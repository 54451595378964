import { Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { Options } from 'ng5-slider';
import { Output, EventEmitter, Component, Input } from '@angular/core';
import Swal from 'sweetalert2';
import { TranslatePipe } from '../../pipes/translate.pipe';

@Component({
  selector: 'image-helper',
  templateUrl: './image-helper.component.html',
  styleUrls: ['./image-helper.component.css']
})
export class ImageHelperComponent {
  constructor(
    private translatePipe: TranslatePipe,
  ) { }

  value: number = 0;
  options: Options = {
    floor: 0,
    ceil: 360
  };

  @Output() convertedImage = new EventEmitter<string>();

  imageChangedEvent: any = '';
  @Input() croppedImage: any = '';
  @Input() readOnly: any = '';
  @Input() aspectRatio: any =  4 / 3;
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  imageURL: string;
  loading = false;
  showCropperPanel = false;

  fileChangeEvent(event: any): void {
    this.loading = true;
    this.showCropperPanel = true;
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;

    this.convertedImage.emit(event.base64);
  }

  imageLoaded() {
    this.showCropper = true;
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    this.loading = false;
  }

  loadImageFailed() {
    this.showCropperPanel = false;
    delete this.croppedImage;
    this.loading = false;
    Swal.fire(this.translatePipe.transform('image_helper'), this.translatePipe.transform('image_load_error'), 'error');
  }

  rotateLeft() {
    this.loading = true;
    setTimeout(() => { // Use timeout because rotating image is a heavy operation and will block the ui thread
      this.canvasRotation--;
      this.flipAfterRotate();
    });
  }

  rotateRight() {
    this.loading = true;
    setTimeout(() => {
      this.canvasRotation++;
      this.flipAfterRotate();
    });
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation
    };
  }

  toggleAspectRatio() {
    this.aspectRatio = this.aspectRatio === 4 / 3 ? 16 / 9 : 4 / 3;
  }
}
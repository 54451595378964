<app-toolbar></app-toolbar>
<label class="tempo-renovacao">
  <button (click)="atualizarTela()"><img class="imgIcone"
         src="../../../assets/icones/refresh.svg"
         title="Reiniciar Imagem"></button>
  {{tempoAtualizacao}}
</label>

<div class="row"
     style="justify-content: center;align-items: center;display: flex;">
  <div class="col s12 m12 l12 card-block">

    <div class="col s12 m4">
      <label class="titleCard center">{{'pedido_liberado' | translate}}</label>
      <div class="card-panel cardPedidosLiberados">
        <div *ngFor="let pedido of pedidosLiberados"
             class="card-action">
          <button class="btn-floating btn-passar waves-effect waves-light right"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Passar para Entrega"
                  (click)="mudaStatusPedido(pedido._id, pedido.entregadorSelecionado, pedido)">
            <i class="material-icons icone-passar">navigate_next</i>
          </button>

          <div>
            <label class="titulo-pedido">
              {{'pedido_numero' | translate}} {{ pedido._id.substr(-5,5).toUpperCase() }}
            </label>
            <label class="horario-pedido right">
              {{pedido.createdAt | date:'dd/MM/yyyy HH:mm'}}
            </label>

            <hr style="margin-bottom: -3px;
              margin-top: 3px;">
          </div>
          <div>
            <label class="horario-pedido">
              Entregar em: {{pedido.delivery_address.address_line_1}}, {{pedido.delivery_address.number}}
              {{pedido.delivery_address.address_line_2}} - {{pedido.delivery_address.area}} -
              {{pedido.delivery_address.city}} - {{pedido.delivery_address.zip_code}}
            </label>

            <hr style="margin-bottom: -3px;
              margin-top: 3px;">
          </div>

          <!-- Produtos -->
          <div>
            <ul style="margin-top: 5px;">
              <li>
                <label class="subtitulo-itens-pedido">Itens do Pedido:</label>
              </li>
              <li *ngFor="let produto of pedido.items">
                <label class="itens-pedido">{{produto.quantity}}x - {{produto.name}}</label>
              </li>
            </ul>
          </div>

          <!-- Meio de Pagamento -->
          <ul>
            <li>
              <label class="subtitulo-itens-pedido"> {{pedido.payment_method.name}}:</label>
            </li>
            <li>
              <label class="itens-pedido"> {{'campo_valor' | translate}}: {{pedido.restaurant.currency_Symbol}}</label>
              <label class="itens-pedido"
                     style="margin-left: 3px;">{{pedido.total | number:'0.2-2'}}</label>
            </li>
            <li *ngIf="pedido.payment_method.requested_change > 0">
              <label class="itens-pedido"> {{'troco' | translate}}: {{pedido.restaurant.currency_Symbol}}</label>
              <label class="itens-pedido"
                     style="margin-left: 3px;">{{pedido.payment_method.requested_change | number:'0.2-2'}}</label>
            </li>
          </ul>

          <!-- Entregador -->
          <select *ngIf="pedido.delivery_method?.method === 'delivery'"
                  material-select
                  id="entregador"
                  style="margin-top: 8px;"
                  [(ngModel)]="pedido.entregadorSelecionado"
                  (click)="vinculaEntregador(pedido.entregadorSelecionado, pedido._id)">
            <option value=""
                    disabled
                    selected>{{'entregador' | translate}}</option>
            <option *ngFor="let entregador of entregadores"
                    [ngValue]="entregador._id">{{entregador.name}}</option>
          </select>

          <!-- Cliente -->
          <div *ngIf="pedido.delivery_method?.method === 'takeaway' || pedido.delivery_method?.method === 'takeway'">
            <label class="itens-pedido">
              {{'cliente' | translate}}: {{pedido.customer.name}}
            </label>
          </div>


          <!-- Correios -->
          <div *ngIf="pedido.delivery_method?.method === 'postal_delivery'">
            <label class="itens-pedido">
              {{'campo_frete_correio' | translate}}: {{pedido.delivery_method.description}}
            </label>
          </div>



        </div>
      </div>
    </div>

    <div class="col s12 m4">
      <label class="titleCard center">{{'pedido_enviado' | translate}}</label>
      <div class="card-panel cardEnviadosEntrega">
        <div *ngFor="let pedido of pedidosEmEntrega"
             class="card-action">
          <a class="btn-floating btn-voltar waves-effect waves-light right"
             data-toggle="tooltip"
             data-placement="bottom"
             title="Voltar para Pedidos Liberados"
             (click)="mudaStatusPedidoRetorna(pedido._id)">
            <i class="material-icons icone-passar">navigate_before</i>
          </a>
          <button *ngIf="pedido.delivery_method?.method === 'takeaway' || pedido.delivery_method?.method === 'takeway'"
                  class="btn-floating btn-passar waves-effect waves-light right"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Passar para Entregues"
                  (click)="mudaStatusPedido(pedido._id, pedido.entregadorSelecionado, pedido)">
            <i class="material-icons icone-passar">navigate_next</i>
          </button>

          <div>
            <label class="titulo-pedido">
              {{'pedido_numero' | translate}} {{ pedido._id.substr(-5,5).toUpperCase() }}
            </label>
            <label class="horario-pedido right">
              {{pedido.createdAt | date:'dd/MM/yyyy HH:mm'}}
            </label>

            <hr style="margin-bottom: -3px;
              margin-top: 3px;">
          </div>
          <div>
            <label class="horario-pedido">
              Entregar em: {{pedido.delivery_address.address_line_1}}, {{pedido.delivery_address.number}}
              {{pedido.delivery_address.address_line_2}} - {{pedido.delivery_address.area}} -
              {{pedido.delivery_address.city}} - {{pedido.delivery_address.zip_code}}
            </label>

            <hr style="margin-bottom: -3px;
              margin-top: 3px;">
          </div>

          <!-- Produtos -->
          <div>
            <ul style="margin-top: 5px;">
              <li>
                <label class="subtitulo-itens-pedido">Itens do Pedido:</label>
              </li>
              <li *ngFor="let produto of pedido.items">
                <label class="itens-pedido">{{produto.quantity}}x - {{produto.name}}</label>
              </li>
            </ul>

          </div>

          <!-- Meio de Pagamento -->
          <ul>
            <li>
              <label class="subtitulo-itens-pedido"> {{pedido.payment_method.name}}:</label>
            </li>
            <li>
              <label class="itens-pedido"> {{'campo_valor' | translate}}: {{pedido.restaurant.currency_Symbol}}</label>
              <label class="itens-pedido"
                     style="margin-left: 3px;">{{pedido.total | number:'0.2-2'}}</label>
            </li>
            <li *ngIf="pedido.payment_method.requested_change > 0">
              <label class="itens-pedido"> {{'troco' | translate}}: {{pedido.restaurant.currency_Symbol}}</label>
              <label class="itens-pedido"
                     style="margin-left: 3px;">{{pedido.payment_method.requested_change | number:'0.2-2'}}</label>
            </li>
          </ul>

          <!-- Entregador -->
          <div *ngIf="pedido.delivery_method?.method === 'delivery'">
            <label class="itens-pedido">
              {{'delivery_person' | translate}}: {{pedido.delivery_person.name}}
            </label>
          </div>
          <!-- Cliente -->
          <div *ngIf="pedido.delivery_method?.method === 'takeaway' || pedido.delivery_method?.method === 'takeway'">
            <label class="itens-pedido">
              {{'cliente' | translate}}: {{pedido.customer.name}}
            </label>
          </div>
          <!-- Correios -->
          <div *ngIf="pedido.delivery_method?.method === 'postal_delivery'">
            <label class="itens-pedido">
              {{'campo_frete_correio' | translate}}: {{pedido.delivery_method.description}}
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="col s12 m4">
      <label class="titleCard center">{{'pedido_entregue' | translate}}</label>
      <div class="card-panel cardPedidosEntregues">
        <div *ngFor="let pedido of pedidosEntregues"
             class="card-action">


          <div>
            <label class="titulo-pedido">
              {{'pedido_numero' | translate}} {{ pedido._id.substr(-5,5).toUpperCase() }}
            </label>
            <label class="horario-pedido right">
              {{pedido.createdAt | date:'dd/MM/yyyy HH:mm'}}
            </label>

            <hr style="margin-bottom: -3px;
                margin-top: 3px;">
          </div>
          <div>
            <label class="horario-pedido">
              Entregar em: {{pedido.delivery_address.address_line_1}}, {{pedido.delivery_address.number}}
              {{pedido.delivery_address.address_line_2}} - {{pedido.delivery_address.area}} -
              {{pedido.delivery_address.city}} - {{pedido.delivery_address.zip_code}}
            </label>

            <hr style="margin-bottom: -3px;
              margin-top: 3px;">
          </div>

          <!-- Produtos -->
          <div>
            <ul style="margin-top: 5px;">
              <li>
                <label class="subtitulo-itens-pedido">Itens do Pedido:</label>
              </li>
              <li *ngFor="let produto of pedido.items">
                <label class="itens-pedido">{{produto.quantity}}x - {{produto.name}}</label>
              </li>
            </ul>
          </div>

          <!-- Meio de Pagamento -->
          <ul>
            <li>
              <label class="subtitulo-itens-pedido"> {{pedido.payment_method.name}}:</label>
            </li>
            <li>
              <label class="itens-pedido"> {{'campo_valor' | translate}}: {{pedido.restaurant.currency_Symbol}}</label>
              <label class="itens-pedido"
                     style="margin-left: 3px;">{{pedido.total | number:'0.2-2'}}</label>
            </li>
            <li *ngIf="pedido.payment_method.requested_change > 0">
              <label class="itens-pedido"> {{'troco' | translate}}: {{pedido.restaurant.currency_Symbol}}</label>
              <label class="itens-pedido"
                     style="margin-left: 3px;">{{pedido.payment_method.requested_change | number:'0.2-2'}}</label>
            </li>
          </ul>

          <!-- Entregador -->
          <div *ngIf="pedido.delivery_method?.method === 'delivery'">
            <label class="itens-pedido">
              {{'delivery_person' | translate}}: {{pedido.delivery_person.name}}
            </label>
          </div>
          <!-- Cliente -->
          <div *ngIf="pedido.delivery_method?.method === 'takeaway' || pedido.delivery_method?.method === 'takeway'">
            <label class="itens-pedido">
              {{'cliente' | translate}}: {{pedido.customer.name}}
            </label>
          </div>
          <!-- Correios -->
          <div *ngIf="pedido.delivery_method?.method === 'postal_delivery'">
            <label class="itens-pedido">
              {{'campo_frete_correio' | translate}}: {{pedido.delivery_method.description}}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CategoriasService {
  BASEURL: string;
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    this.BASEURL = environment.apiUrl;

    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tokenMappe'),
    });
  }

  uploadImages(image: string): Observable<any> {
    return this.http.post(`${this.BASEURL}/images`, { image }, {
      headers: this.httpHeaders,
    });
  }

  listaCategoriasMarcas(): Observable<any> {
    return this.http.get(`${this.BASEURL}/categories/belonging/brands`, {
      headers: this.httpHeaders,
    });
  }

  listaCategoriasSuperiores(): Observable<any> {
    return this.http.get(`${this.BASEURL}/categories/belonging/tops`, {
      headers: this.httpHeaders,
    });
  }

  listaCategoriasProdutos(id: string): Observable<any> {
    return this.http.get(`${this.BASEURL}/categories/belonging/items/${id}`, {
      headers: this.httpHeaders,
    });
  }

  obterCategoria(id: string): Observable<any> {
    return this.http.get(`${this.BASEURL}/categories/${id}`, {
      headers: this.httpHeaders,
    });
  }

  salvaCategoria(body: any): Observable<any> {
    return this.http.post(`${this.BASEURL}/categories`, body, {
      headers: this.httpHeaders,
    });
  }

  editarCategoriaItem(id: string, body: any): Observable<any> {
    return this.http.put(`${this.BASEURL}/categories/${id}`, body, {
      headers: this.httpHeaders,
    });
  }

  excluirCategoria(id: string): Observable<any> {
    return this.http.delete(`${this.BASEURL}/categories/${id}`, {
      headers: this.httpHeaders,
    });
  }
}

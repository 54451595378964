import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ExtrasService {
  BASEURL: string;
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    this.BASEURL = environment.apiUrl;
  
    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tokenMappe'),
    });
}

  listaExtrasProdutos(id: string): Observable<any> {
    return this.http.get(`${this.BASEURL}/extra-ingredients/items/${id}`, {
      headers: this.httpHeaders,
    });
  }

  salvaExtras(body: any): Observable<any> {
    return this.http.post(`${this.BASEURL}/extra-ingredients`, body, {
      headers: this.httpHeaders,
    });
  }

  excluirExtras(id: string): Observable<any> {
    return this.http.delete(`${this.BASEURL}/extra-ingredients/${id}`, {
      headers: this.httpHeaders,
    });
  }

  obterMoedas(id): Observable<any> {
    return this.http.get(`${this.BASEURL}/items/${id}/currencies`, {
      headers: this.httpHeaders,
    });
  }
}

import { TokenService } from './../../../services/token.service';
import { UnidadesService } from './../../../services/unidades.service';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { TranslatePipe } from '../../../pipes/translate.pipe';
import { PlataformaService } from '../../../services/plataforma.service';

@Component({
  selector: 'app-unidade',
  templateUrl: './unidade.component.html',
  styleUrls: ['./unidade.component.css']
})
export class UnidadeComponent implements OnInit {
  isSubmitted = false;
  unidadeForm: FormGroup;
  url = null;
  user = this.tokenService.GetPayLoad();
  modoEdicao = false;
  uploadImage = false;
  idMarca: any;
  idUnidade: any;
  moedas: any;
  responsaveis: any;
  image: any;
  imageId: string;
  imageUrl: string;
  imageSecureUrl: string;
  moedaSelecionada: string;
  meiosPagamento: any;
  meiosArray: any = [];
  formCheks: FormGroup;

  pattern = {
    P: {
      pattern: new RegExp('\\d')
    }
  };

  customMask = ['PPP-PPP', this.pattern];

  constructor(
    private translatePipe: TranslatePipe,
    private fb: FormBuilder,
    private unidadesService: UnidadesService,
    private tokenService: TokenService,
    private router: Router,
    private route: ActivatedRoute,
    private plataformaService: PlataformaService
  ) {
    this.formCheks = this.fb.group({
      checkArray: this.fb.array([], [Validators.required])
    })

    this.init(Validators.required);
  }

  ngOnInit() {
    this.plataformaService.listaConfiguracoes().subscribe((res) => {
      let zipCode = Validators.required;
      if (res.length) {
        if (res[0].zip_code === false) {
          zipCode = undefined;
        }
      }

      this.init(zipCode);

      this.route.paramMap.subscribe(params => {
        this.idMarca = params.get('idMarca');
        this.idUnidade = params.get('idUnidade');
      });

      if (this.idUnidade) {
        this.modoEdicao = true;
        this.carregaCampos();
      } else {
        this.incluirAtendimento();
        this.incluirTaxa();
        this.obterMeioPagamento();
      }

      this.obterMoedas();
      this.obterUsuarios();
    });
  }

  validateNumber(event) {
    if (
      !Number(event.key) &&
      event.key !== '0' &&
      event.key !== '.' &&
      event.key !== '-' &&
      event.key !== 'Backspace'
    ) {
      return false;
    }
  }

  init(zipCode: any) {
    this.unidadeForm = this.fb.group({
      nomeUnidade: ['', Validators.required],
      zipCode: ['', zipCode],
      rua: ['', Validators.required],
      numero: ['', Validators.required],
      complemento: [''],
      bairro: ['', Validators.required],
      cidade: ['', Validators.required],
      estado: ['', Validators.required],
      areaAtendimento: [''],
      telefone: ['', Validators.required],
      email: ['', Validators.required],
      responsavel: [''],
      moeda: ['', Validators.required],
      permite_retirar: [true],
      frete_correio: [false],
      aberta_24_horas: [false],
      atendimentos: this.fb.array([]),
      entrega_gratis: [false],
      taxas: this.fb.array([]),
      loja_parada: [false],
    });
  }

  // Horário de ATendimento
  get atendimentos() {
    return this.unidadeForm.get('atendimentos') as FormArray;
  }

  incluirAtendimento() {
    const atendimento = this.fb.group({
      week_day: ['', Validators.required],
      start_time: ['', Validators.required],
      end_time: ['', Validators.required]
    });

    this.atendimentos.push(atendimento);
  }

  removerAtendimento(index: number) {
    this.atendimentos.removeAt(index);
  }

  // Taxa de Entrega
  get taxas() {
    return this.unidadeForm.get('taxas') as FormArray;
  }

  incluirTaxa() {
    const taxa = this.fb.group({
      km: ['', Validators.required],
      price: ['', Validators.required],
      delivery_time: ['', Validators.required],
      time_type: ['', Validators.required]
    });
    this.taxas.push(taxa);
  }

  removerTaxa(index: number) {
    this.taxas.removeAt(index);
  }

  obterMoedas() {
    this.unidadesService.obterMoedas().subscribe(res => {
      this.moedas = res;

      this.unidadeForm.controls.moeda.setValue(res[0]._id);
    });
  }

  obterUsuarios() {
    this.unidadesService.listaUsuarios().subscribe(res => {
      this.responsaveis = res;
    });
  }

  //MEIOS DE PAGAMENTO
  obterMeioPagamento() {
    this.meiosPagamento = [];
    this.unidadesService.obterMeiosPagamento().subscribe(res => {
      res.forEach(el => {
        this.meiosPagamento.push({
          _id: el._id,
          name: el.name,
          check: false
        });
      })
    })
  }


  onCheckboxChange(e) {
    const checkArray: FormArray = this.formCheks.get('checkArray') as FormArray;

    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  mudaMoeda() {
    const moedaSelecionada = this.unidadeForm.controls.moeda.value;
    this.moedas.forEach(moeda => {
      if (moeda._id === moedaSelecionada) {
        this.moedaSelecionada = moeda.symbol;
      }
    });
  }

  getImage(event) {
    this.image = event;
  }

  onSubmit(form: FormGroup) {
    this.isSubmitted = true;
    if (form.valid && this.formCheks.value.checkArray.length !== 0 && (this.image || this.imageId)) {

      const atendimentos = this.atendimentos.value;

      atendimentos.map(atendimento => {
        atendimento.week_day = Number(atendimento.week_day);
        atendimento.start_time = atendimento.start_time;
        atendimento.end_time = atendimento.end_time;
      });

      const taxas = this.taxas.value;

      taxas.map(taxa => {
        taxa.km = Number(taxa.km);
        taxa.price = Number(taxa.price);
        taxa.delivery_time = Number(taxa.delivery_time);
        taxa.time_type = taxa.time_type;
      });

      if (this.image) {
        this.unidadesService.uploadImages(this.image).subscribe(res => {
          this.salvar({
            brand: this.idMarca,
            name: this.unidadeForm.value.nomeUnidade,
            zip_code: (this.unidadeForm.value.zipCode ? this.unidadeForm.value.zipCode : undefined),
            address_line_1: this.unidadeForm.value.rua,
            number: this.unidadeForm.value.numero,
            address_line_2: this.unidadeForm.value.complemento,
            area: this.unidadeForm.value.bairro,
            city: this.unidadeForm.value.cidade,
            state: this.unidadeForm.value.estado,
            service_area: this.unidadeForm.value.areaAtendimento ? this.unidadeForm.value.areaAtendimento : undefined,
            phone: this.unidadeForm.value.telefone,
            email: this.unidadeForm.value.email,
            owner: this.unidadeForm.value.responsavel ? this.unidadeForm.value.responsavel : undefined,
            payment_methods: this.formCheks.value.checkArray,
            image: {
              id: res.public_id,
              url: res.url,
              secure_url: res.secure_url
            },
            currency_id: this.unidadeForm.value.moeda,
            takeaway: this.unidadeForm.value.permite_retirar,
            postal_delivery: this.unidadeForm.value.frete_correio,
            is_stopped: this.unidadeForm.value.loja_parada,
            open_24_hours: this.unidadeForm.value.aberta_24_horas,
            office_hours: (this.unidadeForm.value.aberta_24_horas === false ? atendimentos : undefined),
            delivery_fees: (this.unidadeForm.value.entrega_gratis === false ? taxas : undefined),
          });
        });
      }
      else {
        this.salvar({
          brand: this.idMarca,
          name: this.unidadeForm.value.nomeUnidade,
          zip_code: (this.unidadeForm.value.zipCode ? this.unidadeForm.value.zipCode : undefined),
          address_line_1: this.unidadeForm.value.rua,
          number: this.unidadeForm.value.numero,
          address_line_2: this.unidadeForm.value.complemento,
          area: this.unidadeForm.value.bairro,
          city: this.unidadeForm.value.cidade,
          state: this.unidadeForm.value.estado,
          service_area: this.unidadeForm.value.areaAtendimento ? this.unidadeForm.value.areaAtendimento : undefined,
          phone: this.unidadeForm.value.telefone,
          email: this.unidadeForm.value.email,
          owner: this.unidadeForm.value.responsavel ? this.unidadeForm.value.responsavel : undefined,
          payment_methods: this.formCheks.value.checkArray,
          image: {
            id: this.imageId,
            url: this.imageUrl,
            secure_url: this.imageSecureUrl
          },
          currency_id: this.unidadeForm.value.moeda,
          takeaway: this.unidadeForm.value.permite_retirar,
          postal_delivery: this.unidadeForm.value.frete_correio,
          is_stopped: this.unidadeForm.value.loja_parada,
          open_24_hours: this.unidadeForm.value.aberta_24_horas,
          office_hours: (this.unidadeForm.value.aberta_24_horas === false ? atendimentos : undefined),
          delivery_fees: (this.unidadeForm.value.entrega_gratis === false ? taxas : undefined),
        });
      }
    }
  }

  salvar(unidadeForm) {
    if (this.idUnidade) {
      this.unidadesService.editarUnidade(this.idUnidade, unidadeForm).subscribe(
        res => {
          Swal.fire(this.translatePipe.transform('titulo_unidade'), this.translatePipe.transform('unidade_editada'), 'success').then(() => {
            this.router.navigate([`admin/unidades/marca/${this.idMarca}`]);
          });
        },
        error => {
          Swal.fire(this.translatePipe.transform('titulo_unidade'), error.error.message[0].message, 'error');
        }
      );
    } else {
      this.unidadesService.salvaUnidade(unidadeForm).subscribe(
        res => {
          Swal.fire(this.translatePipe.transform('titulo_unidade'), this.translatePipe.transform('unidade_cadastrada'), 'success').then(() => {
            this.router.navigate([`admin/unidades/marca/${this.idMarca}`]);
          });
        },
        error => {
          Swal.fire(this.translatePipe.transform('titulo_unidade'), error.error.message, 'error');
        }
      );
    }
  }

  async validar24Horas(e) {
    if (e.target.checked) {
      this.atendimentos.clear();
    } else {
      this.incluirAtendimento();
    }
  }

  async validarEntregaGratis(e) {
    if (e.target.checked) {
      this.taxas.clear();
    } else {
      this.incluirTaxa();
    }
  }

  async buscarCEP() {
    this.unidadesService.obterLogradouroCep(this.unidadeForm.value.zipCode).subscribe(res => {
      this.unidadeForm.controls.zipCode.setValue(res.cep.replace('-', ''));
      this.unidadeForm.controls.rua.setValue(res.logradouro);
      this.unidadeForm.controls.bairro.setValue(res.bairro);
      this.unidadeForm.controls.cidade.setValue(res.localidade);
      this.unidadeForm.controls.estado.setValue(res.uf);
    })
  }

  cancelar(id: string) {
    this.router.navigate([`admin/unidades/marca/${id}`]);
  }

  // EDIÇÃO
  carregaCampos() {
    this.unidadesService.obterDadosUnidade(this.idUnidade).subscribe(res => {
      this.isSubmitted = true;
      if (res.image) {
        this.imageId = res.image.id;
        this.imageUrl = res.image.url;
        this.imageSecureUrl = res.image.secure_url;
        this.url = res.image.url;
      }

      this.unidadeForm.controls.nomeUnidade.setValue(res.name);
      this.unidadeForm.controls.zipCode.setValue(res.zip_code);
      this.unidadeForm.controls.rua.setValue(res.address_line_1);
      this.unidadeForm.controls.numero.setValue(res.number);
      this.unidadeForm.controls.complemento.setValue(res.address_line_2);
      this.unidadeForm.controls.bairro.setValue(res.area);
      this.unidadeForm.controls.cidade.setValue(res.city);
      this.unidadeForm.controls.estado.setValue(res.state);
      this.unidadeForm.controls.areaAtendimento.setValue(res.service_area);
      this.unidadeForm.controls.telefone.setValue(res.phone);
      this.unidadeForm.controls.email.setValue(res.email);
      this.unidadeForm.controls.responsavel.setValue(res.owner ? res.owner : '');
      this.unidadeForm.controls.moeda.setValue(res.currency._id);
      this.unidadeForm.controls.permite_retirar.setValue(res.takeaway);
      this.unidadeForm.controls.frete_correio.setValue(res.postal_delivery);
      this.unidadeForm.controls.loja_parada.setValue(res.is_stopped);
      this.moedaSelecionada = res.currency.symbol;

      res.delivery_fees.forEach(element => {
        const taxa = this.fb.group({
          km: [element.km],
          price: [element.price],
          delivery_time: [element.delivery_time],
          time_type: [element.time_type]
        });
        this.taxas.push(taxa);
      });

      if (res.delivery_fees && res.delivery_fees.length === 0) {
        this.unidadeForm.controls.entrega_gratis.setValue(true);
      }
      else {
        this.unidadeForm.controls.entrega_gratis.setValue(false);
      }

      res.office_hours.forEach(element => {
        const atendimento = this.fb.group({
          week_day: [element.week_day, Validators.required],
          start_time: [element.start_time, Validators.required],
          end_time: [element.end_time, Validators.required]
        });
        this.atendimentos.push(atendimento);
      });

      if (res.office_hours && res.office_hours.length === 0) {
        this.unidadeForm.controls.aberta_24_horas.setValue(true);
      }
      else {
        this.unidadeForm.controls.aberta_24_horas.setValue(res.open_24_hours);
      }

      //Seta os chekbox dos meios de pagamentos
      this.meiosPagamento = [];
      let encontradoMeio;

      this.unidadesService.obterMeiosPagamento().subscribe(resu => {
        resu.forEach(ele => {

          encontradoMeio = res.payment_methods.find(el => {
            return el._id === ele._id;
          })

          if (encontradoMeio) {
            this.meiosPagamento.push({
              _id: ele._id,
              name: ele.name,
              check: true
            });

            const checkArray: FormArray = this.formCheks.get('checkArray') as FormArray;
            checkArray.push(new FormControl(ele._id));

          } else {
            this.meiosPagamento.push({
              _id: ele._id,
              name: ele.name,
              check: false
            });
          }
        })
      })
    });
  }
}

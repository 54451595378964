import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  BASEURL: string;

  constructor(private http: HttpClient) {
    this.BASEURL = environment.apiUrl;
  }

  registerUser(body): Observable<any> {
    return this.http.post(`${this.BASEURL}/auth/register`, body);
  }

  loginUser(body): Observable<any> {
    return this.http.post(`${this.BASEURL}/users/login`, body);
  }

  sendNewPasswordToken(body): Observable<any> {
    return this.http.post(`${this.BASEURL}/users/password-token`, body);
  }

  changePasswordToken(body): Observable<any> {
    return this.http.post(`${this.BASEURL}/users/password-token-change`, body);
  }
}

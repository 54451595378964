import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-toolbars',
  templateUrl: './toolbars.component.html',
  styleUrls: ['./toolbars.component.css']
})
export class ToolbarsComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}

import { TaxasService } from '../../services/taxas.service';
import { TokenService } from '../../services/token.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { TranslatePipe } from '../../pipes/translate.pipe';
import * as moment from 'moment';
@Component({
  selector: 'app-taxas',
  templateUrl: './taxas.component.html',
  styleUrls: ['./taxas.component.css']
})
export class TaxasComponent implements OnInit {
  isSubmitted = false;
  url = null;
  taxaForm: FormGroup;
  idMarca: any;
  idProduto: any;
  taxas = [];
  modoEdicao = false;
  idItem: any;
  user = this.tokenService.GetPayLoad();

  constructor(
    private tokenService: TokenService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private taxasService: TaxasService,
    private translatePipe: TranslatePipe
  ) { }

  ngOnInit() {
    this.init();

    this.incluirOptions();

    this.listaTaxas();
  }

  init() {
    this.taxaForm = this.fb.group({
      nome: ['', Validators.required],
      inicio: ['', Validators.required],
      fim: ['', Validators.required],
      range: this.fb.array([])
    });
  }

  listaTaxas() {
    this.taxasService.listaTaxas().subscribe(res => {
      this.taxas = res;

      this.taxas.map((taxa) => {
        taxa.start = moment(taxa.start).format("DD/MM/YYYY");
        taxa.end = moment(taxa.end).format("DD/MM/YYYY");
      })

    });
  }

  get options() {
    return this.taxaForm.get('range') as FormArray;
  }

  incluirOptions() {
    const option = this.fb.group({
      preco: ['', Validators.required],
      tipo: ['', Validators.required],
      taxacliente: ['', Validators.required],
      taxaloja: ['', Validators.required]
    });

    this.options.push(option);
  }

  removeroptions(index: number) {
    this.options.removeAt(index);
  }

  onSubmit(form: FormGroup) {
    this.isSubmitted = true;
    this.taxaForm.updateValueAndValidity();

    if (form.valid) {
      this.salvar({
        name: this.taxaForm.value.nome,
        start: this.taxaForm.value.inicio,
        end: this.taxaForm.value.fim,
        range: this.options
      });
    }
  }

  onSubmitEdit(form: FormGroup) {
    this.taxaForm.updateValueAndValidity();
    if (form.valid) {
      this.salvar({
        name: this.taxaForm.value.nome,
        start: this.taxaForm.value.inicio,
        end: this.taxaForm.value.fim,
        range: this.options
      });
    }
  }

  salvar(taxaForm) {
    if (this.modoEdicao) {
      const items = [];
      this.options.value.forEach(item => {
        items.push({
          price: item.preco,
          type: item.tipo,
          client_fee: item.taxacliente,
          brand_fee: item.taxaloja
        });
      });

      taxaForm.start = moment(taxaForm.start).format("YYYY-MM-DD");
      taxaForm.end = moment(taxaForm.end).format("YYYY-MM-DD");

      taxaForm.range = (items.length > 0 ? items : undefined);

      this.taxasService.editarItemTaxa(this.idItem, taxaForm).subscribe(
        res => {
          Swal.fire('Taxas', this.translatePipe.transform('mensagem_editar_combo'), 'success').then(() => {
            this.listaTaxas();

            this.taxaForm.controls.nome.markAsUntouched();
            this.taxaForm.controls.nome.setValue(null);

            this.taxaForm.controls.inicio.markAsUntouched();
            this.taxaForm.controls.inicio.setValue('');

            this.taxaForm.controls.fim.markAsUntouched();
            this.taxaForm.controls.fim.setValue('');

            this.taxaForm.controls.range = this.fb.array([]);

            this.incluirOptions();

            this.isSubmitted = false;
            this.modoEdicao = false;
          });
        },
        error => {
          Swal.fire('Taxas', error.error.message, 'error');
        }
      );
    } else {
      const items = [];
      this.options.value.forEach(item => {
        items.push({
          price: item.preco,
          type: item.tipo,
          client_fee: item.taxacliente,
          brand_fee: item.taxaloja
        });
      });

      taxaForm.range = (items.length > 0 ? items : undefined);

      this.taxasService.salvaTaxa(taxaForm).subscribe(
        res => {
          Swal.fire('Taxas', this.translatePipe.transform('mensagem_combo'), 'success').then(() => {
            this.listaTaxas();

            this.taxaForm.controls.nome.markAsUntouched();
            this.taxaForm.controls.nome.setValue(null);

            this.taxaForm.controls.inicio.markAsUntouched();
            this.taxaForm.controls.inicio.setValue('');

            this.taxaForm.controls.fim.markAsUntouched();
            this.taxaForm.controls.fim.setValue('');

            this.taxaForm.controls.range = this.fb.array([]);

            this.incluirOptions();

            this.isSubmitted = false;
            this.modoEdicao = false;
          });
        },
        error => {
          Swal.fire('Taxas', error.error.message, 'error');
        }
      );
    }
  }

  deletarItemCombo(id: string) {
    Swal.fire({
      title: this.translatePipe.transform('deletar_titulos'),
      text: this.translatePipe.transform('mensagem_excluir_combo'),
      showCancelButton: true,
      confirmButtonText: this.translatePipe.transform('deletar_sim'),
      cancelButtonText: this.translatePipe.transform('deletar_nao'),
    }).then(result => {
      if (result.value) {
        this.taxasService.excluirItemTaxa(id).subscribe(
          res => {
            this.listaTaxas();
          },
          error => {
            Swal.fire('Taxas', error.error.message, 'error');
          }
        );
      }
    });
  }

  editarItemCombo(id: string) {
    this.modoEdicao = true;
    this.idItem = id;
    this.taxasService.listaItemTaxa(id).subscribe(res => {
      this.taxaForm.controls.nome.setValue(res.name);

      this.taxaForm.controls.inicio.setValue(moment(res.start).format("YYYY-MM-DD"));
      this.taxaForm.controls.fim.setValue(moment(res.end).format("YYYY-MM-DD"));

      this.taxaForm.controls.range = this.fb.array([]);

      res.range.forEach(element => {
        const opcao = this.fb.group({
          preco: [element.price, Validators.required],
          tipo: [element.type, Validators.required],
          taxacliente: [element.client_fee, Validators.required],
          taxaloja: [element.brand_fee, Validators.required]
        });
        this.options.push(opcao);

        this.taxaForm.updateValueAndValidity();
      });
    });
    window.scrollTo(0, 0);
  }

  cancelar() {
    this.taxaForm.controls.nome.markAsUntouched();
    this.taxaForm.controls.nome.setValue(null);

    this.taxaForm.controls.inicio.markAsUntouched();
    this.taxaForm.controls.inicio.setValue('');

    this.taxaForm.controls.fim.markAsUntouched();
    this.taxaForm.controls.fim.setValue('');

    this.taxaForm.controls.range = this.fb.array([]);

    this.incluirOptions();

    this.isSubmitted = false;
    this.modoEdicao = false;

  }

  voltar() {
    this.router.navigate([`admin/home`]);
  }
}

import { CardapiosService } from './../../services/cardapios.service';
import { TokenService } from '../../services/token.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { TranslatePipe } from '../../pipes/translate.pipe';

@Component({
  selector: 'app-cardapios',
  templateUrl: './cardapios.component.html',
  styleUrls: ['./cardapios.component.css']
})
export class CardapiosComponent implements OnInit {
  produtos = [];
  categorias = [];
  id: string;
  moedasCombo: any;

  user = this.tokenService.GetPayLoad();
  constructor(
    private translatePipe: TranslatePipe,
    private tokenService: TokenService,
    private route: ActivatedRoute,
    private cardapiosService: CardapiosService,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('idMarca');
    });

    this.obterMoedas();

  }

  obterMoedas() {
    this.cardapiosService.obterMoedas().subscribe(res => {
      this.moedasCombo = res;

      this.listaItemsCardapios();
    });
  }

  obterMoeda(id) {
    if (id) {
      return this.moedasCombo.filter((moeda) => { return moeda._id === id })[0].symbol;
    }
    else {
      return this.moedasCombo[0].symbol;
    }
  }

  listaItemsCardapios() {
    this.cardapiosService.listaItemsCardapiosPorMarca(this.id).subscribe(res => {

      res.map((produto) => {


        if (produto.prices.length > 0) {
          produto.moeda = this.obterMoeda(produto.prices[0].currency);
          produto.valor = produto.prices[0].value;
        }
        else {
          produto.moeda = '';
          produto.valor = '';
        }

        if (this.categorias.filter((categoria) => { return categoria._id === produto.categories[0]._id }).length === 0) {
          this.categorias.push(produto.categories[0]);
        }
      });

      this.produtos = res;
    });
  }

  produtosFiltros(categoria) {
    return this.produtos.filter((produto) => { return produto.categories[0]._id === categoria._id });
  }

  excluirItem(id: string) {
    Swal.fire({
      title: 'Você tem certeza?',
      text: this.translatePipe.transform('deletar_produto'),
      showCancelButton: true,
      confirmButtonText: this.translatePipe.transform('deletar_sim'),
      cancelButtonText: this.translatePipe.transform('deletar_nao'),
    }).then(result => {
      if (result.value) {
        this.cardapiosService.excluirItemCardapio(id).subscribe(
          res => {
            this.listaItemsCardapios();
          },
          error => {
            Swal.fire('Cardápio', error.error.message, 'error');
          }
        );
      }
    });
  }

  editar(id: string) {
    this.router.navigate([`admin/cardapios/marca/${this.id}/produto/${id}/editar`]);
  }

  redirecionaExtras(id: string) {
    this.router.navigate([`admin/cardapios/marca/${this.id}/produto/${id}/extras`]);
  }

  redirecionaCombo(id: string) {
    this.router.navigate([`admin/cardapios/marca/${this.id}/produto/${id}/combo`]);
  }
}

import { CategoriasService } from '../../services/categorias.service';
import { TokenService } from '../../services/token.service';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslatePipe } from '../../pipes/translate.pipe';

@Component({
  selector: 'app-segmentos-superiores',
  templateUrl: './segmentos-superiores.component.html',
  styleUrls: ['./segmentos-superiores.component.css']
})
export class SegmentosSuperioresComponent implements OnInit {
  segmentos = [];
  marca: string;
  url = null;
  user = this.tokenService.GetPayLoad();
  idMarca: any;

  constructor(private translatePipe: TranslatePipe,
    private tokenService: TokenService,
    private categoriasService: CategoriasService,
    private router: Router,
    private route: ActivatedRoute,) { }

  ngOnInit() {
    this.listaCategorias();
  }

  listaCategorias() {
    this.categoriasService.listaCategoriasSuperiores().subscribe(res => {
      this.segmentos = res;
    });
  }

  excluir(id: string) {
    Swal.fire({
      title: this.translatePipe.transform('deletar_titulos'),
      text: this.translatePipe.transform('gerenciamento_segmentos_superior_excluir'),
      showCancelButton: true,
      confirmButtonText: this.translatePipe.transform('deletar_sim'),
      cancelButtonText: this.translatePipe.transform('deletar_nao'),
    }).then(result => {
      if (result.value) {
        this.categoriasService.excluirCategoria(id).subscribe((res: any) => {
          this.listaCategorias();
        }, (err) => {
          Swal.fire('Segmentos', this.translatePipe.transform(err.error.message), 'error');
        });
      }
    });
  }

  editar(id: string) {
    this.router.navigate([`admin/segmentos-superiores/segmento/${id}/editar`]);
  }
}

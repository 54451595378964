import { DestaquesAplicativoService } from '../../services/destaques-aplicativo.service';
import { TokenService } from '../../services/token.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { TranslatePipe } from '../../pipes/translate.pipe';
import * as moment from 'moment';
@Component({
  selector: 'app-destaques-aplicativo',
  templateUrl: './destaques-aplicativo.component.html',
  styleUrls: ['./destaques-aplicativo.component.css']
})
export class DestaquesAplicativoComponent implements OnInit {
  isSubmitted = false;
  url = null;
  destaqueForm: FormGroup;
  modoEdicao = false;
  idMarca: any;
  idProduto: any;
  destaques = [];
  idItem: any;
  user = this.tokenService.GetPayLoad();

  constructor(
    private tokenService: TokenService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private destaquesAplicativoService: DestaquesAplicativoService,
    private translatePipe: TranslatePipe
  ) { }

  ngOnInit() {
    this.init();

    this.route.paramMap.subscribe(params => {
      this.idMarca = params.get('idMarca');

      if (this.idMarca) {
        this.listaDestaques();
      }
    });
  }

  init() {
    this.destaqueForm = this.fb.group({
      nome: ['', Validators.required],
      inicio: ['', Validators.required],
      fim: ['', Validators.required],
    });
  }

  listaDestaques() {
    this.destaquesAplicativoService.listaDestaque(this.idMarca).subscribe(res => {
      this.destaques = res;
    });
  }

  get options() {
    return this.destaqueForm.get('range') as FormArray;
  }


  salvarCadastro(form: FormGroup) {
    this.isSubmitted = true;
    this.destaqueForm.updateValueAndValidity();

    if (form.valid) {
      this.salvar({
        brand: this.idMarca,
        name: this.destaqueForm.value.nome,
        start_date: this.destaqueForm.value.inicio,
        end_date: this.destaqueForm.value.fim,
      });
    }
  }

  salvarEdicao(form: FormGroup) {
    this.destaqueForm.updateValueAndValidity();
    if (form.valid) {
      this.salvar({
        brand: this.idMarca,
        name: this.destaqueForm.value.nome,
        start_date: this.destaqueForm.value.inicio,
        end_date: this.destaqueForm.value.fim,
      });
    }
  }

  salvar(destaqueForm) {
    if (this.modoEdicao) {
      this.destaquesAplicativoService.editarItemDestaque(this.idItem, destaqueForm).subscribe(
        res => {
          Swal.fire(this.translatePipe.transform('destaques_aplicativos'), this.translatePipe.transform('destaques_aplicativos_editar'), 'success').then(() => {
            this.listaDestaques();

            this.cancelar();
          });
        },
        error => {
          Swal.fire(this.translatePipe.transform('destaques_aplicativos'), this.translatePipe.transform(error.error.message), 'error');
        }
      );
    } else {

      this.destaquesAplicativoService.salvaDestaque(destaqueForm).subscribe(
        res => {
          Swal.fire(this.translatePipe.transform('destaques_aplicativos'), this.translatePipe.transform('destaques_aplicativos_cadastrar'), 'success').then(() => {
            this.listaDestaques();

            this.cancelar();
          });
        },
        error => {
          Swal.fire(this.translatePipe.transform('destaques_aplicativos'), this.translatePipe.transform(error.error.message), 'error');
        }
      );
    }
  }

  deletarItemCombo(id: string) {
    Swal.fire({
      title: this.translatePipe.transform('deletar_titulos'),
      text: this.translatePipe.transform('destaques_aplicativos_excluir'),
      showCancelButton: true,
      confirmButtonText: this.translatePipe.transform('deletar_sim'),
      cancelButtonText: this.translatePipe.transform('deletar_nao'),
    }).then(result => {
      if (result.value) {
        this.destaquesAplicativoService.excluirItemDestaque(id).subscribe(
          res => {
            this.listaDestaques();
          },
          error => {
            Swal.fire(this.translatePipe.transform('destaques_aplicativos'), this.translatePipe.transform(error.error.message), 'error');
          }
        );
      }
    });
  }

  editarItemCombo(id: string) {
    this.modoEdicao = true;
    this.idItem = id;
    this.destaquesAplicativoService.listaItemDestaque(id).subscribe(res => {
      this.destaqueForm.controls.nome.setValue(res.name);
      this.destaqueForm.controls.inicio.setValue(moment(res.start_date).format("YYYY-MM-DD"));
      this.destaqueForm.controls.fim.setValue(moment(res.end_date).format("YYYY-MM-DD"));
    });
    window.scrollTo(0, 0);
  }

  cancelar() {
    this.destaqueForm.controls.nome.markAsUntouched();
    this.destaqueForm.controls.nome.setValue(null);

    this.destaqueForm.controls.inicio.markAsUntouched();
    this.destaqueForm.controls.inicio.setValue(null);

    this.destaqueForm.controls.fim.markAsUntouched();
    this.destaqueForm.controls.fim.setValue(null);

    this.isSubmitted = false;
    this.modoEdicao = false;
  }

  voltar() {
    this.router.navigate([`admin/marcas`]);
  }
}

<app-toolbar></app-toolbar>
<div class="container">
  <form class="col s12"
        [formGroup]="destaqueForm"
        novalidate>
    <div class="row">

      <div class="input-field col s6">
        <input id="nome"
               type="text"
               formControlName="nome">
        <label for="nome"
               [class.active]="modoEdicao">{{'destaques_aplicativos_descricao' | translate}}</label>
        <span class="error"
              *ngIf="destaqueForm.controls['nome'].invalid && 
          (destaqueForm.controls['nome'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>

      <div class="input-field col s3">
        <input id="inicio"
               type="date"
               formControlName="inicio">
        <label for="inicio"
               [class.active]="modoEdicao">{{'destaques_aplicativos_inicio' | translate}}</label>
        <span class="error"
              *ngIf="destaqueForm.controls['inicio'].invalid && 
              (destaqueForm.controls['inicio'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>
      <div class="input-field col s3">
        <input id="fim"
               type="date"
               formControlName="fim">
        <label for="fim"
               [class.active]="modoEdicao">{{'destaques_aplicativos_fim' | translate}}</label>
        <span class="error"
              *ngIf="destaqueForm.controls['fim'].invalid && 
              (destaqueForm.controls['fim'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>
    </div>
  </form>

  <div class="row">
    <button *ngIf="!modoEdicao"
            class="btn btnSalvar waves-effect right"
            id="salvar"
            type="button"
            (click)="salvarCadastro(destaqueForm)"
            [disabled]="uploadImage">
      {{'texto_salvar' | translate}}
    </button>

    <button *ngIf="modoEdicao"
            class="btn btnSalvar waves-effect right"
            id="salvar"
            type="button"
            (click)="salvarEdicao(destaqueForm)">
      {{'texto_salvar' | translate}}
    </button>

    <button *ngIf="modoEdicao"
            class="btn btnCancelar right"
            id="cancelar"
            (click)="cancelar()"
            type="button">
      {{'texto_cancelar' | translate}}
    </button>

  </div>

  <table style="margin-top: 5rem;">
    <thead>
      <tr>
        <th style="width: 50%;">{{'destaques_aplicativos_descricao' | translate}}</th>
        <th style="width: 15%;">{{'destaques_aplicativos_inicio' | translate}}</th>
        <th style="width: 15%;">{{'destaques_aplicativos_fim' | translate}}</th>
        <th style="text-align: center;">{{'texto_acao' | translate}}</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let item of destaques">
        <td style="width:50%;">{{item.name}}</td>
        <td style="width:15%;">{{item.start_date | date:"dd/MM/yyyy"}}</td>
        <td style="width:15%;">{{item.end_date | date:"dd/MM/yyyy"}}</td>
        <td style="text-align: center;">
          <a class="btn-floating btn-edit"
             (click)="editarItemCombo(item._id)"
             data-toggle="tooltip"
             data-placement="bottom"
             title="{{'texto_editar' | translate}}">
            <i class="material-icons"
               style="color: orange;">create</i>
          </a>

          <a class="btn-floating btn-remove"
             (click)="deletarItemCombo(item._id)"
             data-toggle="tooltip"
             data-placement="bottom"
             title="{{'texto_excluir' | translate}}">
            <i class="material-icons">cancel</i>
          </a>

        </td>
      </tr>

    </tbody>
  </table>

  <div class="row center"
       style="margin-top: 3rem;">
    <button class="btn btnVoltar"
            id="voltar"
            (click)="voltar()"
            type="button">
      {{'texto_voltar' | translate}}
    </button>
  </div>
</div>
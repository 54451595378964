import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PagamentoService {
  BASEURL: string;
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    this.BASEURL = environment.apiUrl;
  
    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tokenMappe'),
    });
}

  listaPagamentos(): Observable<any> {
    return this.http.get(`${this.BASEURL}/payment-methods`, {
      headers: this.httpHeaders,
    });
  }

  salvaPagamento(body: any): Observable<any> {
    return this.http.post(`${this.BASEURL}/payment-methods`, body, {
      headers: this.httpHeaders,
    });
  }

  excluirPagamento(id: string): Observable<any> {
    return this.http.delete(`${this.BASEURL}/payment-methods/${id}`, {
      headers: this.httpHeaders,
    });
  }

  obterDadosPagamento(id: string): Observable<any> {
    return this.http.get(`${this.BASEURL}/payment-methods/${id}`, {
      headers: this.httpHeaders,
    });
  }

  editarPagamento(id: string, body: any): Observable<any> {
    return this.http.put(`${this.BASEURL}/payment-methods/${id}`, body, {
      headers: this.httpHeaders,
    });
  }
}

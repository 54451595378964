import { MotivosCancelamentoService } from '../../services/motivos-cancelamento.service';
import { TokenService } from '../../services/token.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { TranslatePipe } from '../../pipes/translate.pipe';
@Component({
  selector: 'app-motivos-cancelamentos',
  templateUrl: './motivos-cancelamento.component.html',
  styleUrls: ['./motivos-cancelamento.component.css']
})
export class MotivosCancelamentoComponent implements OnInit {
  isSubmitted = false;
  url = null;
  motivoCancelamentoForm: FormGroup;
  idMarca: any;
  idProduto: any;
  motivos = [];
  modoEdicao = false;
  idItem: any;
  user = this.tokenService.GetPayLoad();

  constructor(
    private tokenService: TokenService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private motivosCancelamentoService: MotivosCancelamentoService,
    private translatePipe: TranslatePipe
  ) { }

  ngOnInit() {
    this.init();

    this.listaMotivos();
  }

  init() {
    this.motivoCancelamentoForm = this.fb.group({
      descricao: ['', Validators.required],
    });
  }

  listaMotivos() {
    this.motivosCancelamentoService.listaMotivoCancelamento().subscribe(res => {
      this.motivos = res;
    });
  }

  get options() {
    return this.motivoCancelamentoForm.get('range') as FormArray;
  }


  onSubmit(form: FormGroup) {
    this.isSubmitted = true;
    this.motivoCancelamentoForm.updateValueAndValidity();

    if (form.valid) {
      this.salvar({
        description: this.motivoCancelamentoForm.value.descricao,
      });
    }
  }

  onSubmitEdit(form: FormGroup) {
    this.motivoCancelamentoForm.updateValueAndValidity();
    if (form.valid) {
      this.salvar({
        description: this.motivoCancelamentoForm.value.descricao,
      });
    }
  }

  salvar(motivoCancelamentoForm) {
    if (this.modoEdicao) {
      this.motivosCancelamentoService.editarItemMotivoCancelamento(this.idItem, motivoCancelamentoForm).subscribe(
        res => {
          Swal.fire('Motivos', this.translatePipe.transform('motivos_cancelamento_editar'), 'success').then(() => {
            this.listaMotivos();

            this.motivoCancelamentoForm.controls.descricao.markAsUntouched();
            this.motivoCancelamentoForm.controls.descricao.setValue(null);

            this.isSubmitted = false;
            this.modoEdicao = false;
          });
        },
        error => {
          Swal.fire('Motivos', this.translatePipe.transform(error.error.message), 'error');
        }
      );
    } else {

      this.motivosCancelamentoService.salvaMotivoCancelamento(motivoCancelamentoForm).subscribe(
        res => {
          Swal.fire('Motivos', this.translatePipe.transform('motivos_cancelamento_cadastrar'), 'success').then(() => {
            this.listaMotivos();

            this.motivoCancelamentoForm.controls.descricao.markAsUntouched();
            this.motivoCancelamentoForm.controls.descricao.setValue(null);

            this.isSubmitted = false;
            this.modoEdicao = false;
          });
        },
        error => {
          Swal.fire('Motivos', this.translatePipe.transform(error.error.message), 'error');
        }
      );
    }
  }

  deletarItemCombo(id: string) {
    Swal.fire({
      title: this.translatePipe.transform('deletar_titulos'),
      text: this.translatePipe.transform('motivos_cancelamento_excluir'),
      showCancelButton: true,
      confirmButtonText: this.translatePipe.transform('deletar_sim'),
      cancelButtonText: this.translatePipe.transform('deletar_nao'),
    }).then(result => {
      if (result.value) {
        this.motivosCancelamentoService.excluirItemMotivoCancelamento(id).subscribe(
          res => {
            this.listaMotivos();
          },
          error => {
            Swal.fire('Motivos', this.translatePipe.transform(error.error.message), 'error');
          }
        );
      }
    });
  }

  editarItemCombo(id: string) {
    this.modoEdicao = true;
    this.idItem = id;
    this.motivosCancelamentoService.listaItemMotivoCancelamento(id).subscribe(res => {
      this.motivoCancelamentoForm.controls.descricao.setValue(res.description);
    });
    window.scrollTo(0, 0);
  }

  cancelar() {
    this.motivoCancelamentoForm.controls.descricao.markAsUntouched();
    this.motivoCancelamentoForm.controls.descricao.setValue(null);

    this.isSubmitted = false;
    this.modoEdicao = false;

  }

  voltar() {
    this.router.navigate([`admin/home`]);
  }
}

import { PromocoesService } from './../../services/promocoes.service';
import { TokenService } from '../../services/token.service';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslatePipe } from '../../pipes/translate.pipe';

@Component({
  selector: 'app-promocoes',
  templateUrl: './promocoes.component.html',
  styleUrls: ['./promocoes.component.css']
})
export class PromocoesComponent implements OnInit {
  promocoes = [];
  marca: string;
  url = null;
  user = this.tokenService.GetPayLoad();
  idMarca: any;

  constructor(private translatePipe: TranslatePipe,
    private tokenService: TokenService,
    private promocoesService: PromocoesService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.idMarca = params.get('idMarca');
    });

    this.listaPromocoes();
  }

  listaPromocoes() {
    this.promocoesService.listaPromocoes(this.idMarca).subscribe(res => {
      this.promocoes = res;
    });
  }

  excluir(id: string) {
    Swal.fire({
      title: this.translatePipe.transform('deletar_titulos'),
      text: this.translatePipe.transform('excluir_promocao'),
      showCancelButton: true,
      confirmButtonText: this.translatePipe.transform('deletar_sim'),
      cancelButtonText: this.translatePipe.transform('deletar_nao'),
    }).then(result => {
      if (result.value) {
        this.promocoesService.excluirPromocao(id).subscribe((res: any) => {
          this.listaPromocoes();
        });
      }
    });
  }

  editar(id: string) {
    this.router.navigate([`admin/promocoes/marca/${this.idMarca}/promocao/${id}/editar`]);
  }
}

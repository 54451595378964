import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarComponent } from '../components/toolbar/toolbar.component';
import { ImageHelperComponent } from '../components/image-helper/image-helper.component';

import { TokenService } from '../services/token.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { StreamsRoutingModule } from './streams-routing.module';
import { MarcasComponent } from '../components/marcas/marcas.component';
import { MarcaComponent } from '../components/marcas/marca/marca.component';
import { HomeComponent } from '../components/home/home.component';
import { ConfiguracoesPlataformaComponent } from '../components/configuracoes-plataforma/configuracoes-plataforma.component';
import { UnidadesComponent } from '../components/unidades/unidades.component';
import { UnidadeComponent } from '../components/unidades/unidade/unidade.component';
import { CardapiosComponent } from '../components/cardapios/cardapios.component';
import { CardapioComponent } from '../components/cardapios/cardapio/cardapio.component';
import { GerenciamentoUsuariosComponent } from './../components/gerenciamento-usuarios/gerenciamento-usuarios.component';
import { GerenciamentoUsuarioComponent } from './../components/gerenciamento-usuarios/gerenciamento-usuario/gerenciamento-usuario.component';
import { PagamentosComponent } from './../components/pagamentos/pagamentos.component';
import { PagamentoComponent } from './../components/pagamentos/pagamento/pagamento.component';
import { ProdutosExtrasComponent } from '../components/produtos-extras/produtos-extras.component';
import { PedidosComponent } from '../components/controle-pedidos/pedidos.component';
import { PromocoesComponent } from './../components/promocoes/promocoes.component';
import { PromocaoComponent } from './../components/promocoes/promocao/promocao.component';
import { ComboComponent } from '../components/combo/combo.component';
import { EntregasComponent } from '../components/controle-entregas/controle-entregas.component';
import { ControleRecebimentoComponent } from './../components/controle-recebimento/controle-recebimento.component';
import { ChatComponent } from './../components/chat/chat.component';
import { ModalCancelamentoPedidoComponent } from './../components/modal-cancelamento-pedido/modal-cancelamento-pedido.component';

import { CategoriasComponent } from './../components/categorias/categorias.component';
import { CategoriaComponent } from './../components/categorias/categoria/categoria.component';

import { SegmentosComponent } from './../components/segmentos/segmentos.component';
import { SegmentoComponent } from './../components/segmentos/segmento/segmento.component';


import { SegmentosSuperioresComponent } from './../components/segmentos-superiores/segmentos-superiores.component';
import { SegmentoSuperiorComponent } from './../components/segmentos-superiores/segmento-superior/segmento-superior.component';


import { OpcionaisComponent } from './../components/opcionais/opcionais.component';
import { OpcionalComponent } from './../components/opcionais/opcional/opcional.component';

import { EstoqueComponent } from './../components/estoque/estoque.component';
import { EstoqueDigitalComponent } from './../components/estoque/estoque-digital/estoque-digital.component';
import { EstoqueIngressosComponent } from './../components/estoque/estoque-ingressos/estoque-ingressos.component';

import { CuponsComponent } from '../components/cupons/cupons.component';
import { CupomComponent } from '../components/cupons/cupom/cupom.component';

import { TaxasComponent } from './../components/taxas/taxas.component';
import { MotivosCancelamentoComponent } from './../components/motivos-cancelamento/motivos-cancelamento.component';
import { DestaquesAplicativoComponent } from './../components/destaques-aplicativo/destaques-aplicativo.component';

import { EnquetesComponent } from './../components/enquetes/enquetes.component';

import { NgxMaskModule } from 'ngx-mask';
import { TranslatePipe } from '../pipes/translate.pipe';
import { TranslationService } from '../services/translation.service';
import { ImageCropperModule } from 'ngx-image-cropper';
import { Ng5SliderModule } from 'ng5-slider';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    StreamsRoutingModule,
    NgxMaskModule.forRoot(),
    ImageCropperModule,
    Ng5SliderModule,
    BrowserAnimationsModule,
    NgxChartsModule,
    PdfViewerModule
  ],
  declarations: [
    ToolbarComponent,
    ImageHelperComponent,
    HomeComponent,
    ConfiguracoesPlataformaComponent,
    MarcasComponent,
    MarcaComponent,
    UnidadesComponent,
    UnidadeComponent,
    CuponsComponent,
    CupomComponent,
    CardapiosComponent,
    CardapioComponent,
    CategoriasComponent,
    CategoriaComponent,
    SegmentosComponent,
    SegmentoComponent,
    SegmentosSuperioresComponent,
    SegmentoSuperiorComponent,
    OpcionaisComponent,
    OpcionalComponent,
    EstoqueComponent,
    EstoqueDigitalComponent,
    EstoqueIngressosComponent,
    GerenciamentoUsuariosComponent,
    GerenciamentoUsuarioComponent,
    PagamentosComponent,
    PagamentoComponent,
    ProdutosExtrasComponent,
    PromocoesComponent,
    PromocaoComponent,
    PedidosComponent,
    ComboComponent,
    EntregasComponent,
    ControleRecebimentoComponent,
    ChatComponent,
    ModalCancelamentoPedidoComponent,
    TaxasComponent,
    EnquetesComponent,
    MotivosCancelamentoComponent,
    DestaquesAplicativoComponent,
    TranslatePipe
  ],
  exports: [ToolbarComponent, ImageHelperComponent],
  providers: [
    TokenService,
    TranslationService,
    TranslatePipe,
    ImageHelperComponent
  ]
})
export class StreamsModule { }

<app-toolbar></app-toolbar>

<div class="container">

  <div class="center">
    <image-helper (convertedImage)="getImage($event)"
                  [croppedImage]="imageSecureUrl"></image-helper>
  </div>

  <div class="center">
    <span class="error"
          *ngIf="!image && !imageId && isSubmitted">
      {{'texto_obrigatorio' | translate}}
    </span>
  </div>

  <form class="col s6"
        [formGroup]="promocaoForm"
        novalidate
        (ngSubmit)="onSubmit(promocaoForm)">

    <div class="row">
      <div class="input-field col s6">
        <input id="nomeItem"
               type="text"
               formControlName="nomeItem">
        <label for="nomeItem"
               [class.active]="modoEdicao">{{'campo_nome_item' | translate}}</label>
        <span class="error"
              *ngIf="promocaoForm.controls['nomeItem'].invalid && 
          (promocaoForm.controls['nomeItem'].touched ||isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>

      <div class="input-field col s6">
        <input id="descricaoItem"
               type="text"
               formControlName="descricaoItem">
        <label for="descricaoItem"
               [class.active]="modoEdicao">{{'campo_descricao' | translate}}</label>
        <span class="error"
              *ngIf="promocaoForm.controls['descricaoItem'].invalid && 
          (promocaoForm.controls['descricaoItem'].touched ||isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>
    </div>

    <div class="form-group col s12 m7">
      <div formArrayName="valores">
        <div *ngFor="let valor of valores.controls; let i=index"
             [formGroup]="valor">
          <div class="row">
            <div class="input-field col s6"
                 style="margin-right: 10px;">
              <input id="value"
                     type="number"
                     formControlName="value">
              <label for="value"
                     [class.active]="modoEdicao">{{'campo_valor' | translate}}</label>
              <span class="error"
                    style="margin-top: 0.5rem;"
                    *ngIf="valores.controls[i].controls.value.invalid &&
                (valores.controls[i].controls.value.touched ||isSubmitted)">
                {{'texto_obrigatorio' | translate}}
              </span>
            </div>

            <div *ngIf="this.moedasCombo.length !== 1"
                 class="input-field col s3"
                 style="display: flex;
            flex-direction: column;
            margin-top: -11px;
            padding: 0;">
              <span class="labelSelect"
                    style="margin-left: 0;margin-bottom: 3px;margin-right: 10px;display: block;">
                {{'campo_moeda' | translate}}
              </span>

              <div class="input-field inline"
                   style="margin-top: 0px;
              margin-left: 0;">
                <select material-select
                        id="currency"
                        formControlName="currency"
                        (change)="selecionaMoeda(valores.controls[i].controls.currency.value)">
                  <option value=""
                          disabled
                          selected>{{'texto_selecione' | translate}}</option>
                  <option *ngFor="let moeda of moedasCombo"
                          [ngValue]="moeda._id"
                          [disabled]="moedasSelecionadas.includes(moeda._id)">
                    {{ moeda.name }}
                  </option>
                </select>

                <div style="margin-top: 0.5rem;">
                  <span class="error"
                        *ngIf="valores.controls[i].controls.currency.invalid &&
                  (valores.controls[i].controls.currency.touched ||isSubmitted)">
                    {{'texto_obrigatorio' | translate}}
                  </span>
                </div>
              </div>
            </div>

            <div *ngIf="this.moedasCombo.length !== 1"
                 class="input-field col s2">
              <a *ngIf="i === valores.length - 1"
                 class="btn-floating"
                 (click)="incluirValores()"
                 data-toggle="tooltip"
                 data-placement="bottom"
                 title="{{'texto_adicionar' | translate}}"
                 style="margin-top: 0;">
                <i class="material-icons">add</i>
              </a>

              <a *ngIf="i > 0"
                 class="btn-floating btn-remove"
                 (click)="removerValores(i)"
                 data-toggle="tooltip"
                 data-placement="bottom"
                 title="{{'texto_excluir' | translate}}"
                 style="margin-top: 0;">
                <i class="material-icons">cancel</i>
              </a>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="input-field col s6"
           style="margin-right: 10px;">
        <input id="taxas"
               type="number"
               formControlName="taxas">
        <label for="taxas"
               [class.active]="modoEdicao">{{'campo_taxa' | translate}}</label>
        <span class="error"
              *ngIf="promocaoForm.controls['taxas'].invalid && 
          (promocaoForm.controls['taxas'].touched ||isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>
    </div>

    <div class="row">
      <label class="labelSelect"
             [class.active]="modoEdicao">{{'campo_produto' | translate}}</label> <br />
      <div class="input-field col s6">
        <select material-select
                id="produto"
                formControlName="produto">
          <option value=""
                  disabled
                  selected>{{'texto_selecione' | translate}}</option>
          <option *ngFor="let produto of produtos"
                  [ngValue]="produto._id">{{ produto.name }}</option>
        </select>
        <span class="error"
              style="margin-top: 0.5rem;"
              *ngIf="(!promocaoForm.controls['produto'].value && produtoSubmitted) && produtosInclusos.length === 0">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>

      <div class="input-field col s2">
        <input id="quantidade"
               type="number"
               formControlName="quantidade">
        <label for="quantidade"
               [class.active]="modoEdicao">{{'campo_quantidade' | translate}}</label>
        <span class="error"
              *ngIf="(!promocaoForm.controls['quantidade'].value && produtoSubmitted) && produtosInclusos.length === 0">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>

      <button class="btn-floating"
              id="salvaGrid"
              (click)="incluirProduto()"
              type="button"
              style="margin-top: 15px;"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Incluir Item">
        <i class="material-icons">add</i>
      </button>
    </div>

    <div class="col s12 m12 l12">
      <div class="row"
           style="margin-top: 4rem; margin-bottom: 4rem;">

        <table>
          <thead>
            <tr>
              <th style="width: 50%;">{{'grid_produtos' | translate}}</th>
              <th style="width: 40%;">{{'campo_quantidade' | translate}}</th>
              <th style="text-align: center; width: 10%;">{{'texto_acao' | translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of produtosInclusos; let i = index ">
              <td>{{item.name}}</td>
              <td>{{item.quantity}}</td>
              <td (click)="removerItem(i)"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="{{'texto_excluir' | translate}}"
                  style="text-align: center;">
                <a class="btn-floating btn-remove"
                   style="margin-top: 0px;">
                  <i class="material-icons">cancel</i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row center botoes-acao">
      <button class="btn btnCancelar waves-effect"
              id="cancelar"
              (click)="cancelar()"
              type="button">
        {{'texto_cancelar' | translate}}
      </button>
      <button class="btn btnSalvar waves-effect"
              id="salvar"
              type="button"
              (click)="onSubmit(promocaoForm)"
              [disabled]="uploadImage">
        {{'texto_salvar' | translate}}
      </button>
    </div>
  </form>
</div>
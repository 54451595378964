<app-toolbar></app-toolbar>
<div class="container">
  <div class="card-panel">
    <form class="col s6"
          [formGroup]="motivoCancelamentoForm"
          novalidate
          (ngSubmit)="onSubmit(motivoCancelamentoForm)">
      <div class="container">
        <div class="row">
          <div class="input-field col s12"
               style="margin-top: -10px;">
            <label class="labelSelect"
                   [class.active]="modoEdicao"
                   for="descricao">{{'motivos_cancelamento_descricao' | translate}}</label>
            <input id="descricao"
                   type="text"
                   formControlName="descricao">
            <span class="error"
                  *ngIf="motivoCancelamentoForm.controls['descricao'].invalid && 
            (motivoCancelamentoForm.controls['descricao'].touched || isSubmitted)">
              {{'texto_obrigatorio' | translate}}
            </span>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="row">
    <button *ngIf="!modoEdicao"
            class="btn btnSalvar waves-effect right"
            id="salvar"
            type="button"
            (click)="onSubmit(motivoCancelamentoForm)"
            [disabled]="uploadImage">
      {{'texto_salvar' | translate}}
    </button>

    <button *ngIf="modoEdicao"
            class="btn btnSalvar waves-effect right"
            id="salvar"
            type="button"
            (click)="onSubmitEdit(motivoCancelamentoForm)">
      {{'texto_salvar' | translate}}
    </button>

    <button *ngIf="modoEdicao"
            class="btn btnCancelar right"
            id="cancelar"
            (click)="cancelar()"
            type="button">
      {{'texto_cancelar' | translate}}
    </button>

  </div>

  <table style="margin-top: 5rem;">
    <thead>
      <tr>
        <th style="width: 80%;">{{'motivos_cancelamento_descricao' | translate}}</th>
        <th style="text-align: center;">{{'texto_acao' | translate}}</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let item of motivos">
        <td style="width:80%;">{{item.description}}</td>
        <td style="text-align: center;">
          <a class="btn-floating btn-edit"
             (click)="editarItemCombo(item._id)"
             data-toggle="tooltip"
             data-placement="bottom"
             title="{{'texto_editar' | translate}}">
            <i class="material-icons"
               style="color: orange;">create</i>
          </a>

          <a class="btn-floating btn-remove"
             (click)="deletarItemCombo(item._id)"
             data-toggle="tooltip"
             data-placement="bottom"
             title="{{'texto_excluir' | translate}}">
            <i class="material-icons">cancel</i>
          </a>

        </td>
      </tr>

    </tbody>
  </table>

  <div class="row center"
       style="margin-top: 3rem;">
    <button class="btn btnVoltar"
            id="voltar"
            (click)="voltar()"
            type="button">
      {{'texto_voltar' | translate}}
    </button>
  </div>
</div>
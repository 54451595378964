import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PedidosService {
  BASEURL: string;
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    this.BASEURL = environment.apiUrl;

    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tokenMappe'),
    });
  }

  obterPedidos(): Observable<any> {
    return this.http.get(`${this.BASEURL}/orders`, {
      headers: this.httpHeaders,
    });
  }

  obterPedidosChat(): Observable<any> {
    return this.http.get(`${this.BASEURL}/orders-chat`, {
      headers: this.httpHeaders,
    });
  }

  trocarStatus(id: string, body: any): Observable<any> {
    return this.http.put(`${this.BASEURL}/orders/${id}/update-status`, body, {
      headers: this.httpHeaders,
    });
  }

  cancelarPedido(id: string, body: any): Observable<any> {
    return this.http.put(`${this.BASEURL}/orders/${id}/update-status`, body, {
      headers: this.httpHeaders,
    });
  }

  obterEntregadores(): Observable<any> {
    return this.http.get(`${this.BASEURL}/users/delivery-people`, {
      headers: this.httpHeaders,
    });
  }

  vinculaEntregador(id: string, body: any): Observable<any> {
    return this.http.post(`${this.BASEURL}/orders/${id}/link-delivery-person`, body, {
      headers: this.httpHeaders,
    });
  }

  obterDadosEntregador(id: string): Observable<any> {
    return this.http.get(`${this.BASEURL}/users/${id}`, {
      headers: this.httpHeaders,
    });
  }

  //CHAT
  obterMensagens(id: string): Observable<any> {
    return this.http.get(`${this.BASEURL}/chat/${id}/messages`, {
      headers: this.httpHeaders,
    });
  }

  enviarMensagem(body: any): Observable<any> {
    return this.http.post(`${this.BASEURL}/chat`, body, {
      headers: this.httpHeaders,
    });
  }

  obterPedidosUltimos30Dias(): Observable<any> {
    return this.http.get(`${this.BASEURL}/orders-last30days`, {
      headers: this.httpHeaders,
    });
  }
}

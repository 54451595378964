import { HttpClientModule } from '@angular/common/http';
import { PromocoesService } from './../../../services/promocoes.service';
import { TokenService } from './../../../services/token.service';
import { FormGroup, FormBuilder, Validators, FormArray, RequiredValidator } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { TranslatePipe } from '../../../pipes/translate.pipe';

@Component({
  selector: 'app-promocao',
  templateUrl: './promocao.component.html',
  styleUrls: ['./promocao.component.css']
})
export class PromocaoComponent implements OnInit {
  isSubmitted = false;
  @Output() mudaItems = new EventEmitter();
  promocaoForm: FormGroup;
  idMarca: string;
  idPromocao: string;
  url = null;
  user = this.tokenService.GetPayLoad();
  teste = this.tokenService.GetPayLoad();
  modoEdicao = false;
  produtoSubmitted = false;
  uploadImage = false;
  image: any;
  imageId: string;
  imageUrl: string;
  imageSecureUrl: string;
  produtos: any;
  moedasCombo: any;
  produtosInclusos = [];
  produtosQuantidade = [];
  moedasSelecionadas = [];

  constructor(
    private translatePipe: TranslatePipe,
    private fb: FormBuilder,
    private promocoesService: PromocoesService,
    private tokenService: TokenService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.init();

    this.route.paramMap.subscribe(params => {
      this.idMarca = params.get('idMarca');
      this.idPromocao = params.get('idPromocao');
    });

    this.obterMoedas();

    this.obterProdutos();
  }

  init() {
    this.promocaoForm = this.fb.group({
      nomeItem: ['', Validators.required],
      descricaoItem: ['', Validators.required],
      taxas: [''],
      produto: [''],
      quantidade: [''],
      valores: this.fb.array([])
    });
  }

  incluirProduto() {
    this.produtoSubmitted = true;

    if (!this.promocaoForm.value.produto || !this.promocaoForm.value.quantidade) {
      return;
    }

    let produtoSelecionado = null;
    this.produtos.forEach(produto => {
      if (produto._id === this.promocaoForm.value.produto) {
        produtoSelecionado = produto;
      }
    });

    this.produtosInclusos.push({
      name: produtoSelecionado.name,
      item: produtoSelecionado._id,
      quantity: this.promocaoForm.value.quantidade
    });

    this.promocaoForm.controls.quantidade.markAsUntouched();
    this.promocaoForm.controls.quantidade.setValue(null);
    this.promocaoForm.controls.produto.markAsUntouched();
    this.promocaoForm.controls.produto.setValue('');
    this.produtoSubmitted = false;
  }

  removerItem(index) {
    this.produtosInclusos.splice(index, 1);
  }

  get valores() {
    return this.promocaoForm.get('valores') as FormArray;
  }

  incluirValores() {
    this.moedasSelecionadas = [];
    this.valores.controls.forEach(control => {
      this.moedasSelecionadas.push(control.value.currency);
    });
    this.promocaoForm.controls.valores['submitted'] = true;
    const valor = this.fb.group({
      value: ['', Validators.required],
      currency: [(this.moedasCombo.length === 1 ? this.moedasCombo[0]._id : ''), Validators.required]
    });

    this.valores.push(valor);
  }

  removerValores(index: number) {
    this.moedasSelecionadas.splice(
      this.moedasSelecionadas.indexOf(this.valores.controls[index]['controls'].currency.value),
      1
    );
    this.valores.removeAt(index);
  }

  obterMoedas() {
    this.promocoesService.obterMoedas().subscribe(res => {
      this.moedasCombo = res;

      if (this.idPromocao) {
        this.modoEdicao = true;
        this.carregaCampos();
      } else {
        this.incluirValores();
      }
    });
  }

  selecionaMoeda(currency, event) {
    this.moedasSelecionadas = [];
    this.valores.controls.forEach(control => {
      this.moedasSelecionadas.push(control.value.currency);
    });
  }

  obterProdutos() {
    this.promocoesService.listaProdutosPorMarca(this.idMarca).subscribe(res => {
      this.produtos = res;
    });
  }
  getImage(event) {
    this.image = event;
  }

  onSubmit(form: FormGroup) {
    this.isSubmitted = true;

    if (form.valid && this.produtosInclusos.length >= 1 && (this.image || this.imageId)) {
      this.produtosInclusos.forEach(item => {
        this.produtosQuantidade.push({
          item: item.item,
          quantity: item.quantity
        });
      });
      
      const valores = this.valores.value;

      if (this.image) {
        this.promocoesService.uploadImages(this.image).subscribe(res => {
          this.salvar({
            name: this.promocaoForm.value.nomeItem,
            description: this.promocaoForm.value.descricaoItem,
            prices: valores,
            delivery_tax: this.promocaoForm.value.taxas == '' ? 0 : this.promocaoForm.value.taxas,
            brand: this.idMarca,
            products: this.produtosQuantidade,
            image: {
              id: res.public_id,
              url: res.url,
              secure_url: res.secure_url
            },
          });
        });
      }
      else {
        this.salvar({
          name: this.promocaoForm.value.nomeItem,
          description: this.promocaoForm.value.descricaoItem,
          prices: valores,
          delivery_tax: this.promocaoForm.value.taxas == '' ? 0 : this.promocaoForm.value.taxas,
          brand: this.idMarca,
          products: this.produtosQuantidade,
          image: {
            id: this.imageId,
            url: this.imageUrl,
            secure_url: this.imageSecureUrl
          }
        });
      }

    } else {
      if (
        this.promocaoForm.controls.nomeItem.value &&
        this.promocaoForm.controls.descricaoItem.value &&
        this.promocaoForm.controls.quantidade.value &&
        this.promocaoForm.controls.produto.value &&
        this.produtosInclusos.length === 0
      ) {
        Swal.fire(this.translatePipe.transform('promocao_titulo'), this.translatePipe.transform('produto_minimo'), 'error');
      }
      this.produtoSubmitted = true;
    }
  }

  salvar(promocaoForm) {
    if (this.idPromocao) {
      this.promocoesService.editarPromocao(this.idPromocao, promocaoForm).subscribe(
        res => {
          Swal.fire(this.translatePipe.transform('promocao_titulo'), this.translatePipe.transform('promocao_editado'), 'success').then(() => {
            this.router.navigate([`admin/promocoes/marca/${this.idMarca}`]);
          });
        },
        error => {
          Swal.fire(this.translatePipe.transform('promocao_titulo'), error.error.message, 'error');
        }
      );
    } else {
      this.promocoesService.salvaPromocao(promocaoForm).subscribe(
        res => {
          Swal.fire(this.translatePipe.transform('promocao_titulo'), this.translatePipe.transform('promocao_cadastrado'), 'success').then(() => {
            this.router.navigate([`admin/promocoes/marca/${this.idMarca}`]);
          });
        },
        error => {
          Swal.fire(this.translatePipe.transform('promocao_titulo'), error.error.message, 'error');
        }
      );
    }
  }

  cancelar() {
    this.router.navigate([`admin/promocoes/marca/${this.idMarca}`]);
  }

  // Editar
  carregaCampos() {
    this.isSubmitted = true;
    this.promocoesService.obterPromocao(this.idPromocao).subscribe(res => {
      this.promocaoForm.controls.nomeItem.setValue(res.name);
      this.promocaoForm.controls.descricaoItem.setValue(res.description);
      this.promocaoForm.controls.taxas.setValue(res.delivery_tax);
      this.imageId = res.image.id;
      this.imageUrl = res.image.url;
      this.imageSecureUrl = res.image.secure_url;
      this.url = res.image.url;

      res.items.forEach(item => {
        this.produtosInclusos.push({
          item: item.item._id,
          name: item.item.name,
          quantity: item.quantity
        });
      });

      res.prices.forEach(item => {
        this.moedasSelecionadas.push(item.currency);
        const valor = this.fb.group({
          value: [item.value, Validators.required],
          currency: [item.currency, Validators.required]
        });
        this.valores.push(valor);
      });
      this.obterProdutos();
    });
    this.promocaoForm.updateValueAndValidity();
  }
}

<app-toolbar></app-toolbar>
<label class="tempo-renovacao">
  <button (click)="atualizarTela()"><img class="imgIcone"
         src="../../../assets/icones/refresh.svg"
         title="Reiniciar Imagem"></button>
  {{tempoAtualizacao}}
</label>
<div class="row"
     style="justify-content: center;align-items: center;display: flex;">

  <div class="col s12 m12 l12 card-block">
    <div class="col s12 m4">
      <label class="titleCard center">{{'novo_pedido' | translate}}</label>
      <div class="card-panel cardNovosPedidos">

        <div *ngFor="let pedido of pedidosNovos"
             class="card-action">
          <a class="btn-floating btn-passar waves-effect waves-light right"
             data-toggle="tooltip"
             data-placement="bottom"
             title="Passar para Produção"
             (click)="mudaStatusPedido(pedido._id)">
            <i class="material-icons icone-passar">navigate_next</i>
          </a>

          <div>
            <label class="titulo-pedido">
              {{'pedido_numero' | translate}} {{ pedido._id.substr(-5,5).toUpperCase() }}
            </label>
            <a class="botoes"
               (click)="cancelamentoPedido(pedido)">
              <img class="imgIcones"
                   src="../../../assets/icones/excluir.svg"
                   [title]="translatePipe.transform('texto_excluir')">
            </a>
            <label class="horario-pedido right">
              {{pedido.createdAt | date:'dd/MM/yyyy HH:mm'}}
            </label>

            <hr style="margin-bottom: -3px;
              margin-top: 3px;">
          </div>
          <div>
            <label class="horario-pedido">
              Entregar em: {{pedido.delivery_address.address_line_1}}, {{pedido.delivery_address.number}}
              {{pedido.delivery_address.address_line_2}} - {{pedido.delivery_address.area}} -
              {{pedido.delivery_address.city}} - {{pedido.delivery_address.zip_code}}
            </label>
            <br>
            <label class="horario-pedido">{{pedido.payment_method.name}} - {{'campo_valor' | translate}}:
              {{pedido.restaurant.currency_Symbol}} {{pedido.total | number:'0.2-2'}} </label>
            <label *ngIf="pedido.payment_method.requested_change > 0"
                   class="horario-pedido"> {{'troco' | translate}}:
              {{pedido.restaurant.currency_Symbol}} {{pedido.payment_method.requested_change | number:'0.2-2'}} </label>
            <hr style="margin-bottom: -3px;
              margin-top: 3px;">
          </div>

          <div *ngFor="let item of pedido.items">
            <label class="titulo-item-pedido">
              {{item.name}}
            </label>

            <ul style="margin-top: 0;">
              <li>
                <label class="subtitulo-itens-pedido">{{'pedido_quantidade' | translate}}: {{item.quantity}}</label>
              </li>

              <li *ngIf="item.combo?.length">
                <label class="subtitulo-itens-pedido">Opções:</label>
              </li>
              <li *ngFor="let item of item.combo">
                <label class="itens-pedido">{{item.category}}: {{item.name}}</label>
              </li>

              <li *ngIf="item.removed?.length">
                <label class="subtitulo-itens-pedido">Retirar:</label>
              </li>
              <li *ngFor="let item of item.removed">
                <label class="itens-pedido">{{item}}</label>
              </li>

              <li *ngIf="item.extras?.length">
                <label class="subtitulo-itens-pedido">Adicionar:</label>
              </li>
              <li *ngFor="let item of item.extras">
                <label class="itens-pedido"
                       *ngIf="item.quantity > 0">{{item.name}} - {{item.quantity }}</label>
              </li>

              <li *ngIf="item.observations?.length">
                <label class="subtitulo-itens-pedido">Observações:</label>
              </li>
              <li *ngIf="item.observations?.length">
                <label *ngIf="item.observations?.length"
                       class="itens-pedido">{{item.observations}}</label>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="col s12 m4">
      <label class="titleCard center">{{'pedido_producao' | translate}}</label>
      <div class="card-panel cardPedidosProducao">
        <div *ngFor="let pedido of pedidosEmProducao"
             class="card-action">
          <a class="btn-floating btn-passar waves-effect waves-light right"
             data-toggle="tooltip"
             data-placement="bottom"
             title="Passar para Liberados"
             (click)="mudaStatusPedido(pedido._id)">
            <i class="material-icons icone-passar">navigate_next</i>
          </a>

          <a class="btn-floating btn-voltar waves-effect waves-light right"
             data-toggle="tooltip"
             data-placement="bottom"
             title="Voltar para Novos Pedidos"
             (click)="mudaStatusPedidoRetorna(pedido._id)">
            <i class="material-icons icone-passar">navigate_before</i>
          </a>

          <div>
            <label class="titulo-pedido">
              {{'pedido_numero' | translate}} {{ pedido._id.substr(-5,5).toUpperCase() }}
            </label>
            <a class="botoes"
               (click)="cancelamentoPedido(pedido)">
              <img class="imgIcones"
                   src="../../../assets/icones/excluir.svg"
                   [title]="translatePipe.transform('texto_excluir')">
            </a>
            <label class="horario-pedido right">
              {{pedido.createdAt | date:'dd/MM/yyyy HH:mm'}}
            </label>
            <hr style="margin-bottom: -3px;
              margin-top: 3px;">
          </div>
          <div>
            <label class="horario-pedido">
              Entregar em: {{pedido.delivery_address.address_line_1}}, {{pedido.delivery_address.number}}
              {{pedido.delivery_address.address_line_2}} - {{pedido.delivery_address.area}} -
              {{pedido.delivery_address.city}} - {{pedido.delivery_address.zip_code}}
            </label>
            <br>
            <label class="horario-pedido">{{pedido.payment_method.name}} - {{'campo_valor' | translate}}:
              {{pedido.restaurant.currency_Symbol}} {{pedido.total | number:'0.2-2'}} </label>
            <label *ngIf="pedido.payment_method.requested_change > 0"
                   class="horario-pedido"> {{'troco' | translate}}:
              {{pedido.restaurant.currency_Symbol}} {{pedido.payment_method.requested_change | number:'0.2-2'}} </label>
            <hr style="margin-bottom: -3px;
              margin-top: 3px;">



          </div>

          <div *ngFor="let item of pedido.items">
            <label class="titulo-item-pedido">
              {{item.name}}
            </label>

            <ul style="margin-top: 0;">
              <li>
                <label class="subtitulo-itens-pedido">{{'pedido_quantidade' | translate}}: {{item.quantity}}</label>
              </li>

              <li *ngIf="item.combo?.length">
                <label class="subtitulo-itens-pedido">Opções:</label>
              </li>
              <li *ngFor="let item of item.combo">
                <label class="itens-pedido">{{item.category}}: {{item.name}}</label>
              </li>

              <li *ngIf="item.removed?.length">
                <label class="subtitulo-itens-pedido">Retirar:</label>
              </li>
              <li *ngFor="let item of item.removed">
                <label class="itens-pedido">{{item}}</label>
              </li>

              <li *ngIf="item.extras?.length">
                <label class="subtitulo-itens-pedido">Adicionar:</label>
              </li>
              <li *ngFor="let item of item.extras">
                <label class="itens-pedido"
                       *ngIf="item.quantity > 0">{{item.name}} - {{item.quantity }}</label>
              </li>

              <li *ngIf="item.observations?.length">
                <label class="subtitulo-itens-pedido">Observações:</label>
              </li>
              <li *ngIf="item.observations?.length">
                <label *ngIf="item.observations?.length"
                       class="itens-pedido">{{item.observations}}</label>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="col s12 m4">
      <label class="titleCard center">{{'pedido_liberado' | translate}}</label>
      <div class="card-panel cardPedidosFinalizados">
        <div *ngFor="let pedido of pedidosLiberados"
             class="card-action">
          <a class="btn-floating btn-voltar waves-effect waves-light right"
             data-toggle="tooltip"
             data-placement="bottom"
             title="Voltar para Produção"
             (click)="mudaStatusPedidoRetorna(pedido._id)">
            <i class="material-icons icone-passar">navigate_before</i>
          </a>

          <div>
            <label class="titulo-pedido">
              {{'pedido_numero' | translate}} {{ pedido._id.substr(-5,5).toUpperCase() }}
            </label>
            <a class="botoes"
               (click)="cancelamentoPedido(pedido)">
              <img class="imgIcones"
                   src="../../../assets/icones/excluir.svg"
                   [title]="translatePipe.transform('texto_excluir')">
            </a>
            <label class="horario-pedido right">
              {{pedido.createdAt | date:'dd/MM/yyyy HH:mm'}}
            </label>
            <hr style="margin-bottom: -3px;
              margin-top: 3px;">
          </div>
          <div>
            <label class="horario-pedido">
              Entregar em: {{pedido.delivery_address.address_line_1}}, {{pedido.delivery_address.number}}
              {{pedido.delivery_address.address_line_2}} - {{pedido.delivery_address.area}} -
              {{pedido.delivery_address.city}} - {{pedido.delivery_address.zip_code}}
            </label>
            <br>
            <label class="horario-pedido">{{pedido.payment_method.name}} - {{'campo_valor' | translate}}:
              {{pedido.restaurant.currency_Symbol}} {{pedido.total | number:'0.2-2'}} </label>
            <label *ngIf="pedido.payment_method.requested_change > 0"
                   class="horario-pedido"> {{'troco' | translate}}:
              {{pedido.restaurant.currency_Symbol}} {{pedido.payment_method.requested_change | number:'0.2-2'}} </label>
            <hr style="margin-bottom: -3px;
              margin-top: 3px;">
          </div>

          <div *ngFor="let item of pedido.items">
            <label class="titulo-item-pedido">
              {{item.name}}
            </label>

            <ul style="margin-top: 0;">
              <li>
                <label class="subtitulo-itens-pedido">{{'pedido_quantidade' | translate}}: {{item.quantity}}</label>
              </li>

              <li *ngIf="item.combo?.length">
                <label class="subtitulo-itens-pedido">Opções:</label>
              </li>
              <li *ngFor="let item of item.combo">
                <label class="itens-pedido">{{item.category}}: {{item.name}}</label>
              </li>

              <li *ngIf="item.removed?.length">
                <label class="subtitulo-itens-pedido">Retirar:</label>
              </li>
              <li *ngFor="let item of item.removed">
                <label class="itens-pedido">{{item}}</label>
              </li>

              <li *ngIf="item.extras?.length">
                <label class="subtitulo-itens-pedido">Adicionar:</label>
              </li>
              <li *ngFor="let item of item.extras">
                <label class="itens-pedido"
                       *ngIf="item.quantity > 0">{{item.name}} - {{item.quantity }}</label>
              </li>

              <li *ngIf="item.observations?.length">
                <label class="subtitulo-itens-pedido">Observações:</label>
              </li>
              <li *ngIf="item.observations?.length">
                <label *ngIf="item.observations?.length"
                       class="itens-pedido">{{item.observations}}</label>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div *ngIf="modalCancelamento"
       class="cancelamento-modal">
    <div class="cancelamento-close-div">
      <a (click)="fecharModalCancelamento()">
        <img class="cancelamento-close-icon"
             src="assets/icones/close.svg">
      </a>
    </div>
    <app-modal-cancelamento-pedido [pedido]="pedidoCancelamento"
                                   (pedidoCancelado)="pedidoCancelado()"></app-modal-cancelamento-pedido>
  </div>

</div>
<app-toolbar></app-toolbar>

<div class="container">

  <div class="center">
    <image-helper (convertedImage)="getImage($event)"
                  [croppedImage]="imageSecureUrl"></image-helper>
  </div>

  <div class="center">
    <span class="error"
          *ngIf="!image && !imageId && isSubmitted">
      {{'texto_obrigatorio' | translate}}
    </span>
  </div>

  <form class="col s6"
        [formGroup]="categoriaForm"
        novalidate
        (ngSubmit)="onSubmit(categoriaForm)">

    <div class="row">
      <div class="input-field input-center col s6">
        <input id="nomeItem"
               type="text"
               formControlName="nomeItem">
        <label for="nomeItem"
               [class.active]="modoEdicao">{{'campo_descricao' | translate}}</label>
        <span class="error"
              *ngIf="categoriaForm.controls['nomeItem'].invalid && 
          (categoriaForm.controls['nomeItem'].touched ||isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>


    </div>

    <div class="row">
      <div class="input-field input-center col s6"
           style="margin-top: 15px;">

        <select materialize="material_select"
                class="initialized ng-untouched ng-pristine ng-valid"
                id="tipo"
                formControlName="tipo">
          <option value=""
                  disabled
                  selected>{{'texto_tipo' | translate}}</option>
          <option value="Storable">{{'produto_fisico' | translate}}</option>
          <option value="Digital">{{'produto_digital' | translate}}</option>
          <option value="Ticket">{{'produto_ingresso' | translate}}</option>
        </select>
        <label for="preco"
               [class.active]="true">{{'texto_tipo' | translate}}</label>

        <span class="error"
              *ngIf="categoriaForm.controls['tipo'].invalid && 
    (categoriaForm.controls['tipo'].touched ||isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>

      </div>

    </div>

    <div class="row">
      <div class="input-field input-center col s6">
        <input id="preco"
               type="number"
               formControlName="preco">
        <label for="preco"
               [class.active]="modoEdicao">{{'campo_valor' | translate}}</label>
      </div>
    </div>

    <div *ngIf="categoriaForm.controls['tipo'].value === 'Storable'" class="row">
      <div class="input-field input-center col s3">
        <input id="tempo_preparacao"
               type="number"
               formControlName="tempo_preparacao">
        <label for="tempo_preparacao"
               [class.active]="modoEdicao">{{'campo_tempo_preparacao' | translate}}</label>
      </div>

      <div class="input-field inline col s3"
           style="margin-top: 15px;">
        <select materialize="material_select"
                class="initialized ng-untouched ng-pristine ng-valid"
                id="tipo_tempo_preparacao"
                formControlName="tipo_tempo_preparacao">
          <option value=""
                  disabled
                  selected>{{'campo_tipo_entrega' | translate}}</option>
          <option value="minute">{{'minutos' | translate}}</option>
          <option value="hour">{{'horas' | translate}}</option>
          <option value="day">{{'dias' | translate}}</option>
        </select>
      </div>
    </div>

    <div class="row center">
      <button class="btn btnCancelar waves-effect"
              id="cancelar"
              (click)="cancelar()"
              type="button">
        {{'texto_cancelar' | translate}}
      </button>
      <button class="btn btnSalvar waves-effect"
              id="salvar"
              type="button"
              (click)="onSubmit(categoriaForm)"
              [disabled]="uploadImage">
        {{'texto_salvar' | translate}}
      </button>
    </div>
  </form>
</div>
import { Router, ActivatedRoute } from '@angular/router';
import { TokenService } from '../../services/token.service';
import { PedidosService } from './../../services/pedidos.service';
import { FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-controle-recebimento',
  templateUrl: './controle-recebimento.component.html',
  styleUrls: ['./controle-recebimento.component.css']
})
export class ControleRecebimentoComponent implements OnInit {
  pedidos = [];
  pedidosEntregues = [];
  pedidosFinalizados = [];

  user = this.tokenService.GetPayLoad();

  constructor(
    private tokenService: TokenService,
    private fb: FormBuilder,
    private pedidosService: PedidosService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.filtrarPedidos();
  }

  filtrarPedidos() {
    this.pedidosService.obterPedidos().subscribe(res => {
      this.pedidos = res;

      this.pedidos.map(pedido => {
        pedido.delivery_person.stars = [];
        while (pedido.delivery_person.stars.length < pedido.delivery_person.rating) {
          pedido.delivery_person.stars.push(1);
        }
        switch (pedido.status) {
          case 'delivered':
            this.pedidosEntregues.push(pedido);
            break;
          case 'closed':
            this.pedidosFinalizados.push(pedido);
            break;
        }
      });
    });
    this.pedidosEntregues = [];
    this.pedidosFinalizados = [];
  }

  mudaStatusPedido(_id: string) {

    this.pedidos.map(pedido => {
      if (pedido._id === _id) {
        switch (pedido.status) {
          case 'delivered':
            let statusClosed = { status: 'closed' };
            this.pedidosService.trocarStatus(_id, statusClosed).subscribe(res => {
              this.filtrarPedidos();
            });
            break;
        }
      }
    });
  }
}

import { HttpClientModule } from '@angular/common/http';
import { TokenService } from '../../../services/token.service';
import { CuponsService } from '../../../services/cupons.service';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule, FormArray } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { TranslatePipe } from '../../../pipes/translate.pipe';
import * as moment from 'moment';

@Component({
  selector: 'app-cupom',
  templateUrl: './cupom.component.html',
  styleUrls: ['./cupom.component.css']
})
export class CupomComponent implements OnInit {
  isSubmitted = false;
  cupomForm: FormGroup;
  idCupom: string;
  idMarca: string;
  user = this.tokenService.GetPayLoad();
  modoEdicao = false;
  cep: any;

  marcasCombo: any;

  constructor(
    private translatePipe: TranslatePipe,
    private fb: FormBuilder,
    private cuponsService: CuponsService,
    private tokenService: TokenService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {

    this.init();

    this.route.paramMap.subscribe(params => {
      this.idCupom = params.get('id');
      this.idMarca = params.get('idMarca');

      this.obterMarcas();

      if (this.idCupom) {
        this.modoEdicao = true;
        this.carregaCampos();
      } else {
        this.incluirMarcas();
      }
    });
  }

  init() {
    this.cupomForm = this.fb.group({
      codigo: ['', Validators.required],
      nome: ['', [Validators.required]],
      quantidade: ['', [Validators.required]],
      valor: ['', [Validators.required]],
      tipoValor: ['', [Validators.required]],
      valorMinimo: ['', [Validators.required]],
      vigencia: ['', [Validators.required]],
      validade: ['', [Validators.required]],
      freteGratis: [false],
      taxaClienteGratis: [false],
      entregaManual: [false],
      limitePeriodo: ['', [Validators.required]],
      limitePeriodoTipo: ['', [Validators.required]],
      marcas: this.fb.array([]),
      primeirasCompras: ['', [Validators.required]],
      ultimaCompra: ['', [Validators.required]],
      indicarUsuarios: ['', [Validators.required]],
      distanciaEndereco: ['', [Validators.required]],
      zipCode: [''],
      rua: [''],
      numero: [''],
    });
  }

  async buscarCEP() {
    this.cuponsService.obterLogradouroCep(this.cupomForm.value.zipCode).subscribe(res => {
      this.cep = res;
      this.cupomForm.controls.zipCode.setValue(res.cep.replace('-', ''));
      this.cupomForm.controls.rua.setValue(res.logradouro + ' - ' + res.bairro);
    })
  }

  obterMarcas() {
    this.cuponsService.listaMarcas().subscribe(res => {
      this.marcasCombo = res;
    });
  }

  get marcas() {
    return this.cupomForm.get('marcas') as FormArray;
  }

  incluirMarcas() {
    const marca = this.fb.group({
      campoMarca: [''],
      quantidadeMarca: [''],
      limitePeriodoMarca: [''],
      limitePeriodoTipoMarca: [''],
    });

    this.marcas.push(marca);
  }

  removerMarcas(index: number) {
    this.marcas.removeAt(index);
  }

  onSubmit(form: FormGroup) {
    this.isSubmitted = true;

    if (form.valid) {

      this.cupomForm.updateValueAndValidity();

      let marcasSelecionadas = [];

      this.marcas.value.map(item => {

        if (item.campoMarca && item.quantidadeMarca && item.limitePeriodoMarca && item.limitePeriodoTipoMarca) {
          marcasSelecionadas.push({
            brand: item.campoMarca,
            quantity: item.quantidadeMarca,
            limit_use: item.limitePeriodoMarca,
            limit_use_type: item.limitePeriodoTipoMarca,
          });
        }
      });

      this.salvar({
        brand: (this.idMarca ? this.idMarca : undefined),
        code: this.cupomForm.value.codigo,
        name: this.cupomForm.value.nome,
        quantity: this.cupomForm.value.quantidade,
        amount: this.cupomForm.value.valor,
        amount_type: this.cupomForm.value.tipoValor,
        minimum_amount: this.cupomForm.value.valorMinimo,
        start_date: this.cupomForm.value.vigencia,
        end_date: this.cupomForm.value.validade,
        free_delivery: this.cupomForm.value.freteGratis,
        free_client_fee: this.cupomForm.value.taxaClienteGratis,
        limit_use: this.cupomForm.value.limitePeriodo,
        limit_use_type: this.cupomForm.value.limitePeriodoTipo,
        brands: marcasSelecionadas,

        first_purchases: this.cupomForm.value.primeirasCompras,
        latest_purchases: this.cupomForm.value.ultimaCompra,
        referred_users: this.cupomForm.value.indicarUsuarios,
        distance_from_address: this.cupomForm.value.distanciaEndereco,
        zip_code: this.cupomForm.value.zipCode ? this.cupomForm.value.zipCode : undefined,
        address_line_1: this.cep?.logradouro,
        city: this.cep?.localidade,
        number: this.cupomForm.value.numero ? this.cupomForm.value.numero : undefined,
        manual_delivery: this.cupomForm.value.entregaManual
      });
    }

  }

  salvar(cupom: any) {
    if (this.idCupom) {
      this.cuponsService.editarItemCupom(this.idCupom, cupom).subscribe(
        res => {
          Swal.fire(this.translatePipe.transform('gerenciamento_cupons_titulo'), this.translatePipe.transform('gerenciamento_cupons_editado'), 'success').then(() => {
            this.voltar();
          });
        },
        error => {
          Swal.fire(this.translatePipe.transform('gerenciamento_cupons_titulo'), error.error.message, 'error');
        }
      );
    } else {
      this.cuponsService.salvaCupom(cupom).subscribe(
        res => {

          Swal.fire(this.translatePipe.transform('gerenciamento_cupons_titulo'), this.translatePipe.transform('gerenciamento_cupons_cadastrado'), 'success').then(() => {
            this.voltar();
          });


        },
        error => {
          Swal.fire(this.translatePipe.transform('gerenciamento_cupons_titulo'), this.translatePipe.transform(error.error.message), 'error');
        }
      );
    }
  }

  voltar() {
    if (this.idMarca) {
      this.router.navigate([`admin/cupons/marca/${this.idMarca}`]);
    }
    else {
      this.router.navigate(['admin/cupons']);
    }
  }

  // Editar
  carregaCampos() {
    this.cuponsService.listaItemCupom(this.idCupom).subscribe(res => {
      this.isSubmitted = true;

      this.cupomForm.controls.codigo.setValue(res.code);
      this.cupomForm.controls.nome.setValue(res.name);
      this.cupomForm.controls.quantidade.setValue(res.quantity);
      this.cupomForm.controls.valor.setValue(res.amount);
      this.cupomForm.controls.tipoValor.setValue(res.amount_type);
      this.cupomForm.controls.valorMinimo.setValue(res.minimum_amount);

      this.cupomForm.controls.vigencia.setValue(res.start_date.toString().substr(0, 10));
      this.cupomForm.controls.validade.setValue(res.end_date.toString().substr(0, 10));

      this.cupomForm.controls.freteGratis.setValue(res.free_delivery);
      this.cupomForm.controls.taxaClienteGratis.setValue(res.free_client_fee);

      this.cupomForm.controls.limitePeriodo.setValue(res.limit_use);
      this.cupomForm.controls.limitePeriodoTipo.setValue(res.limit_use_type);

      res.brands.forEach(cupom => {

        const marca = this.fb.group({
          campoMarca: [cupom.brand._id],
          quantidadeMarca: [cupom.quantity],
          limitePeriodoMarca: [cupom.limit_use],
          limitePeriodoTipoMarca: [cupom.limit_use_type],
        });

        this.marcas.push(marca);
      });

      this.cupomForm.controls.primeirasCompras.setValue(res.first_purchases);
      this.cupomForm.controls.ultimaCompra.setValue(res.latest_purchases);
      this.cupomForm.controls.indicarUsuarios.setValue(res.referred_users);
      this.cupomForm.controls.distanciaEndereco.setValue(res.distance_from_address);

      this.cupomForm.controls.zipCode.setValue(res.zip_code);
      this.cupomForm.controls.numero.setValue(res.number);

      this.cupomForm.controls.entregaManual.setValue(res.manual_delivery);

      if (res.zip_code?.length === 8) {
        this.buscarCEP();
      }

      if (res.brands.length === 0) {
        this.incluirMarcas();
      }
    });
  }
}

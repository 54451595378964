<app-toolbar></app-toolbar>

<div class="container">

  <form class="col s6"
        [formGroup]="pagamentoForm"
        novalidate
        (ngSubmit)="onSubmit(pagamentoForm)">
    <div class="row">
      <div class="input-field input-center col s6">
        <input id="nomePagamento"
               type="text"
               formControlName="nomePagamento">
        <label for="nomePagamento"
               [class.active]="modoEdicao">{{'campo_nome_pagamento' | translate}}</label>
        <span class="error"
              *ngIf="pagamentoForm.controls['nomePagamento'].invalid && 
          (pagamentoForm.controls['nomePagamento'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>
    </div>

    <div class="row">
      <div class="input-field input-center col s6">
        <input id="taxas"
               type="number"
               formControlName="taxas">
        <label for="taxas"
               [class.active]="modoEdicao">{{'campo_taxa' | translate}}</label>
        <span class="error"
              *ngIf="pagamentoForm.controls['taxas'].invalid && 
          (pagamentoForm.controls['taxas'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>
    </div>

    <div class="row center">
      <div class="col s12 m12 l12 card-block">
        <h5 class="title">{{'configuracoes' | translate}}</h5>
      </div>
    </div>

    <div class="row center" style="padding-bottom: 20px;">
      <label class="check-task custom-control custom-checkbox d-flex"
             style="margin-right: 20px">
        <input type="checkbox"
               class="input-field input-center col s3"
               id="pagamentoCredito"
               [checked]="pagamentoCredito"
               (click)="atualizarPagamento('Credito')"
               formControlName="pagamentoCredito" />
        <span class="custom-control-label texto-sm-check">{{'campo_pagamento_credito' | translate}}?</span>
      </label>
      <label class="check-task custom-control custom-checkbox d-flex"
             style="margin-right: 20px">
        <input type="checkbox"
               class="input-field input-center col s3"
               id="pagamentoDebito"
               [checked]="pagamentoDebito"
               (click)="atualizarPagamento('Debito')"
               formControlName="pagamentoDebito" />
        <span class="custom-control-label texto-sm-check">{{'campo_pagamento_debito' | translate}}?</span>
      </label>
      <label class="check-task custom-control custom-checkbox d-flex"
             style="margin-right: 20px">
        <input type="checkbox"
               class="input-field input-center col s3"
               id="pagamentoPix"
               [checked]="pagamentoPix"
               (click)="atualizarPagamento('PIX')"
               formControlName="pagamentoPix" />
        <span class="custom-control-label texto-sm-check">{{'campo_pagamento_pix' | translate}}?</span>
      </label>
      <label class="check-task custom-control custom-checkbox d-flex">
        <input type="checkbox"
               class="input-field input-center col s3"
               id="pagamentoDinheiro"
               [checked]="pagamentoDinheiro"
               (click)="atualizarPagamento('Dinheiro')"
               formControlName="pagamentoDinheiro" />
        <span class="custom-control-label texto-sm-check">{{'campo_dinheiro' | translate}}?</span>
      </label>
    </div>

    <div class="row center">
      <button class="btn btnCancelar waves-effect"
              id="cancelar"
              (click)="cancelar()"
              type="button">
        {{'texto_cancelar' | translate}}
      </button>

      <button class="btn btnSalvar waves-effect"
              id="salvar"
              type="submit">
        {{'texto_salvar' | translate}}
      </button>
    </div>
  </form>
</div>
<div class="navbar-fixed">
  <nav>
    <div class="nav-wrapper">

      <ul id="nav-mobile"
          (click)="redirecionaHome()"
          class="left ">
        <li class="dropdown-button dropdown-trigger">
          <img class="imgLogo"
               src="../../../assets/images/logotoolbar.png">
        </li>
      </ul>

      <ul class="left ">
        <li>
          <div *ngFor="let breadcrumb of breadcrumbLista">
            <a *ngIf="breadcrumb.redirecionamento"
               class="breadcrumb"
               href="{{breadcrumb.rota}}">
              {{breadcrumb.descricao}}
            </a>
            <span *ngIf="!breadcrumb.redirecionamento"
                  class="breadcrumb-inativo">
              {{breadcrumb.descricao}}
            </span>
          </div>
        </li>
      </ul>

      <ul id="nav-mobile"
          class="right">
        <li class="collection-item"
            [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="{exact: true}"
            *ngIf="user.accesses.brands">
          <a href="#/admin/home">
            {{'home' | translate}}
          </a>
        </li>

        <li class="collection-item"
            [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="{exact: true}"
            *ngIf="user.accesses.brands">
          <a (click)="redirecionaMarcas()">
            {{'marcas' | translate}}
          </a>
        </li>

        <li (click)="showDropdownOperacao = !showDropdownOperacao; fechaConfiguracao(); fechaIdiomas(); fechaPlataforma();"
            class="active-dropdown-wrapper"
            *ngIf="user.accesses.operations">
          <a class="dropdown-button"> {{'operacao' | translate}}
            <i class="material-icons right">arrow_drop_down</i>
          </a>
          <ul [class.active-dropdownOperacao]="showDropdownOperacao"
              class="dropdown-content">
            <li (click)="redirecionaControlePedidos()">
              <a>
                <img class="icone"
                     src="assets/icones/Restaurante.svg">
              </a>
              <label> {{'controle_pedidos' | translate}}</label>
            </li>
            <li (click)="redirecionaControleEntregas()">
              <a>
                <img class="icone"
                     style="height: 2.4rem;"
                     src="assets/icones/entrega.svg">
              </a>
              <label> {{'controle_entrega' | translate}}</label>
            </li>
            <li (click)="redirecionaControleRecebimentos()">
              <a>
                <img class="icone"
                     src="assets/icones/cartao.svg">
              </a>
              <label> {{'controle_recebimentos' | translate}}</label>
            </li>
          </ul>
        </li>

        <li (click)="showDropdownConfiguracao = !showDropdownConfiguracao; fechaOperacao(); fechaIdiomas(); fechaPlataforma();"
            class="active-dropdown-wrapper"
            *ngIf="user.accesses.configurations">
          <a class="dropdown-button"> {{'configuracao' | translate}}
            <i class="material-icons right">arrow_drop_down</i>
          </a>
          <ul [class.active-dropdown]="showDropdownConfiguracao"
              class="dropdown-content">
            <li *ngIf="this.user.accesses.cupons"
                (click)="redirecionaCupons()">
              <a>
                <img class="icone"
                     src="assets/icones/cupons.svg">
              </a>
              <label> {{'gerenciamento_cupons' | translate}}</label>
            </li>

            <li *ngIf="this.user.accesses.categories"
                (click)="redirecionaSuperiores()">
              <a>
                <img class="icone"
                     src="assets/icones/segmentos_superiores.svg">
              </a>
              <label> {{'gerenciamento_segmentos_superior' | translate}}</label>
            </li>

            <li *ngIf="this.user.accesses.categories"
                (click)="redirecionaGerenciamentoDeCategoriasMarcas()">
              <a>
                <img class="icone"
                     src="assets/icones/food.svg">
              </a>
              <label> {{'gerenciamento_categorias' | translate}}</label>
            </li>
            <li *ngIf="user.accesses.fees"
                (click)="redirecionaTaxas()">
              <a>
                <img class="icone"
                     src="assets/icones/taxa.svg">
              </a>
              <label> {{'cadastro_taxa' | translate}}</label>
            </li>
            <li *ngIf="user.accesses.surveys"
                (click)="redirecionaEnquetes()">
              <a>
                <img class="icone"
                     src="assets/icones/enquetes.svg">
              </a>
              <label> {{'cadastro_enquete' | translate}}</label>
            </li>
          </ul>
        </li>

        <li (click)="showDropdownPlataforma = !showDropdownPlataforma; fechaOperacao(); fechaIdiomas(); fechaConfiguracao();"
            class="active-dropdown-wrapper"
            *ngIf="user.accesses.platformConfigurations">
          <a class="dropdown-button"> {{'plataforma' | translate}}
            <i class="material-icons right">arrow_drop_down</i>
          </a>
          <ul [class.active-dropdown]="showDropdownPlataforma"
              class="dropdown-content">
            <li *ngIf="this.user.accesses.platform"
                (click)="redirecionaGerenciamentoDePlataforma()">
              <a>
                <img class="icone"
                     src="assets/icones/platform.svg">
              </a>
              <label> {{'gerenciamento_plataforma' | translate}}</label>
            </li>
            <li *ngIf="this.user.accesses.cancel_reasons"
                (click)="redirecionaMotivosCancelamento()">
              <a>
                <img class="icone"
                     src="assets/icones/cancellation.svg">
              </a>
              <label> {{'motivos_cancelamento' | translate}}</label>
            </li>
            <li *ngIf="user.accesses.payment_method"
                (click)="redirecionaMeiosPagamento()">
              <a>
                <img class="icone"
                     src="assets/icones/cartao.svg">
              </a>
              <label> {{'pagamento' | translate}}</label>
            </li>
            <li *ngIf="user.accesses.users"
                (click)="redirecionaUsuariosPerfis()">
              <a>
                <img class="icone"
                     src="assets/icones/perfil.svg">
              </a>
              <label>{{'usuario_perfil' | translate}}</label>
            </li>
          </ul>
        </li>

        <li (click)="showDropdownIdiomas = !showDropdownIdiomas; fechaOperacao(); fechaConfiguracao(); fechaPlataforma();"
            class="active-dropdown-wrapper"
            data-target="dropdown"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Idiomas">
          <a>
            <img class="imgIdioma"
                 src="assets/icones/idioma.svg">
            <i class="material-icons right">arrow_drop_down</i>
          </a>
          <ul [class.active-dropdown]="showDropdownIdiomas"
              class="dropdown-content">
            <ng-container *ngFor="let language of languages">
              <li (click)="setaIdioma(language.value)">
                <a>
                  <img class="icone"
                       src="assets/icones/{{language.icon}}.svg">
                </a>
                <label>{{ language.label }}</label>
              </li>
            </ng-container>
          </ul>
        </li>
        <li class="logout">
          <a (click)="logout()">
            {{'sair' | translate}}
          </a>
        </li>
      </ul>
    </div>
  </nav>

</div>
<div class="overlay-dropdown"
     *ngIf="showDropdownConfiguracao || showDropdownPlataforma || showDropdownOperacao"
     (click)="(showDropdownConfiguracao = false) || (showDropdownPlataforma = false) || (showDropdownOperacao = false)">
</div>

<div class="right chat"
     *ngIf="user.accesses.chat">
  <a (click)="redirecionaChat()">
    <img *ngIf="chatNaoLido"
         class="chat-icon"
         src="assets/icones/chatativo.svg">
    <img *ngIf="!chatNaoLido"
         class="chat-icon"
         src="assets/icones/chat.svg">
  </a>
</div>

<div *ngIf="chatVisivel"
     class="chat-modal">
  <div class="chat-close-div">
    <a (click)="redirecionaChat()">
      <img class="chat-close-icon"
           src="assets/icones/close.svg">
    </a>
  </div>
  <app-chat></app-chat>
</div>
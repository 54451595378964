<app-toolbar></app-toolbar>

<div class="container">

  <div class="center">
    <image-helper (convertedImage)="getImage($event)"
                  [croppedImage]="imageSecureUrl"></image-helper>
  </div>

  <div class="center">
    <span class="error"
          *ngIf="!image && !imageId && isSubmitted">
      {{'texto_obrigatorio' | translate}}
    </span>
  </div>

  <form class="col s6"
        [formGroup]="unidadeForm"
        novalidate
        (ngSubmit)="onSubmit(unidadeForm)">
    <div class="row">
      <div class="input-field col s7">
        <input id="nomeUnidade"
               type="text"
               formControlName="nomeUnidade">
        <label for="nomeUnidade"
               [class.active]="modoEdicao">{{'campo_nome_unidade' | translate}}</label>
        <span class="error"
              *ngIf="unidadeForm.controls['nomeUnidade'].invalid && 
          (unidadeForm.controls['nomeUnidade'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>
      <div class="input-field col s5"
           style="display: flex;flex-direction: column;margin-top: -11px;">
        <span class="labelSelect"
              style="margin-left: 0;margin-bottom: 3px;margin-right: 10px;display: block;">
          {{'campo_loja_parada' | translate}}
          <div style="margin-top: 13px;">
            <label>
              <input type="checkbox"
                     formControlName="loja_parada" />
              <span></span>
            </label>
          </div>
        </span>
      </div>
    </div>

    <div class="row">

      <div class="input-field col s3">
        <input id="zipCode"
               type="text"
               (blur)="buscarCEP()"
               formControlName="zipCode">
        <label for="zipCode"
               [class.active]="modoEdicao">{{'campo_zip' | translate}}</label>
        <span class="error"
              *ngIf="unidadeForm.controls['zipCode'].invalid && 
          (unidadeForm.controls['zipCode'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>

      <div class="input-field col s4">
        <input id="rua"
               type="text"
               formControlName="rua">
        <label for="rua"
               [class.active]="modoEdicao">{{'campo_endereco' | translate}}</label>
        <span class="error"
              *ngIf="unidadeForm.controls['rua'].invalid && 
          (unidadeForm.controls['rua'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>

      <div class="input-field col s2">
        <input id="numero"
               type="text"
               formControlName="numero">
        <label for="numero"
               [class.active]="modoEdicao">{{'campo_endereco_numero' | translate}}</label>
        <span class="error"
              *ngIf="unidadeForm.controls['numero'].invalid && 
          (unidadeForm.controls['numero'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>

      <div class="input-field col s3">
        <input id="complemento"
               type="text"
               formControlName="complemento">
        <label for="complemento"
               [class.active]="modoEdicao">{{'campo_complemento' | translate}}</label>
      </div>
    </div>


    <div class="row">

      <div class="input-field col s3">
        <input id="bairro"
               type="text"
               formControlName="bairro">
        <label for="bairro"
               [class.active]="modoEdicao">{{'campo_bairro' | translate}}</label>
        <span class="error"
              *ngIf="unidadeForm.controls['bairro'].invalid && 
          (unidadeForm.controls['bairro'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>

      <div class="input-field col s4">
        <input id="cidade"
               type="text"
               formControlName="cidade">
        <label for="cidade"
               [class.active]="modoEdicao">{{'campo_cidade' | translate}}</label>
        <span class="error"
              *ngIf="unidadeForm.controls['cidade'].invalid && 
          (unidadeForm.controls['cidade'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>

      <div class="input-field col s2">
        <input id="estado"
               type="text"
               formControlName="estado">
        <label for="estado"
               [class.active]="modoEdicao">{{'campo_estado' | translate}}</label>
        <span class="error"
              *ngIf="unidadeForm.controls['estado'].invalid && 
          (unidadeForm.controls['estado'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>

      <div class="input-field col s3">
        <input id="areaAtendimento"
               type="number"
               formControlName="areaAtendimento">
        <label for="areaAtendimento"
               [class.active]="modoEdicao">{{'campo_area_atendimento' | translate}}</label>
        <span class="error"
              *ngIf="unidadeForm.controls['areaAtendimento'].invalid && 
          (unidadeForm.controls['areaAtendimento'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>
    </div>


    <div class="row">

      <div class="input-field col s2">
        <input id="telefone"
               type="tel"
               formControlName="telefone">
        <label for="telefone"
               [class.active]="modoEdicao">{{'campo_telefone' | translate}}</label>
        <span class="error"
              *ngIf="unidadeForm.controls['telefone'].invalid && 
          (unidadeForm.controls['telefone'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>

      <div class="input-field col s5">
        <input id="email"
               type="text"
               formControlName="email">
        <label for="email"
               [class.active]="modoEdicao">{{'campo_email' | translate}}</label>
        <span class="error"
              *ngIf="unidadeForm.controls['email'].invalid && 
          (unidadeForm.controls['email'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>

      <div class="input-field col s5"
           style="margin-top: -12px;">
        <label class="labelSelect">{{'campo_responsavel' | translate}}</label> <br />
        <select materialize
                id="responsavel"
                formControlName="responsavel"
                style="margin-top: 10px;">
          <option value=""
                  selected>{{'descricao_responsavel' | translate}}</option>
          <option *ngFor="let responsavel of responsaveis"
                  [ngValue]="responsavel._id">{{ responsavel.name }}</option>

        </select>
      </div>
    </div>

    <div *ngIf="false"
         class="row">
      <label class="labelSelect">{{'campo_moeda' | translate}}</label> <br />
      <div class="input-field col s8 m6"
           style="width: 430px;">
        <select materialize
                id="moeda"
                formControlName="moeda"
                (change)="mudaMoeda()">
          <option value=""
                  disabled
                  selected>{{'descricao_moeda' | translate}}</option>
          <option *ngFor="let moeda of moedas"
                  [ngValue]="moeda._id">{{ moeda.name }}</option>

        </select>

        <span class="error"
              *ngIf="unidadeForm.controls['moeda'].invalid && 
          (unidadeForm.controls['moeda'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>
    </div>


    <div class="row">
      <div class="input-field col s5"
           style="display: flex;flex-direction: column;margin-top: -11px;">
        <span class="labelSelect"
              style="margin-left: 0;margin-bottom: 3px;margin-right: 10px;display: block;">
          {{'campo_aberta_24_horas' | translate}}
          <div style="margin-top: 13px;">
            <label>
              <input type="checkbox"
                     formControlName="aberta_24_horas"
                     (click)="validar24Horas($event)" />
              <span></span>
            </label>
          </div>
        </span>
      </div>
    </div>

    <!-- HORARIO DE ATENDIMENTO -->
    <div *ngIf="unidadeForm.controls['aberta_24_horas'].value === false"
         class="row">
      <div class="form-group col s12 m12">
        <div formArrayName="atendimentos">
          <label class="labelSelect"
                 style="margin-left: 0rem;">{{'campo_horario' | translate}}:</label> <br />

          <div *ngFor="let atendimento of atendimentos.controls; let i = index"
               style="margin-bottom: -40px;"
               [formGroup]="atendimento">

            <div class="input-field inline"
                 style="width: 400px;">
              <select materialize="material_select"
                      class="initialized ng-untouched ng-pristine ng-valid"
                      id="week_day"
                      formControlName="week_day">
                <option value=""
                        disabled
                        selected>{{'campo_semana' | translate}}</option>
                <option value="0">{{'domingo' | translate}}</option>
                <option value="1">{{'segunda' | translate}}</option>
                <option value="2">{{'terca' | translate}}</option>
                <option value="3">{{'quarta' | translate}}</option>
                <option value="4">{{'quinta' | translate}}</option>
                <option value="5">{{'sexta' | translate}}</option>
                <option value="6">{{'sabado' | translate}}</option>
              </select>
              <span class="error"
                    *ngIf="atendimentos.controls[i].controls.week_day.invalid &&
                (atendimentos.controls[i].controls.week_day.touched || isSubmitted)">
                {{'texto_obrigatorio' | translate}}
              </span>
            </div>

            <div class="input-field inline">
              <input type="time"
                     class="timepicker"
                     style="margin-left: 1.25rem;"
                     id="start_time"
                     formControlName="start_time">
            </div>

            <div class="input-field inline">
              <input type="time"
                     class="timepicker"
                     style="margin-left: 1.25rem;"
                     id="end_time"
                     formControlName="end_time">
            </div>
            <a *ngIf="i === atendimentos.length - 1"
               class="btn-floating"
               (click)="incluirAtendimento()"
               data-toggle="tooltip"
               data-placement="bottom"
               title="{{'texto_adicionar' | translate}}">
              <i class="material-icons">add</i>
            </a>
            <a *ngIf="i > 0"
               class="btn-floating btn-remove"
               (click)="removerAtendimento(i)"
               data-toggle="tooltip"
               data-placement="bottom"
               title="{{'texto_excluir' | translate}}">
              <i class="material-icons">cancel</i>
            </a>
            <br />
            <span class="error"
                  style="margin-left: 426px;margin-top: -2rem;display: flex;"
                  *ngIf="(atendimentos.controls[i].controls.start_time.invalid) &&
              ((atendimentos.controls[i].controls.start_time.touched) || isSubmitted)">
              {{'texto_horario' | translate}}
            </span>
            <span class="error"
                  style="margin-left: 426px;margin-top: -2rem;display: flex;"
                  *ngIf="atendimentos.controls[i].controls.end_time.touched &&
              (atendimentos.controls[i].value.end_time < atendimentos.controls[i].value.start_time)">
              {{'texto_horario_fim' | translate}}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="row"
         style="margin-top: 3rem;">
      <div class="input-field col s5"
           style="display: flex;flex-direction: column;margin-top: -11px;">
        <span class="labelSelect"
              style="margin-left: 0;margin-bottom: 3px;margin-right: 10px;display: block;">
          {{'campo_permite_retirar' | translate}}
          <div style="margin-top: 13px;">
            <label>
              <input type="checkbox"
                     formControlName="permite_retirar" />
              <span></span>
            </label>
          </div>
        </span>
      </div>

      <div class="input-field col s5"
           style="display: flex;flex-direction: column;margin-top: -11px;">
        <span class="labelSelect"
              style="margin-left: 0;margin-bottom: 3px;margin-right: 10px;display: block;">
          {{'campo_frete_correio' | translate}}
          <div style="margin-top: 13px;">
            <label>
              <input type="checkbox"
                     formControlName="frete_correio" />
              <span></span>
            </label>
          </div>
        </span>
      </div>
    </div>

    <div class="row">
      <div class="input-field col s5"
           style="display: flex;flex-direction: column;margin-top: -11px;">
        <span class="labelSelect"
              style="margin-left: 0;margin-bottom: 3px;margin-right: 10px;display: block;">
          {{'campo_taxas_gratis' | translate}}
          <div style="margin-top: 13px;">
            <label>
              <input type="checkbox"
                     formControlName="entrega_gratis"
                     (click)="validarEntregaGratis($event)" />
              <span></span>
            </label>
          </div>
        </span>
      </div>
    </div>

    <!-- TAXA DE ENTREGA -->
    <div *ngIf="unidadeForm.controls['entrega_gratis'].value === false"
         class="row">
      <div class="form-group col s12 m12">
        <div formArrayName="taxas">
          <label class="labelSelect"
                 style="margin-left: 0rem;">{{'campo_taxas' | translate}}:</label> <br />
          <div *ngFor="let taxa of taxas.controls; let i=index"
               [formGroup]="taxa">

            <div class="input-field inline"
                 style="
              width: 8rem;">
              <input id="km"
                     type="number"
                     formControlName="km">
            </div>
            <span style="font-size: 20px;">
              KM
            </span>

            <span style="margin-left: 1rem;">
              {{'texto_comparativo' | translate}}
            </span>

            <span style="
              font-size: 20px;
              margin-left: 1rem;">
              {{moedaSelecionada}}
            </span>

            <div class="input-field inline"
                 style="width: 8rem;">
              <input id="price"
                     type="number"
                     formControlName="price">
            </div>

            <span style="font-size: 20px;">
              {{'texto_tempo_entrega' | translate}}:
            </span>

            <div class="input-field inline"
                 style="width: 8rem;">
              <input id="delivery_time"
                     type="number"
                     formControlName="delivery_time">
            </div>

            <div class="input-field inline"
                 style="margin-top: 0px;">
              <select materialize="material_select"
                      class="initialized ng-untouched ng-pristine ng-valid"
                      id="time_type"
                      formControlName="time_type">
                <option value=""
                        disabled
                        selected>{{'campo_tipo_entrega' | translate}}</option>
                <option value="minute">{{'minutos' | translate}}</option>
                <option value="hour">{{'horas' | translate}}</option>
                <option value="day">{{'dias' | translate}}</option>
              </select>
            </div>

            <a *ngIf="i === taxas.length - 1"
               class="btn-floating"
               (click)="incluirTaxa()"
               data-toggle="tooltip"
               data-placement="bottom"
               title="{{'texto_adicionar' | translate}}">
              <i class="material-icons">add</i>
            </a>
            <a *ngIf="i > 0"
               class="btn-floating btn-remove"
               (click)="removerTaxa(i)"
               data-toggle="tooltip"
               data-placement="bottom"
               title="{{'texto_excluir' | translate}}">
              <i class="material-icons">cancel</i>
            </a>
            <br />
            <span class="error"
                  style="
              margin-left: 5px;
              margin-top: -2rem;
              display: flex;
              "
                  *ngIf="taxas.controls[i].controls.km.invalid &&
              (taxas.controls[i].controls.km.touched || isSubmitted)">
              {{'texto_obrigatorio' | translate}}
            </span>

            <span class="error"
                  style="
              margin-left: 238px;
              margin-top: -1.5rem;
              display: flex;
              "
                  *ngIf="(taxas.controls[i].controls.price.invalid &&
              (taxas.controls[i].controls.price.touched || isSubmitted))">
              {{'texto_obrigatorio' | translate}}
            </span>

            <span class="error"
                  style="
              margin-left: 540px;
              margin-top: -1.5rem;
              display: flex;
              "
                  *ngIf="(taxas.controls[i].controls.delivery_time.invalid &&
              (taxas.controls[i].controls.delivery_time.touched || isSubmitted))">
              {{'texto_obrigatorio' | translate}}
            </span>

            <span class="error"
                  style="
              margin-left: 675px;
              margin-top: -1.0rem;
              display: flex;
              "
                  *ngIf="(taxas.controls[i].controls.time_type.invalid &&
              (taxas.controls[i].controls.time_type.touched || isSubmitted))">
              {{'texto_obrigatorio' | translate}}
            </span>

          </div>
        </div>
      </div>
    </div>

    <!-- MEIOS DE PAGAMENTO -->
    <div class="row">
      <div class="form-group col s12 m12">
        <div>
          <label class="labelSelect"
                 style="margin-left: 0rem;">{{'campo_pagamentos' | translate}}:</label><br />

          <div *ngFor="let meios of meiosPagamento; let i = index"
               style="margin-top: 5px;">

            <label class="check-task custom-control custom-checkbox d-flex checkboxPag"
                   style="margin-right: 47px;">
              <input type="checkbox"
                     class="input-field input-center col s3"
                     [value]="meios._id"
                     [checked]="meios.check"
                     (change)="onCheckboxChange($event)">
              <span class="custom-control-label texto-sm-check">{{meios.name}}</span>
            </label>
          </div>

          <span class="error"
                *ngIf="this.formCheks.controls['checkArray'].errors?.required && isSubmitted">
            {{'texto_obrigatorio' | translate}}
          </span>
        </div>
      </div>
    </div>

    <!-- Buttons -->
    <div class="row center">
      <button class="btn btnCancelar"
              id="cancelar"
              (click)="cancelar(idMarca)"
              type="button">
        {{'texto_cancelar' | translate}}
      </button>

      <button class="btn btnSalvar"
              id="salvar"
              type="submit"
              [disabled]="uploadImage">
        {{'texto_salvar' | translate}}
      </button>
    </div>
  </form>
</div>
<app-toolbar></app-toolbar>

<div class="container">
  <div class="col s12 m12 l12">
    <div class="row">

      <table>
        <thead>
          <tr>
            <th style="width: 30%;">{{'campo_produto' | translate}}</th>
            <th style="width: 15%;">{{'texto_tipo' | translate}}</th>
            <th style="text-align: center;">{{'quantidade_atual' | translate}}</th>
            <th style="text-align: center;">{{'quantidade_nova' | translate}}</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let item of estoque">
            <td style="width:30%;">{{item.name}}</td>
            <td style="width:15%;">{{item.category.type | translate}}</td>
            <td style="text-align: center;">{{item.actual_quantity}}</td>
            <td *ngIf="item.category.type === 'Storable'"
                style="text-align: center;">
              <input style="text-align: center;"
                     id="new_quantity"
                     type="number"
                     [(ngModel)]=item.new_quantity>
            </td>
            <td *ngIf="item.category.type === 'Digital'"
                style="text-align: center;">
              <button class="btn"
                      id="estoqueDigital"
                      type="button"
                      (click)="estoqueDigital(item)">
                {{'estoque_digital' | translate}}
              </button>
            </td>
            <td *ngIf="item.category.type === 'Ticket'"
                style="text-align: center;">
              <button class="btn"
                      id="estoqueIngressos"
                      type="button"
                      (click)="estoqueIngressos(item)">
                {{'estoque_ingressos' | translate}}
              </button>
            </td>
          </tr>
        </tbody>
      </table>

    </div>

  </div>
  <div class="row center">
    <button class="btn btnVoltar"
            id="voltar"
            (click)="voltar(idMarca)"
            type="button">
      {{'texto_voltar' | translate}}
    </button>
    <button class="btn btnSalvar"
            id="salvar"
            type="button"
            (click)="salvar()">
      {{'texto_salvar' | translate}}
    </button>

  </div>
</div>
import { TokenService } from '../../services/token.service';
import { AuthService } from '../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationService } from '../../services/translation.service';
import { TranslatePipe } from '../../pipes/translate.pipe';
import Swal from 'sweetalert2';

@Component({
  selector: 'alterar-senha',
  templateUrl: './alterar-senha.component.html',
  styleUrls: ['./alterar-senha.component.css']
})
export class AlterarSenhaComponent implements OnInit {
  isSubmitted = false;
  alterarSenhaForm: FormGroup;
  showSpinner = false;
  user: any;
  token: any;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private tokenService: TokenService,
    private translationService: TranslationService,
    private translatePipe: TranslatePipe,
    private route: ActivatedRoute,

  ) { }

  ngOnInit() {

    this.route.paramMap.subscribe(params => {
      this.token = params.get('token');
    });

    this.init();
  }

  init() {
    this.alterarSenhaForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      passwordconfirm: ['', [Validators.required, Validators.minLength(8)]]
    });

  }

  gravarSenha() {
    this.isSubmitted = true;

    if (this.alterarSenhaForm.valid) {

      this.showSpinner = true;

      let alterarSenha = {
        token: this.token,
        password: this.alterarSenhaForm.value.password
      };

      this.authService.changePasswordToken(alterarSenha).subscribe(
        data => {
          Swal.fire('Login', this.translatePipe.transform('senha_alterada'), 'success').then(() => {
            this.tokenService.DeleteToken();
            this.router.navigate(['/']);
          });
        },
        err => {
          this.showSpinner = false;
          if (err.error.message) {
            Swal.fire('Login', this.translatePipe.transform(err.error.message), 'error');
          }
        }
      );
    } else {
      this.alterarSenhaForm.markAsTouched();
    }
  }
}

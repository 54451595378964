import { AuthTabsComponent } from './../components/auth-tabs/auth-tabs.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from '../components/login/login.component';
import { EsqueceuSenhaComponent } from '../components/esqueceu-senha/esqueceu-senha.component';
import { AlterarSenhaComponent } from '../components/alterar-senha/alterar-senha.component';
import { AuthService } from '../services/auth.service';
import { ToolbarsComponent } from '../components/toolbars/toolbars.component';
import { AuthRoutingModule } from './auth-routing.module';
import { StreamsModule } from './streams.module';
import { Translate2Pipe } from '../pipes/translate2.pipe';
import { TranslationService } from '../services/translation.service';


@NgModule({
  imports: [CommonModule, HttpClientModule, FormsModule, ReactiveFormsModule, StreamsModule, AuthRoutingModule],
  declarations: [AuthTabsComponent, LoginComponent, EsqueceuSenhaComponent, AlterarSenhaComponent, ToolbarsComponent,
    Translate2Pipe
  ],
  exports: [AuthTabsComponent, LoginComponent, EsqueceuSenhaComponent, AlterarSenhaComponent, ToolbarsComponent],
  providers: [
    AuthService,
    Translate2Pipe,
    TranslationService
  ]
})
export class AuthModule { }

import { HttpClientModule } from '@angular/common/http';
import { TokenService } from './../../../services/token.service';
import { PagamentoService } from './../../../services/pagamentos.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { TranslatePipe } from '../../../pipes/translate.pipe';

@Component({
  selector: 'app-pagamento',
  templateUrl: './pagamento.component.html',
  styleUrls: ['./pagamento.component.css']
})
export class PagamentoComponent implements OnInit {
  isSubmitted = false;
  pagamentoForm: FormGroup;
  idPagamento: string;
  url = null;
  user = this.tokenService.GetPayLoad();
  modoEdicao = false;

  pagamentoDinheiro = false;
  pagamentoCredito = false;
  pagamentoDebito = false;
  pagamentoPix = false;


  constructor(
    private translatePipe: TranslatePipe,
    private fb: FormBuilder,
    private pagamentoService: PagamentoService,
    private tokenService: TokenService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.pagamentoForm = this.fb.group({
      nomePagamento: ['', Validators.required],
      taxas: ['', Validators.required],
      pagamentoCredito: [],
      pagamentoDebito: [],
      pagamentoPix: [],
      pagamentoDinheiro: []
    });

    this.route.paramMap.subscribe(params => {
      this.idPagamento = params.get('id');

      if (this.idPagamento) {
        this.modoEdicao = true;
        this.carregaCampos();
      }
    });
  }

  onSubmit(form: FormGroup) {
    this.isSubmitted = true;
    if (form.valid) {
      if (this.idPagamento) {
        this.salvar({
          name: this.pagamentoForm.value.nomePagamento,
          tax: this.pagamentoForm.value.taxas,
          allows_change: this.pagamentoForm.value.pagamentoDinheiro === true ? true : false,
          online_credit: this.pagamentoForm.value.pagamentoCredito === true ? true : false,
          online_debit: this.pagamentoForm.value.pagamentoDebito === true ? true : false,
          online_pix: this.pagamentoForm.value.pagamentoPix === true ? true : false,

        });
      } else {
        this.salvar({
          name: this.pagamentoForm.value.nomePagamento,
          tax: this.pagamentoForm.value.taxas,
          allows_change: this.pagamentoForm.value.pagamentoDinheiro === true ? true : false,
          online_credit: this.pagamentoForm.value.pagamentoCredito === true ? true : false,
          online_debit: this.pagamentoForm.value.pagamentoDebito === true ? true : false,
          online_pix: this.pagamentoForm.value.pagamentoPix === true ? true : false,
        });
      }
    }
  }

  salvar(pagamentoForm) {
    if (this.idPagamento) {
      this.pagamentoService.editarPagamento(this.idPagamento, pagamentoForm).subscribe(res => {
        Swal.fire(this.translatePipe.transform('pagamento_titulo'), this.translatePipe.transform('pagamento_editado'), 'success').then(() => {
          this.router.navigate(['admin/pagamentos']);
        });
      });
    } else {
      this.pagamentoService.salvaPagamento(pagamentoForm).subscribe(res => {
        Swal.fire(this.translatePipe.transform('pagamento_titulo'), this.translatePipe.transform('pagamento_cadastrado'), 'success').then(() => {
          this.router.navigate(['admin/pagamentos']);
        });
      });
    }
  }

  atualizarPagamento(campo) {
    if (campo === 'PIX') {
      this.pagamentoForm.controls.pagamentoPix.setValue(!this.pagamentoForm.value.pagamentoPix);
      this.pagamentoForm.controls.pagamentoCredito.setValue(false);
      this.pagamentoForm.controls.pagamentoDebito.setValue(false);
      this.pagamentoForm.controls.pagamentoDinheiro.setValue(false);
    }
    else if (campo === 'Credito') {
      this.pagamentoForm.controls.pagamentoCredito.setValue(!this.pagamentoForm.value.pagamentoCredito);
      this.pagamentoForm.controls.pagamentoDebito.setValue(false);
      this.pagamentoForm.controls.pagamentoPix.setValue(false);
      this.pagamentoForm.controls.pagamentoDinheiro.setValue(false);
    }
    else if (campo === 'Debito') {
      this.pagamentoForm.controls.pagamentoDebito.setValue(!this.pagamentoForm.value.pagamentoDebito);
      this.pagamentoForm.controls.pagamentoCredito.setValue(false);
      this.pagamentoForm.controls.pagamentoPix.setValue(false);
      this.pagamentoForm.controls.pagamentoDinheiro.setValue(false);
    }
    else if (campo === 'Dinheiro') {
      this.pagamentoForm.controls.pagamentoDinheiro.setValue(!this.pagamentoForm.value.pagamentoDinheiro);
      this.pagamentoForm.controls.pagamentoCredito.setValue(false);
      this.pagamentoForm.controls.pagamentoPix.setValue(false);
      this.pagamentoForm.controls.pagamentoDebito.setValue(false);
    }
  }


  cancelar() {
    this.router.navigate(['admin/pagamentos']);
  }

  // Editar
  carregaCampos() {
    this.pagamentoService.obterDadosPagamento(this.idPagamento).subscribe(res => {
      this.isSubmitted = true;
      this.pagamentoForm.controls.nomePagamento.setValue(res.name);
      this.pagamentoForm.controls.taxas.setValue(res.tax);
      this.pagamentoForm.controls.pagamentoDinheiro.setValue(res.allows_change);
      this.pagamentoForm.controls.pagamentoCredito.setValue(res.online_credit);
      this.pagamentoForm.controls.pagamentoDebito.setValue(res.online_debit);
      this.pagamentoForm.controls.pagamentoPix.setValue(res.online_pix);

      this.pagamentoDinheiro = res.allows_change;
      this.pagamentoCredito = res.online_credit;
      this.pagamentoDebito = res.online_debit;
      this.pagamentoPix = res.online_pix;
    });
  }
}

<body>
  <div class="Principal">
    <div class="container containerModal">
      <div class="row">
        <div class="col s10 offset-s1"
             id="painel">
          <img src="../../../../../assets/images/logopadrao.png"
               style="width: 150px;"
               alt="">
          <form class="col s12"
                [formGroup]="loginForm"
                novalidate
                (ngSubmit)="loginUser()">
            <div class="row"
                 style="margin-top: -10px; margin-bottom: 30px !important;">
              <div class="input-field col s12">
                <input id="user-name"
                       type="text"
                       formControlName="username">
                <label for="user-name">{{ 'usuarioLogin' | translate2}}</label>
                <span class="error"
                      *ngIf="(loginForm.controls['username'].invalid && 
            (loginForm.controls['username'].touched || isSubmitted) &&
            !loginForm.controls['username'].value)">
                  {{ 'texto_obrigatorio' | translate2}}
                </span>
                <span class="error"
                      *ngIf="loginForm.controls['username'].value && loginForm.controls['username'].value.length < 5">
                  {{ 'usuario_caracteres_min' | translate2}}
                </span>
              </div>
            </div>

            <div class="row">
              <div class="input-field col s12">
                <input id="password"
                       type="password"
                       formControlName="password">
                <label for="password">{{ 'senhaLogin' | translate2}}</label>
                <span class="error"
                      *ngIf="(loginForm.controls['password'].invalid && 
            (loginForm.controls['password'].touched || isSubmitted) &&
            !loginForm.controls['password'].value)">
                  {{ 'texto_obrigatorio' | translate2}}
                </span>
                <span class="error"
                      *ngIf="loginForm.controls['password'].value && loginForm.controls['password'].value.length < 8">
                  {{ 'senha_caracteres_min' | translate2}}
                </span>
              </div>
            </div>
            <div id="errorMsg"
                 *ngIf="errorMessage">
              <span>{{'login_mensagem' | translate2}}</span>
            </div>
            <div class="center">
              <button class="btn waves-effect"
                      id="loginbtn"
                      (click)="loginUser()">
                {{'entrar' | translate2 }}
              </button>

            </div>
            <form>
              <p class="waves-effect"
                 id="esqueci"
                 (click)="direcionarEsqueceuSenha()">{{'recuperar_senha' | translate2 }}</p><br>
            </form>
          </form>
        </div>
      </div>
    </div>
  </div>
</body>
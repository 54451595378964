import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-politica',
  templateUrl: './politica.component.html',
  styleUrls: ['./politica.component.css']
})
export class PoliticaComponent implements OnInit {


  pdfSrc: any;

  constructor() {
    this.pdfSrc = "../../assets/resources/termo.pdf";
  }

  ngOnInit() {

  }

}

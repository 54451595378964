<app-toolbar></app-toolbar>

<div class="row"
     style="text-align: right;">
  <a routerLink="adicionar">
    <img class="imgAdicionar"
         src="../../../assets/icones/adicionar.svg">
  </a>
</div>

<div *ngFor="let categoria of categorias">
  <h6 class="title-category">{{ categoria.name }} {{ categoria.price ? '  -  Valor da Categoria: R$ ' : '' }} {{ categoria.price | number: '0.2-2' }}</h6>
  <div class="row"
       style="justify-content: center;align-items: center;display: flex;">
    <div class="col s12 m12 l12 card-block">
      <div class="row">
        <div class="col cardDiv"
             *ngFor="let produto of produtosFiltros(categoria)">
          <div class="card">
            <div class="linha">
              <div class="imgDiv">
                <img class="responsive-img"
                     [src]="produto.image.url || 'assets/images/nophoto.png'">
              </div>
              <div>
                <div class="div-dados">
                  <span class="card-title">
                    {{produto.name}}
                  </span>
                  <br>
                  <span class="titleItalic">
                    {{produto.moeda}} {{produto.valor | number: '0.2-2'}}
                  </span>
                </div>
                <div class="divBotoes">
                  <a class="botoes"
                     (click)="editar(produto._id)">
                    <img class="imgIcones"
                         src="../../../assets/icones/editar.svg"
                         [title]="translatePipe.transform('texto_editar')">
                  </a>
                  <a class="botoes"
                     (click)="redirecionaExtras(produto._id)">
                    <img class="imgIcones"
                         src="../../../assets/icones/extras.svg"
                         [title]="translatePipe.transform('texto_extras')">
                  </a>
                  <a class="botoes"
                     (click)="redirecionaCombo(produto._id)">
                    <img class="imgIcones"
                         src="../../../assets/icones/opcoes.svg"
                         [title]="translatePipe.transform('texto_combo')">
                  </a>
                  <a class="botoes"
                     (click)="excluirItem(produto._id)">
                    <img class="imgIcones"
                         src="../../../assets/icones/excluir.svg"
                         [title]="translatePipe.transform('texto_excluir')">
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
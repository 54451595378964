<app-toolbar></app-toolbar>

<div class="container">

  <div class="center">
    <image-helper (convertedImage)="getImage($event)"
                  [croppedImage]="imageSecureUrl"></image-helper>
  </div>

  <div class="center">
    <span class="error"
          *ngIf="!image && !imageId && isSubmitted">
      {{'texto_obrigatorio' | translate}}
    </span>
  </div>

  <form class="col s12"
        [formGroup]="segmentoForm"
        novalidate
        (ngSubmit)="onSubmit(segmentoForm)">

    <div class="row">

      <div class="input-field col s6">
        <label class="labelSelect">{{'gerenciamento_segmentos_superior' | translate}}</label> <br />
        <select materialize
                id="segmentoSuperior"
                formControlName="segmentoSuperior"
                style="margin-top: 10px;">
          <option value=""
                  selected>{{'texto_selecione' | translate}}</option>
          <option *ngFor="let segmento of segmentosSuperiores"
                  [ngValue]="segmento._id">{{ segmento.name }}</option>

        </select>
      </div>

      <div class="input-field col s6" style="margin-top: 37px;">
        <input id="nomeItem"
               type="text"
               formControlName="nomeItem">
        <label for="nomeItem"
               [class.active]="modoEdicao">{{'campo_descricao' | translate}}</label>
        <span class="error"
              *ngIf="segmentoForm.controls['nomeItem'].invalid && 
          (segmentoForm.controls['nomeItem'].touched ||isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>
    </div>

    <div class="row center">
      <button class="btn btnCancelar waves-effect"
              id="cancelar"
              (click)="cancelar()"
              type="button">
        {{'texto_cancelar' | translate}}
      </button>
      <button class="btn btnSalvar waves-effect"
              id="salvar"
              type="button"
              (click)="onSubmit(segmentoForm)"
              [disabled]="uploadImage">
        {{'texto_salvar' | translate}}
      </button>
    </div>
  </form>
</div>
import { CuponsService } from '../../services/cupons.service';
import { TokenService } from '../../services/token.service';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslatePipe } from '../../pipes/translate.pipe';

@Component({
  selector: 'app-cupons',
  templateUrl: './cupons.component.html',
  styleUrls: ['./cupons.component.css']
})
export class CuponsComponent implements OnInit {
  cupons = [];
  situacoes = [];
  url = null;
  user = this.tokenService.GetPayLoad();
  idMarca = null;

  constructor(private translatePipe: TranslatePipe,
    private tokenService: TokenService,
    private cuponsService: CuponsService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.idMarca = params.get('idMarca');

      this.listaCupons();
    });
  }

  listaCupons() {

    if (this.idMarca) {
      this.cuponsService.listaCuponsPorMarca(this.idMarca).subscribe(res => {
        res.map((cupom) => {
          if (this.situacoes.filter((situacao) => { return situacao === cupom.status }).length === 0) {
            this.situacoes.push(cupom.status);
          }
        });

        this.cupons = res;
      });
    }
    else {
      this.cuponsService.listaCupons().subscribe(res => {
        res.map((cupom) => {
          if (this.situacoes.filter((situacao) => { return situacao === cupom.status }).length === 0) {
            this.situacoes.push(cupom.status);
          }
        });

        this.cupons = res;
      });
    }




  }

  cuponsFiltros(situacao) {
    let cuponsFiltrados = this.cupons.filter((cupom) => { return cupom.status === situacao });

    cuponsFiltrados.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase())
        return -1;
      if (a.name.toLowerCase() > b.name.toLowerCase())
        return 1;
      return 0;
    });

    return cuponsFiltrados;
  }

  redirecionarAdicionar() {
    if (this.idMarca) {
      this.router.navigate([`admin/cupons/marca/${this.idMarca}/adicionar`]);
    }
    else {
      this.router.navigate([`admin/cupons/adicionar`]);
    }
  }

  excluir(id: string) {
    Swal.fire({
      title: this.translatePipe.transform('deletar_titulos'),
      text: this.translatePipe.transform('gerenciamento_cupons_excluir'),
      showCancelButton: true,
      confirmButtonText: this.translatePipe.transform('deletar_sim'),
      cancelButtonText: this.translatePipe.transform('deletar_nao'),
    }).then(result => {
      if (result.value) {
        this.cuponsService.excluirItemCupom(id).subscribe((res: any) => {
          this.listaCupons();
        },
          error => {
            Swal.fire(this.translatePipe.transform('gerenciamento_cupons'), error.error.message, 'error');
          }
        );
      }
    });
  }

  editar(id: string) {
    if (this.idMarca) {
      this.router.navigate([`admin/cupons/marca/${this.idMarca}/editar/${id}`]);
    }
    else {
      this.router.navigate([`admin/cupons/editar/${id}`]);
    }
  }
}

import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {
  BASEURL: string;
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    this.BASEURL = environment.apiUrl;

    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tokenMappe'),
    });
  }

  listaUsuarios(): Observable<any> {
    return this.http.get(`${this.BASEURL}/users/employees`, {
      headers: this.httpHeaders,
    });
  }

  salvarUsuario(body: any): Observable<any> {
    return this.http.post(`${this.BASEURL}/users/employees`, body, {
      headers: this.httpHeaders,
    });
  }

  excluirUsuario(id: string): Observable<any> {
    return this.http.delete(`${this.BASEURL}/users/${id}`, {
      headers: this.httpHeaders,
    });
  }

  obterDadosUsuario(id: string): Observable<any> {
    return this.http.get(`${this.BASEURL}/users/${id}`, {
      headers: this.httpHeaders,
    });
  }

  editarUsuario(id: string, body: any): Observable<any> {
    return this.http.put(`${this.BASEURL}/users/employees/${id}`, body, {
      headers: this.httpHeaders,
    });
  }

  uploadImages(image: string): Observable<any> {
    return this.http.post(`${this.BASEURL}/images`, {
      image
    }, {
      headers: this.httpHeaders,
    });
  }

  novaSenhaUsuario(body: any): Observable<any> {
    return this.http.post(`${this.BASEURL}/users/recover-password`, body, {
      headers: this.httpHeaders,
    });
  }

  listaUnidades(): Observable<any> {
    return this.http.get(`${this.BASEURL}/restaurants`, {
      headers: this.httpHeaders,
    });
  } 
  
  listaMarcas(): Observable<any> {
    return this.http.get(`${this.BASEURL}/brands`, {
      headers: this.httpHeaders,
    });
  }

}

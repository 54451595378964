<app-toolbar></app-toolbar>

<div style="justify-content: center;align-items: center;display: flex;width: 100%;padding-left: 18px;">
  <div class="col s12 m12 l12 card-block">
    <h5 class="title">{{'cadastro_taxas' | translate}}</h5>
  </div>
</div>

<div class="container">
  <div class="card-panel">
    <form class="col s6"
          [formGroup]="taxaForm"
          novalidate
          (ngSubmit)="onSubmit(taxaForm)">
      <div class="container">
        <div class="row">
          <div class="input-field col s6"
               style="margin-top: -10px;">
            <label class="labelSelect"
                   [class.active]="modoEdicao"
                   for="nome">{{'campo_taxa_nome' | translate}}</label>
            <input id="nome"
                   type="text"
                   formControlName="nome">
            <span class="error"
                  *ngIf="taxaForm.controls['nome'].invalid && 
            (taxaForm.controls['nome'].touched || isSubmitted)">
              {{'texto_obrigatorio' | translate}}
            </span>
          </div>
          <div class="input-field col s3"
               style="margin-top: -10px;">
            <input id="inicio"
                   type="date"
                   formControlName="inicio">
            <span class="error"
                  *ngIf="taxaForm.controls['inicio'].invalid && 
            (taxaForm.controls['inicio'].touched || isSubmitted)">
              {{'texto_obrigatorio' | translate}}
            </span>
          </div>
          <div class="input-field col s3"
               style="margin-top: -10px;">
            <input id="fim"
                   type="date"
                   formControlName="fim">
            <span class="error"
                  *ngIf="taxaForm.controls['fim'].invalid && 
            (taxaForm.controls['fim'].touched || isSubmitted)">
              {{'texto_obrigatorio' | translate}}
            </span>
          </div>
        </div>

        <div class="row">
          <div class="form-group right">
            <div formArrayName="range"
                 class="options">

              <div *ngFor="let option of options.controls; let i=index"
                   [formGroup]="option">

                <div class="input-field inline">
                  <div class="row">
                    <div class="input-field col s3"
                         style="margin-top: -10px;">
                      <label class="labelSelect"
                             [class.active]="modoEdicao"
                             for="preco">{{'campo_taxa_valor' | translate}}</label>
                      <input id="preco"
                             formControlName="preco"
                             type="number"
                             style="width: 94%;">
                    </div>

                    <div class="input-field inline col s3"
                         style="margin-top: -8px;">
                      <select materialize="material_select"
                              class="initialized ng-untouched ng-pristine ng-valid"
                              formControlName="tipo"
                              id="tipo">
                        <option value=""
                                disabled
                                selected>{{'campo_taxa_tipo' | translate}}</option>
                        <option value="percentage">{{'percentage' | translate}}</option>
                        <option value="value">{{'value' | translate}}</option>
                      </select>
                    </div>

                    <div class="input-field col s3"
                         style="margin-top: -10px;">
                      <label class="labelSelect"
                             [class.active]="modoEdicao"
                             for="taxacliente">{{'campo_taxa_cliente' | translate}}</label>
                      <input id="taxacliente"
                             type="number"
                             formControlName="taxacliente"
                             style="width: 94%;">
                    </div>
                    <div class="input-field col s3"
                         style="margin-top: -10px;">
                      <label class="labelSelect"
                             [class.active]="modoEdicao"
                             for="taxaloja">{{'campo_taxa_marca' | translate}}</label>
                      <input id="taxaloja"
                             type="number"
                             formControlName="taxaloja"
                             style="width: 94%;">
                    </div>
                  </div>
                </div>

                <div style="text-align: right;">
                  <a *ngIf="i === options.length - 1"
                     class="btn-floating"
                     (click)="incluirOptions()"
                     data-toggle="tooltip"
                     data-placement="bottom"
                     title="{{'texto_adicionar' | translate}}"
                     style="margin-left: 10px !important;">
                    <i class="material-icons">add</i>
                  </a>

                  <a *ngIf="i > 0"
                     class="btn-floating btn-remove"
                     (click)="removeroptions(i)"
                     data-toggle="tooltip"
                     data-placement="bottom"
                     title="{{'texto_excluir' | translate}}">
                    <i class="material-icons">cancel</i>
                  </a>
                </div>

                <br />

                <span class="error"
                      style="margin-left: 5px; margin-top: -2rem; display: flex; "
                      *ngIf="options.controls[i].controls.preco.invalid && (options.controls[i].controls.preco.touched || isSubmitted)">
                  {{'texto_obrigatorio' | translate}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="row">
    <button *ngIf="!modoEdicao"
            class="btn btnSalvar waves-effect right"
            id="salvar"
            type="button"
            (click)="onSubmit(taxaForm)"
            [disabled]="uploadImage">
      {{'texto_salvar' | translate}}
    </button>

    <button *ngIf="modoEdicao"
            class="btn btnSalvar waves-effect right"
            id="salvar"
            type="button"
            (click)="onSubmitEdit(taxaForm)">
      {{'texto_salvar' | translate}}
    </button>

    <button *ngIf="modoEdicao"
            class="btn btnCancelar right"
            id="cancelar"
            (click)="cancelar()"
            type="button">
      {{'texto_cancelar' | translate}}
    </button>

  </div>

  <table style="margin-top: 5rem;">
    <thead>
      <tr>
        <th style="width: 30%;">{{'campo_taxa_grid' | translate}}</th>
        <th style="width: 20%;">{{'campo_inicio' | translate}}</th>
        <th style="width: 20%;">{{'campo_fim' | translate}}</th>
        <th style="text-align: center;">{{'texto_acao' | translate}}</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let item of taxas">
        <td style="width:30%;">{{item.name}}</td>
        <td style="width:20%;">{{item.start}}</td>
        <td style="width:20%;">{{item.end}}</td>
        <td style="width:10%; text-align: center;">
          <a class="btn-floating btn-edit"
             (click)="editarItemCombo(item._id)"
             data-toggle="tooltip"
             data-placement="bottom"
             title="{{'texto_editar' | translate}}">
            <i class="material-icons"
               style="color: orange;">create</i>
          </a>

          <a class="btn-floating btn-remove"
             (click)="deletarItemCombo(item._id)"
             data-toggle="tooltip"
             data-placement="bottom"
             title="{{'texto_excluir' | translate}}">
            <i class="material-icons">cancel</i>
          </a>

        </td>
      </tr>

    </tbody>
  </table>

  <div class="row center"
       style="margin-top: 3rem;">
    <button class="btn btnVoltar"
            id="voltar"
            (click)="voltar()"
            type="button">
      {{'texto_voltar' | translate}}
    </button>
  </div>
</div>
<div *ngIf="!readOnly"
     class="btn-small btnUpload file-field input-field">
       <span>
              Enviar
       </span>
       <input type="file"
              (change)="fileChangeEvent($event)"
              accept="image/png, image/jpeg">
</div>
<div *ngIf="!readOnly">
       <br />
       <button (click)="resetImage()"><img class="imgIcone"
                   src="assets/icones/refresh.svg"
                   title="Reiniciar Imagem"></button>
       <button (click)="rotateLeft()"> <img class="imgIcone"
                   src="assets/icones/rotateleft.svg"
                   title="Girar para Esquerda"></button>
       <button (click)="rotateRight()"><img class="imgIcone"
                   src="assets/icones/rotateright.svg"
                   title="Girar para Direita"></button>
       <button (click)="flipHorizontal()"><img class="imgIcone"
                   src="assets/icones/fliphorizontal.svg"
                   title="Girar Horizontal"></button>
       <button (click)="flipVertical()"><img class="imgIcone"
                   src="assets/icones/flipvertical.svg"
                   title="Girar Vertical"></button>
       <button (click)="toggleContainWithinAspectRatio()">
              <img *ngIf='!containWithinAspectRatio'
                   class="imgIcone"
                   src="assets/icones/fullscreen.svg"
                   title="Imagem Inteira">
              <img *ngIf='containWithinAspectRatio'
                   class="imgIcone"
                   src="assets/icones/minimizescreen.svg"
                   title="Parte da Imagem"></button>
       <!-- <button (click)="toggleAspectRatio()">Aspect ratio: {{aspectRatio === 4/3 ? '4/3' : '16/9'}}</button> -->
       <button (click)="zoomOut()"><img class="imgIcone"
                   src="assets/icones/zoomminus.svg"
                   title="Zoom -"></button>
       <button (click)="zoomIn()"><img class="imgIcone"
                   src="assets/icones/zoomplus.svg"
                   title="Zoom +"></button>
       <ng5-slider [(value)]="rotation"
                   [options]="options"
                   (valueChange)="updateRotation()"></ng5-slider>

       <br />
</div>
<div style="display: flex;text-align: center; margin-bottom: 10px;">
       <div *ngIf="showCropperPanel"
            class="cropper-wrapper">
              <image-cropper [imageChangedEvent]="imageChangedEvent"
                             [imageURL]="imageURL"
                             [maintainAspectRatio]="true"
                             [containWithinAspectRatio]="containWithinAspectRatio"
                             [aspectRatio]="aspectRatio"
                             [resizeToWidth]="256"
                             [cropperMinWidth]="256"
                             [onlyScaleDown]="true"
                             [roundCropper]="false"
                             [canvasRotation]="canvasRotation"
                             [transform]="transform"
                             [alignImage]="'center'"
                             [style.display]="showCropper ? null : 'none'"
                             format="png,jpeg,jpg"
                             (imageCropped)="imageCropped($event)"
                             (imageLoaded)="imageLoaded()"
                             (cropperReady)="cropperReady($event)"
                             (loadImageFailed)="loadImageFailed()"></image-cropper>
              <div *ngIf="loading"
                   class="loader">Carregando...</div>
       </div>
       <div class="cropped-image">
              <img [src]="croppedImage"
                   [style.border]="croppedImage ? '1px solid black' : 'none'" />
       </div>

</div>
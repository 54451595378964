import { EstoqueService } from '../../../services/estoque.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { TranslatePipe } from '../../../pipes/translate.pipe';

@Component({
  selector: 'app-estoque-digital',
  templateUrl: './estoque-digital.component.html',
  styleUrls: ['./estoque-digital.component.css']
})
export class EstoqueDigitalComponent implements OnInit {
  isSubmitted = false;

  estoque = [];
  idMarca: any;
  idUnidade: any;
  idItem: any;
  arquivoBase: any;
  arquivo: any;

  constructor(
    private estoqueService: EstoqueService,
    private router: Router,
    private route: ActivatedRoute,
    private translatePipe: TranslatePipe
  ) { }

  ngOnInit() {

    this.init();

    this.route.paramMap.subscribe(params => {
      this.idMarca = params.get('idMarca');
      this.idUnidade = params.get('idUnidade');
      this.idItem = params.get('idItem');
    });
    this.listaItens();
  }

  init() {

  }

  selecionarArquivoBase(files: any): void {
    const file = files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.arquivoBase = { file_name: files[0].name, base64: reader.result };

      this.arquivo = this.arquivoBase.file_name;
    };
  }

  mostrarUrl(item) {
    Swal.fire(this.translatePipe.transform('estoque_digital'), item.base_file.url, 'info');
  }

  excluirEstoqueDigital(item) {
    Swal.fire({
      title: this.translatePipe.transform('deletar_titulos'),
      text: this.translatePipe.transform('estoque_digital_excluir'),
      showCancelButton: true,
      confirmButtonText: this.translatePipe.transform('deletar_sim'),
      cancelButtonText: this.translatePipe.transform('deletar_nao'),
    }).then(result => {
      if (result.value) {
        this.estoqueService.excluiItemDigital(item._id).subscribe((res: any) => {
          this.listaItens();
        });
      }
    });
  }

  salvar() {
    this.isSubmitted = true;

    if (!this.arquivoBase) {
      Swal.fire(this.translatePipe.transform('estoque_digital'), this.translatePipe.transform('estoque_selecione_arquivo'), 'info');
      return;
    }

    let estoqueDigital = {
      restaurant: this.idUnidade,
      item: this.idItem,
      base_file: this.arquivoBase
    }

    this.estoqueService.salvaItemDigital(estoqueDigital).subscribe(
      res => {
        Swal.fire(this.translatePipe.transform('estoque_digital'), this.translatePipe.transform('estoque_atualizado'), 'success').then(() => {
          
          this.listaItens();
          
          this.arquivoBase = undefined;
          this.arquivo = undefined;

          this.isSubmitted = false;
        });
      },
      error => {
        Swal.fire(this.translatePipe.transform('estoque_digital'), error.error.message, 'error');
      }
    );
  }

  listaItens() {
    this.estoqueService.listaItensDigitais(this.idUnidade, this.idItem).subscribe(res => {
      this.estoque = res;
    });
  }


  voltar() {
    this.router.navigate([`admin/unidades/marca/${this.idMarca}/unidade/${this.idUnidade}/estoque`]);
  }
}

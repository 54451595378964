import { HttpClientModule } from '@angular/common/http';
import { CategoriasService } from '../../../services/categorias.service';
import { TokenService } from '../../../services/token.service';
import { FormGroup, FormBuilder, Validators, FormArray, RequiredValidator } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { TranslatePipe } from '../../../pipes/translate.pipe';

@Component({
  selector: 'app-segmento',
  templateUrl: './segmento.component.html',
  styleUrls: ['./segmento.component.css']
})
export class SegmentoComponent implements OnInit {
  isSubmitted = false;
  @Output() mudaItems = new EventEmitter();
  segmentoForm: FormGroup;
  idSegmento: string;
  url = null;
  modoEdicao = false;
  uploadImage = false;
  image: any;
  imageId: string;
  imageUrl: string;
  imageSecureUrl: string;

  segmentosSuperiores: any;

  constructor(
    private translatePipe: TranslatePipe,
    private fb: FormBuilder,
    private categoriasService: CategoriasService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.init();
    this.listaCategoriasSuperiores();

    this.route.paramMap.subscribe(params => {
      this.idSegmento = params.get('idSegmento');

      if (this.idSegmento) {
        this.modoEdicao = true;
        this.carregaCampos();
      }
    });

  }

  init() {
    this.segmentoForm = this.fb.group({
      nomeItem: ['', Validators.required],
      segmentoSuperior: ['']
    });
  }

  getImage(event) {
    this.image = event;
  }

  onSubmit(form: FormGroup) {
    this.isSubmitted = true;
    if (form.valid && (this.image || this.imageId)) {

      if (this.image) {
        this.categoriasService.uploadImages(this.image).subscribe(res => {
          this.salvar({
            name: this.segmentoForm.value.nomeItem,
            top_category: this.segmentoForm.value.segmentoSuperior ? this.segmentoForm.value.segmentoSuperior : undefined,
            belongs_to: 'Brands',
            image: {
              id: res.public_id,
              url: res.url,
              secure_url: res.secure_url
            }
          });
        });
      }
      else {
        this.salvar({
          name: this.segmentoForm.value.nomeItem,
          top_category: this.segmentoForm.value.segmentoSuperior ? this.segmentoForm.value.segmentoSuperior : undefined,
          belongs_to: 'Brands',
          image: {
            id: this.imageId,
            url: this.imageUrl,
            secure_url: this.imageSecureUrl
          }
        });
      }
    }
  }

  salvar(segmento) {
    if (this.idSegmento) {
      this.categoriasService.editarCategoriaItem(this.idSegmento, segmento).subscribe(
        res => {
          Swal.fire(this.translatePipe.transform('gerenciamento_segmentos'), this.translatePipe.transform('gerenciamento_segmentos_editado'), 'success').then(() => {
            this.router.navigate([`admin/segmentos`]);
          });
        },
        error => {
          Swal.fire(this.translatePipe.transform('gerenciamento_segmentos'), error.error.message, 'error');
        }
      );
    } else {
      this.categoriasService.salvaCategoria(segmento).subscribe(
        res => {
          Swal.fire(this.translatePipe.transform('gerenciamento_segmentos'), this.translatePipe.transform('gerenciamento_segmentos_cadastrado'), 'success').then(() => {
            this.router.navigate([`admin/segmentos`]);
          });
        },
        error => {
          Swal.fire(this.translatePipe.transform('gerenciamento_segmentos'), error.error.message, 'error');
        }
      );
    }
  }

  cancelar() {
    this.router.navigate([`admin/segmentos`]);
  }

  // Editar
  carregaCampos() {
    this.isSubmitted = true;
    this.categoriasService.obterCategoria(this.idSegmento).subscribe(res => {
      this.segmentoForm.controls.nomeItem.setValue(res.name);
      this.segmentoForm.controls.segmentoSuperior.setValue(res.top_category ? res.top_category : '');

      this.imageId = res.image?.id;
      this.imageUrl = res.image?.url;
      this.imageSecureUrl = res.image?.secure_url;
      this.url = res.image.url;
    });
    this.segmentoForm.updateValueAndValidity();
  }


  listaCategoriasSuperiores() {
    this.categoriasService.listaCategoriasSuperiores().subscribe(res => {
      this.segmentosSuperiores = res;
    });
  }
}

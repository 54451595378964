<div class="row"
     style="justify-content: center;align-items: center;display: flex;">
    <div class="col s12 m12 l12 card-block">
        <div class="col s12 m12">
            <div class="card-panel cardPedidos">
                <div class="input-field col s8">
                    <label class="labelSelect">{{'motivos_cancelamento' | translate}}</label> <br />
                    <select materialize
                            id="motivoCancelamento"
                            style="margin-top: 10px;"
                            [(ngModel)]="motivoCancelamento">
                        <option value=""
                                disabled
                                selected>{{'texto_selecione' | translate}}</option>
                        <option *ngFor="let motivo of motivos"
                                [ngValue]="motivo._id">{{ motivo.description }}</option>
                    </select>
                </div>

                <button class="btn btnVoltar"
                        id="voltar"
                        (click)="confirmar()"
                        type="button">
                    {{'texto_confirmar' | translate}}
                </button>






            </div>
            <!-- <div class="row center">
            </div> -->

        </div>
    </div>
</div>
import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OpcionaisService {
  BASEURL: string;
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    this.BASEURL = environment.apiUrl;

    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tokenMappe'),
    });
  }

  listaOpcoes(id: string): Observable<any> {
    return this.http.get(`${this.BASEURL}/optionals/brand/${id}`, {
      headers: this.httpHeaders,
    });
  }
  listaGrupos(id: string): Observable<any> {
    return this.http.get(`${this.BASEURL}/optionals/brand/groups/${id}`, {
      headers: this.httpHeaders,
    });
  }

  obterOpcao(id: string): Observable<any> {
    return this.http.get(`${this.BASEURL}/optionals/${id}`, {
      headers: this.httpHeaders,
    });
  }

  salvaOpcao(body: any): Observable<any> {
    return this.http.post(`${this.BASEURL}/optionals`, body, {
      headers: this.httpHeaders,
    });
  }

  editarOpcao(id: string, body: any): Observable<any> {
    return this.http.put(`${this.BASEURL}/optionals/${id}`, body, {
      headers: this.httpHeaders,
    });
  }

  excluirOpcao(id: string): Observable<any> {
    return this.http.delete(`${this.BASEURL}/optionals/${id}`, {
      headers: this.httpHeaders,
    });
  }
}

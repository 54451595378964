import { PlataformaService } from '../../services/plataforma.service';

import { TokenService } from '../../services/token.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslatePipe } from '../../pipes/translate.pipe';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-configuracoes-plataforma',
  templateUrl: './configuracoes-plataforma.component.html',
  styleUrls: ['./configuracoes-plataforma.component.css']
})
export class ConfiguracoesPlataformaComponent implements OnInit {
  user = this.tokenService.GetPayLoad();

  configuracoes: any;

  isSubmitted = false;
  url = null;
  modoEdicao = false;
  uploadImage = false;

  pesquisaLojas: any;
  pesquisaIngressos: any;
  segmentosuperior: any;

  imageClienteUrl: string;
  imageCliente: any;

  imageEntregadorUrl: string;
  imageEntregador: any;

  mensagemCompartilhamento: any;

  instagram: any;
  facebook: any;
  whatsapp: any;
  email: any;
  site: any;

  cnpj: any;
  nif: any;

  codigopostal: any;
  telefoneObrigatorio: any;
  endereco: any;

  constructor(
    private translatePipe: TranslatePipe,
    private tokenService: TokenService,
    private router: Router,
    private plataformaService: PlataformaService,
  ) { }

  ngOnInit() {
    this.obterConfiguracoes();
  }

  obterConfiguracoes() {
    this.plataformaService.listaConfiguracoes().subscribe(res => {
      this.configuracoes = res;

      if (this.configuracoes.length) {
        this.imageClienteUrl = this.configuracoes[0].image_popup_customer;
        this.imageEntregadorUrl = this.configuracoes[0].image_popup_delivery_person;
        this.mensagemCompartilhamento = this.configuracoes[0].message_customer_share;

        this.instagram = this.configuracoes[0].instagram;
        this.facebook = this.configuracoes[0].facebook;
        this.email = this.configuracoes[0].email;
        this.site = this.configuracoes[0].site;
        this.whatsapp = this.configuracoes[0].whatsapp;

        this.cnpj = this.configuracoes[0].cnpj;
        this.nif = this.configuracoes[0].nif;

        if (!this.imageClienteUrl) {
          this.imageClienteUrl = undefined;
        }
        if (!this.imageEntregadorUrl) {
          this.imageEntregadorUrl = undefined;
        }
        if (!this.mensagemCompartilhamento) {
          this.mensagemCompartilhamento = undefined;
        }
        if (!this.instagram) {
          this.instagram = undefined;
        }
        if (!this.facebook) {
          this.facebook = undefined;
        }
        if (!this.whatsapp) {
          this.whatsapp = undefined;
        }
        if (!this.site) {
          this.site = undefined;
        }
        if (!this.email) {
          this.email = undefined;
        }
        if (!this.cnpj) {
          this.cnpj = undefined;
        }
        if (!this.nif) {
          this.nif = undefined;
        }

        this.codigopostal = this.configuracoes[0].zip_code;
        this.telefoneObrigatorio = this.configuracoes[0].telephone_required;
        this.endereco = this.configuracoes[0].address;
        this.pesquisaIngressos = this.configuracoes[0].search_by_tickets;
        this.pesquisaLojas = this.configuracoes[0].search_by_brands;
        this.segmentosuperior = this.configuracoes[0].upper_segment;
      }
    });
  }

  getImage(event: any, tipo: any) {
    if (tipo === 'cliente') {
      this.imageCliente = event;
    } else if (tipo === 'entregador') {
      this.imageEntregador = event;
    }
  }

  atualizaDocumentacao(documento: string) {
    if (documento === 'cnpj') {
      this.nif = undefined;
    } else {
      this.cnpj = undefined;
    }
  }
  excluirCliente() {
    this.imageClienteUrl = undefined;
  }

  excluirEntregador() {
    this.imageEntregadorUrl = undefined;
  }

  cancelar() {
    this.router.navigate(['admin/home']);
  }

  salvarConfiguracoes() {
    this.isSubmitted = true;

    if (!this.mensagemCompartilhamento) {
      this.mensagemCompartilhamento = undefined;
    }
    if (!this.instagram) {
      this.instagram = undefined;
    }
    if (!this.facebook) {
      this.facebook = undefined;
    }
    if (!this.whatsapp) {
      this.whatsapp = undefined;
    }
    if (!this.site) {
      this.site = undefined;
    }
    if (!this.email) {
      this.email = undefined;
    }
    if (!this.cnpj) {
      this.cnpj = undefined;
    }
    if (!this.nif) {
      this.nif = undefined;
    }

    let configuracoes = {
      search_by_brands: this.pesquisaLojas,
      search_by_tickets: this.pesquisaIngressos,
      upper_segment: this.segmentosuperior,
      address: this.endereco,
      zip_code: this.codigopostal,
      telephone_required: this.telefoneObrigatorio,
      image_popup_customer: this.imageClienteUrl,
      image_popup_delivery_person: this.imageEntregadorUrl,
      message_customer_share: this.mensagemCompartilhamento,
      instagram: this.instagram,
      facebook: this.facebook,
      site: this.site,
      email: this.email,
      whatsapp: this.whatsapp,
      cnpj: this.cnpj,
      nif: this.nif,
    };

    if (this.imageCliente && this.imageEntregador) {
      this.plataformaService.uploadImages(this.imageCliente).subscribe(cliente => {
        configuracoes.image_popup_customer = cliente.secure_url;

        this.plataformaService.uploadImages(this.imageEntregador).subscribe(entregador => {
          configuracoes.image_popup_delivery_person = entregador.secure_url;

          this.salvar(configuracoes);
        })
      })
    }
    else if (this.imageCliente) {
      this.plataformaService.uploadImages(this.imageCliente).subscribe(cliente => {
        configuracoes.image_popup_customer = cliente.secure_url;

        this.salvar(configuracoes);
      })
    }
    else if (this.imageEntregador) {
      this.plataformaService.uploadImages(this.imageEntregador).subscribe(entregador => {
        configuracoes.image_popup_delivery_person = entregador.secure_url;

        this.salvar(configuracoes);
      })
    }
    else {
      this.salvar(configuracoes);
    }
  }

  salvar(configuracoes) {

    this.plataformaService.salvaConfiguracoes(configuracoes).subscribe(
      res => {
        Swal.fire(this.translatePipe.transform('gerenciamento_plataforma'), this.translatePipe.transform('gerenciamento_plataforma_alterado'), 'success').then(() => {
          this.router.navigate(['admin/home']);
        });
      },
      error => {
        Swal.fire(this.translatePipe.transform('gerenciamento_plataforma'), this.translatePipe.transform(error.error.message), 'error');
      }
    );
  }


}

import { PedidosService } from './../../services/pedidos.service';
import { FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { TokenService } from '../../services/token.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {
  pedidos = [];
  mensagem: any;
  mensagemOrdenada: any;
  exibeMensagem = false;
  user = this.tokenService.GetPayLoad();
  textoMensagem = "";
  idPedido = null;

  constructor(
    private tokenService: TokenService,
    private fb: FormBuilder,
    private pedidosService: PedidosService,
    private router: Router,) { }

  ngOnInit() {
    this.obterPedidos();
  }

  obterPedidos() {
    this.pedidosService.obterPedidosChat().subscribe(res => {
      this.pedidos = res;
    });
  }

  mensagens(pedido: any) {
    this.idPedido = pedido;
    this.pedidosService.obterMensagens(pedido).subscribe(res => {
      this.mensagem = res;
      if (this.mensagem.length) {
        this.mensagemOrdenada = this.mensagem.reverse();
        this.exibeMensagem = true;
      }
      else {
        this.exibeMensagem = false;
      }
    });
  }

  enviarMensagem(texto) {
    if (this.idPedido) {
      this.salvar({
        order: this.idPedido,
        user: this.user._id,
        type: 'restaurant',
        message: texto
      });
      delete this.textoMensagem;
    }
  }

  salvar(obj) {
    this.pedidosService.enviarMensagem(obj).subscribe(
      res => {
        this.mensagens(this.idPedido);
      });
  }
}

<app-toolbar></app-toolbar>

<div class="container">
  <form class="col s6"
        [formGroup]="opcaoForm"
        novalidate
        (ngSubmit)="onSubmit(opcaoForm)">



    <div class="row center">
      <label class="labelSelect"
             [class.active]="modoEdicao">{{'opcionais_cardapios_grupo' | translate}}</label> <br />
      <div class="input-field col s6"
           style="
            flex-direction: column;
            display: flex;
            justify-content: start;
            align-items: start;
            margin-left: -83px;
            margin-top: 2rem;
            ">
        <select material-select
                id="grupo"
                (change)="reinit()"
                formControlName="grupo">
          <option value=""
                  disabled
                  selected>{{'texto_selecione' | translate}}</option>
          <option value="-1"
                  selected>*{{'texto_adicionar' | translate}}*</option>
          <option *ngFor="let grupo of gruposCombo"
                  [ngValue]="grupo">{{ grupo }}</option>
        </select>

        <span class="error"
              style="margin-top: 0.5rem;"
              *ngIf="opcaoForm.controls['grupo'].invalid && 
              (opcaoForm.controls['grupo'].touched || isSubmitted)">
          Campo obrigatório
        </span>
      </div>
    </div>


    <div *ngIf="opcaoForm.controls['grupo'].value === '-1'"
         class="row">
      <div class="input-field input-center col s6">
        <input id="grupoNovo"
               type="text"
               formControlName="grupoNovo">
        <label for="grupoNovo"
               [class.active]="modoEdicao">{{'opcionais_cardapios_novo_grupo' | translate}}</label>
        <span class="error"
              *ngIf="opcaoForm.controls['grupoNovo'].invalid && 
                  (opcaoForm.controls['grupoNovo'].touched ||isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>
    </div>





    <div class="row">
      <div class="input-field input-center col s6">
        <input id="nomeItem"
               type="text"
               formControlName="nomeItem">
        <label for="nomeItem"
               [class.active]="modoEdicao">{{'campo_nome' | translate}}</label>
        <span class="error"
              *ngIf="opcaoForm.controls['nomeItem'].invalid && 
                  (opcaoForm.controls['nomeItem'].touched ||isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>
    </div>

    <div class="row">
      <div class="input-field input-center col s6">
        <input id="descricao"
               type="text"
               formControlName="descricao">
        <label for="descricao"
               [class.active]="modoEdicao">{{'campo_descricao' | translate}}</label>
        <span class="error"
              *ngIf="opcaoForm.controls['descricao'].invalid && 
              (opcaoForm.controls['descricao'].touched ||isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>
    </div>


    <div class="row">
      <div class="input-field input-center col s6">
        <input id="preco"
               type="number"
               formControlName="preco">
        <label for="preco"
               [class.active]="modoEdicao">{{'campo_valor_adicional' | translate}}</label>
      </div>
    </div>

    <div class="row center">
      <button class="btn btnCancelar waves-effect"
              id="cancelar"
              (click)="cancelar()"
              type="button">
        {{'texto_cancelar' | translate}}
      </button>
      <button class="btn btnSalvar waves-effect"
              id="salvar"
              type="button"
              (click)="onSubmit(opcaoForm)"
              [disabled]="uploadImage">
        {{'texto_salvar' | translate}}
      </button>
    </div>
  </form>
</div>
import { UnidadesService } from './../../services/unidades.service';
import { TokenService } from '../../services/token.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { TranslatePipe } from '../../pipes/translate.pipe';

@Component({
  selector: 'app-unidades',
  templateUrl: './unidades.component.html',
  styleUrls: ['./unidades.component.css']
})
export class UnidadesComponent implements OnInit {
  id: string;
  marca: string;
  idMarca: string;
  unidades = [];
  url = null;
  user = this.tokenService.GetPayLoad();

  constructor(
    private translatePipe: TranslatePipe,
    private tokenService: TokenService,
    private router: Router,
    private route: ActivatedRoute,
    private unidadesService: UnidadesService
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('idMarca');
    });

    this.obterDadosMarca();
  }

  obterDadosMarca() {
    this.unidadesService.obterDadosMarca(this.id).subscribe(res => {
      this.marca = res.name;
      this.obterUnidadesMarca();
    });
  }

  obterUnidadesMarca() {
    this.unidadesService.obterUnidadeMarca(this.id).subscribe(res => {
      this.unidades = res;
    });
  }

  excluirUnidade(id: string) {
    Swal.fire({
      title: this.translatePipe.transform('deletar_titulos'),
      text: this.translatePipe.transform('excluir_unidade'),
      showCancelButton: true,
      confirmButtonText: this.translatePipe.transform('deletar_sim'),
      cancelButtonText: this.translatePipe.transform('deletar_nao'),
    }).then(result => {
      if (result.value) {
        this.unidadesService.excluirUnidade(id).subscribe(
          (res: any) => {
            this.obterUnidadesMarca();
          },
          error => {
            Swal.fire('Unidade', error.error.message, 'error');
          }
        );
      }
    });
  }

  editar(id: string) {
    this.router.navigate([`admin/unidades/marca/${this.id}/unidade/${id}/editar`]);
  }

  estoque(id: string) {
    this.router.navigate([`admin/unidades/marca/${this.id}/unidade/${id}/estoque`]);
  }
}

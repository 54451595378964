import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnquetesService {
  BASEURL: string;
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    this.BASEURL = environment.apiUrl;

    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tokenMappe'),
    });
  }

  listar(): Observable<any> {
    return this.http.get(`${this.BASEURL}/surveys`, {
      headers: this.httpHeaders,
    });
  }

  listarItem(id: string): Observable<any> {
    return this.http.get(`${this.BASEURL}/surveys/${id}`, {
      headers: this.httpHeaders,
    });
  }

  salvar(body: any): Observable<any> {
    return this.http.post(`${this.BASEURL}/surveys`, body, {
      headers: this.httpHeaders,
    });
  }

  editarItem(id: string, body: any): Observable<any> {
    return this.http.put(`${this.BASEURL}/surveys/${id}`, body, {
      headers: this.httpHeaders,
    });
  }

  excluirItem(id: string): Observable<any> {
    return this.http.delete(`${this.BASEURL}/surveys/${id}`, {
      headers: this.httpHeaders,
    });
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../services/auth.guard';

import { MarcasComponent } from './../components/marcas/marcas.component';
import { MarcaComponent } from '../components/marcas/marca/marca.component';
import { UnidadesComponent } from './../components/unidades/unidades.component';
import { HomeComponent } from './../components/home/home.component';
import { ConfiguracoesPlataformaComponent } from './../components/configuracoes-plataforma/configuracoes-plataforma.component';
import { UnidadeComponent } from '../components/unidades/unidade/unidade.component';
import { CardapiosComponent } from './../components/cardapios/cardapios.component';
import { CardapioComponent } from './../components/cardapios/cardapio/cardapio.component';
import { CategoriasComponent } from '../components/categorias/categorias.component';
import { GerenciamentoUsuariosComponent } from './../components/gerenciamento-usuarios/gerenciamento-usuarios.component';
import { GerenciamentoUsuarioComponent } from './../components/gerenciamento-usuarios/gerenciamento-usuario/gerenciamento-usuario.component';
import { OpcionaisComponent } from '../components/opcionais/opcionais.component';
import { OpcionalComponent } from '../components/opcionais/opcional/opcional.component';
import { PagamentosComponent } from './../components/pagamentos/pagamentos.component';
import { PagamentoComponent } from './../components/pagamentos/pagamento/pagamento.component';
import { ProdutosExtrasComponent } from './../components/produtos-extras/produtos-extras.component';
import { PromocoesComponent } from './../components/promocoes/promocoes.component';
import { PromocaoComponent } from './../components/promocoes/promocao/promocao.component';
import { PedidosComponent } from '../components/controle-pedidos/pedidos.component';
import { ComboComponent } from './../components/combo/combo.component';
import { EntregasComponent } from '../components/controle-entregas/controle-entregas.component';
import { ControleRecebimentoComponent } from './../components/controle-recebimento/controle-recebimento.component';
import { ChatComponent } from './../components/chat/chat.component';
import { ModalCancelamentoPedidoComponent } from './../components/modal-cancelamento-pedido/modal-cancelamento-pedido.component';
import { CategoriaComponent } from '../components/categorias/categoria/categoria.component';
import { EstoqueComponent } from '../components/estoque/estoque.component';
import { EstoqueDigitalComponent } from '../components/estoque/estoque-digital/estoque-digital.component';
import { EstoqueIngressosComponent } from '../components/estoque/estoque-ingressos/estoque-ingressos.component';
import { SegmentosComponent } from '../components/segmentos/segmentos.component';
import { SegmentoComponent } from '../components/segmentos/segmento/segmento.component';
import { SegmentosSuperioresComponent } from '../components/segmentos-superiores/segmentos-superiores.component';
import { SegmentoSuperiorComponent } from '../components/segmentos-superiores/segmento-superior/segmento-superior.component';
import { TaxasComponent } from '../components/taxas/taxas.component';
import { MotivosCancelamentoComponent } from '../components/motivos-cancelamento/motivos-cancelamento.component';
import { CuponsComponent } from './../components/cupons/cupons.component';
import { CupomComponent } from './../components/cupons/cupom/cupom.component';
import { DestaquesAplicativoComponent } from '../components/destaques-aplicativo/destaques-aplicativo.component';
import { EnquetesComponent } from './../components/enquetes/enquetes.component';

const routes: Routes = [
  {
    path: 'admin',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home'
      },
      {
        path: 'home',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: HomeComponent
          },
        ],
        canActivate: [AuthGuard]
      },
      {
        path: 'categorias/marca/:idMarca',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: CategoriasComponent
          },
          {
            path: 'adicionar',
            component: CategoriaComponent
          },
          {
            path: 'categoria/:idCategoria/editar',
            component: CategoriaComponent
          },
        ],
        canActivate: [AuthGuard]
      },
      {
        path: 'segmentos',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: SegmentosComponent
          },
          {
            path: 'adicionar',
            component: SegmentoComponent
          },
          {
            path: 'segmento/:idSegmento/editar',
            component: SegmentoComponent
          },
        ],
        canActivate: [AuthGuard]
      },
      {
        path: 'segmentos-superiores',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: SegmentosSuperioresComponent
          },
          {
            path: 'adicionar',
            component: SegmentoSuperiorComponent
          },
          {
            path: 'segmento/:idSegmento/editar',
            component: SegmentoSuperiorComponent
          },
        ],
        canActivate: [AuthGuard]
      },
      {
        path: 'opcionais/marca/:idMarca',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: OpcionaisComponent
          },
          {
            path: 'adicionar',
            component: OpcionalComponent
          },
          {
            path: 'opcional/:idOpcao/editar',
            component: OpcionalComponent
          },
        ],
        canActivate: [AuthGuard]
      },
      {
        path: 'promocoes/marca/:idMarca',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: PromocoesComponent
          },
          {
            path: 'adicionar',
            component: PromocaoComponent
          },
          {
            path: 'promocao/:idPromocao/editar',
            component: PromocaoComponent
          },
        ],
        canActivate: [AuthGuard]
      },
      {
        path: 'marcas',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: MarcasComponent
          },
          {
            path: 'adicionar',
            component: MarcaComponent
          },
          {
            path: 'editar/:id',
            component: MarcaComponent
          }
        ],
        canActivate: [AuthGuard]
      },
      {
        path: 'unidades/marca/:idMarca',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: UnidadesComponent
          },
          {
            path: 'adicionar',
            component: UnidadeComponent
          },
          {
            path: 'unidade/:idUnidade/editar',
            component: UnidadeComponent
          }
        ],
        canActivate: [AuthGuard]
      },
      {
        path: 'unidades/marca/:idMarca/unidade/:idUnidade/estoque',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: EstoqueComponent
          },
        ],
        canActivate: [AuthGuard]
      },
      {
        path: 'unidades/marca/:idMarca/unidade/:idUnidade/estoque/:idItem/estoque-digital',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: EstoqueDigitalComponent
          },
        ],
        canActivate: [AuthGuard]
      },
      {
        path: 'unidades/marca/:idMarca/unidade/:idUnidade/estoque/:idItem/estoque-ingressos',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: EstoqueIngressosComponent
          },
        ],
        canActivate: [AuthGuard]
      },
      {
        path: 'cardapios/marca/:idMarca',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: CardapiosComponent
          },
          {
            path: 'adicionar',
            component: CardapioComponent
          },
          {
            path: 'produto/:idProduto/editar',
            component: CardapioComponent
          },
          {
            path: 'produto/:idProduto/extras',
            component: ProdutosExtrasComponent
          },
          {
            path: 'produto/:idProduto/combo',
            component: ComboComponent
          }
        ],
        canActivate: [AuthGuard]
      },
      {
        path: '**',
        redirectTo: 'streams'
      },
      {
        path: 'gerenciamento-usuarios',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: GerenciamentoUsuariosComponent
          },
          {
            path: 'adicionar',
            component: GerenciamentoUsuarioComponent
          },
          {
            path: 'editar/:id',
            component: GerenciamentoUsuarioComponent
          }
        ],
        canActivate: [AuthGuard]
      },
      {
        path: 'cupons',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: CuponsComponent
          },
          {
            path: 'adicionar',
            component: CupomComponent
          },
          {
            path: 'editar/:id',
            component: CupomComponent
          }
        ],
        canActivate: [AuthGuard]
      },
      {
        path: 'cupons/marca/:idMarca',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: CuponsComponent
          },
          {
            path: 'adicionar',
            component: CupomComponent
          },
          {
            path: 'editar/:id',
            component: CupomComponent
          },
        ],
        canActivate: [AuthGuard]
      },
      {
        path: 'destaques-aplicativo/marca/:idMarca',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: DestaquesAplicativoComponent
          },
        ],
        canActivate: [AuthGuard]
      },
      {
        path: 'pagamentos',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: PagamentosComponent
          },
          {
            path: 'adicionar',
            component: PagamentoComponent
          },
          {
            path: 'editar/:id',
            component: PagamentoComponent
          }
        ],
        canActivate: [AuthGuard]
      },
      {
        path: 'pedidos',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: PedidosComponent
          }
        ],
        canActivate: [AuthGuard]
      },
      {
        path: 'controle-entregas',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: EntregasComponent
          }
        ],
        canActivate: [AuthGuard]
      },
      {
        path: 'controle-recebimento',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: ControleRecebimentoComponent
          }
        ],
        canActivate: [AuthGuard]
      },
      {
        path: 'chat',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: ChatComponent
          }
        ],
        canActivate: [AuthGuard]
      },
      {
        path: 'modal-cancelamento',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: ModalCancelamentoPedidoComponent
          }
        ],
        canActivate: [AuthGuard]
      },
      {
        path: 'taxas',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: TaxasComponent
          }
        ],
        canActivate: [AuthGuard]
      },
      {
        path: 'enquetes',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: EnquetesComponent
          }
        ],
        canActivate: [AuthGuard]
      },
      {
        path: 'motivos-cancelamento',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: MotivosCancelamentoComponent
          }
        ],
        canActivate: [AuthGuard]
      },
      {
        path: 'configuracoes-plataforma',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: ConfiguracoesPlataformaComponent
          }
        ],
        canActivate: [AuthGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class StreamsRoutingModule { }

import { TranslationService } from '../services/translation.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translate2',
  pure: false
})
export class Translate2Pipe implements PipeTransform {
  constructor(private translationService: TranslationService) {}

  transform(value: any, args?: any): any {
    return this.translationService.translate(value);
  }
}
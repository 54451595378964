<app-toolbar></app-toolbar>

<div class="row"
     style="text-align: right;">
  <a routerLink="adicionar">
    <img class="imgAdicionar"
         src="../../../assets/icones/adicionar.svg">
  </a>
</div>

<div class="row"
     style="justify-content: center;align-items: center;display: flex;">
  <div class="col s12 m12 l12 card-block">
    <div class="row">
      <div class="col cardDiv"
           *ngFor="let pagamento of pagamentos">
        <div class="card">
          <div class="linha">
            <div class="imgDiv">
              <img class="responsive-img"
                   [src]="url || 'assets/images/pagamentos.png'">
            </div>
            <div>
              <div class="div-dados">
                <span class="card-title">
                  {{pagamento.name}}
                </span>
              </div>
              <div class="divBotoes">
                <a class="botoes"
                   (click)="editar(pagamento._id)">
                  <img class="imgIcones"
                       src="../../../assets/icones/editar.svg"
                       [title]="translatePipe.transform('texto_editar')">
                </a>
                <a class="botoes"
                   (click)="excluir(pagamento._id)">
                  <img class="imgIcones"
                       src="../../../assets/icones/excluir.svg"
                       [title]="translatePipe.transform('texto_excluir')">
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
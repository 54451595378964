import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AuthModule } from './modules/auth.module';
import { AppRoutingModule } from './app-routing.module';

import { StreamsModule } from './modules/streams.module';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './services/token-interceptor';
import { NgxMaskModule } from 'ngx-mask';
import { from } from 'rxjs';
import { PoliticaComponent } from './components/politica/politica.component';

import { NgxChartsModule }from '@swimlane/ngx-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PdfViewerModule } from 'ng2-pdf-viewer';

import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
  declarations: [AppComponent, PoliticaComponent],
  imports: [
    BrowserModule,
    AuthModule,
    StreamsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    ImageCropperModule,
    BrowserAnimationsModule,
    NgxChartsModule,
    PdfViewerModule
  ],
  providers: [

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

<div class="row"
     style="justify-content: center;align-items: center;display: flex;">
  <div class="col s12 m12 l12 card-block">

    <div class="col s12 m5">
      <div class="card-panel cardPedidos">
        <div *ngFor="let pedido of pedidos"
             class="card-action"
             (click)="mensagens(pedido._id)">

          <div>
            <label *ngIf="pedido._id === idPedido"
                   class="titulo-pedido titulo-pedido-selecionado">
              {{'pedido_numero' | translate}} {{ pedido._id.substr(-5,5).toUpperCase() }}
            </label>
            <label *ngIf="pedido._id !== idPedido"
                   class="titulo-pedido">
              {{'pedido_numero' | translate}} {{ pedido._id.substr(-5,5).toUpperCase() }}
            </label>
            <label class="horario-pedido right">
              {{pedido.createdAt | date:'dd/MM/yyyy HH:mm'}}
            </label>
            <hr style="margin-bottom: -3px;
                margin-top: 3px;">
          </div>

          <br>
          <div style="margin-left: 10px;">
            <label class="itens-pedido">
              {{'chat_mensagens_nao_lidas' | translate}}:
            </label>
            <label class="itens-pedido">
              {{pedido.message_not_read_count}}
            </label>
            <br>
            <label class="itens-pedido">
              {{'cliente' | translate}}:
            </label>
            <label class="itens-pedido">
              {{pedido?.customer?.name}}
            </label>
            <br>
            <label class="itens-pedido">
              {{'data' | translate}}:
            </label>
            <label class="itens-pedido">
              {{pedido.createdAt | date:'dd/MM/yyyy HH:mm'}}
            </label>
            <br>
            <label class="itens-pedido">
              {{'situacao' | translate}}:
            </label>
            <label class="itens-pedido">
              {{pedido.status | translate }}
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="col s12 m7">
      <div class="card-panel cardMensagens"
           style="display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;">

        <div *ngIf="exibeMensagem"
             style="width: 100%; overflow-x: hidden;overflow-y: auto;">
          <div class="card-content">

            <div class="row"
                 *ngFor="let mensagens of mensagemOrdenada">

              <div class="mensagemCliente"
                   *ngIf="mensagens.type === 'customer'">
                <label class="name-cliente left">Cliente - {{mensagens.createdAt | date:"dd/MM/yyyy HH:mm"}}</label>
                {{mensagens.message}}
              </div>

              <div class="mensagemEntregador"
                   *ngIf="mensagens.type === 'delivery_person'">
                <label class="name-entregador left">Entregador - {{mensagens.createdAt | date:"dd/MM/yyyy HH:mm"}}</label>
                {{mensagens.message}}
              </div>

              <div class="mensagemLoja right"
                   *ngIf="mensagens.type === 'restaurant'">
                <label class="name-loja right">{{mensagens.createdAt | date:"dd/MM/yyyy HH:mm"}} - Loja</label>
                {{mensagens.message}}
              </div>
            </div>

          </div>
        </div>

        <div class="row"
             style="width: 100%;">
          <div class="input-field col s10">
            <input id="mensagem"
                   type="text"
                   [disabled]="!idPedido"
                   (keyup.enter)="enviarMensagem(textoMensagem)"
                   [(ngModel)]="textoMensagem">
          </div>
          <a (click)="enviarMensagem(textoMensagem)">
            <img class="imgMensage"
                 src="assets/icones/send.svg">
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
import { EnquetesService } from '../../services/enquetes.service';
import { TokenService } from '../../services/token.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { TranslatePipe } from '../../pipes/translate.pipe';
import * as moment from 'moment';
@Component({
  selector: 'app-enquetes',
  templateUrl: './enquetes.component.html',
  styleUrls: ['./enquetes.component.css']
})
export class EnquetesComponent implements OnInit {
  isSubmitted = false;
  url = null;
  enqueteForm: FormGroup;
  idMarca: any;
  idProduto: any;
  enquetes = [];
  modoEdicao = false;
  idItem: any;
  user = this.tokenService.GetPayLoad();

  constructor(
    private tokenService: TokenService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private enquetesService: EnquetesService,
    private translatePipe: TranslatePipe
  ) { }

  ngOnInit() {
    this.init();

    this.incluirOptions();

    this.listarEnquetes();
  }

  init() {
    this.enqueteForm = this.fb.group({
      pergunta: ['', Validators.required],
      answers: this.fb.array([])
    });
  }

  listarEnquetes() {
    this.enquetesService.listar().subscribe(res => {
      this.enquetes = res;
    });
  }

  get options() {
    return this.enqueteForm.get('answers') as FormArray;
  }

  incluirOptions() {
    const option = this.fb.group({
      resposta: ['', Validators.required],
    });

    this.options.push(option);
  }

  removeroptions(index: number) {
    this.options.removeAt(index);
  }

  onSubmit(form: FormGroup) {
    this.isSubmitted = true;
    this.enqueteForm.updateValueAndValidity();

    if (form.valid) {
      this.salvar({
        question: this.enqueteForm.value.pergunta,
        answers: this.options
      });
    }
  }

  onSubmitEdit(form: FormGroup) {
    this.enqueteForm.updateValueAndValidity();
    if (form.valid) {
      this.salvar({
        question: this.enqueteForm.value.pergunta,
        answers: this.options
      });
    }
  }

  salvar(enqueteForm) {
    if (this.modoEdicao) {
      const items = [];
      this.options.value.forEach(item => {
        items.push({
          answer: item.resposta,
        });
      });

      enqueteForm.answers = (items.length > 0 ? items : undefined);

      this.enquetesService.editarItem(this.idItem, enqueteForm).subscribe(
        res => {
          Swal.fire(this.translatePipe.transform('cadastro_enquete'), this.translatePipe.transform('mensagem_editar_combo'), 'success').then(() => {
            this.listarEnquetes();

            this.enqueteForm.controls.pergunta.markAsUntouched();
            this.enqueteForm.controls.pergunta.setValue(null);

            this.enqueteForm.controls.answers = this.fb.array([]);

            this.incluirOptions();

            this.isSubmitted = false;
            this.modoEdicao = false;
          });
        },
        error => {
          Swal.fire(this.translatePipe.transform('cadastro_enquete'), error.error.message, 'error');
        }
      );
    } else {
      const items = [];
      this.options.value.forEach(item => {
        items.push({
          answer: item.resposta,
        });
      });

      enqueteForm.answers = (items.length > 0 ? items : undefined);

      this.enquetesService.salvar(enqueteForm).subscribe(
        res => {
          Swal.fire(this.translatePipe.transform('cadastro_enquete'), this.translatePipe.transform('mensagem_combo'), 'success').then(() => {
            this.listarEnquetes();

            this.enqueteForm.controls.pergunta.markAsUntouched();
            this.enqueteForm.controls.pergunta.setValue(null);

            this.enqueteForm.controls.answers = this.fb.array([]);

            this.incluirOptions();

            this.isSubmitted = false;
            this.modoEdicao = false;
          });
        },
        error => {
          Swal.fire(this.translatePipe.transform('cadastro_enquete'), error.error.message, 'error');
        }
      );
    }
  }

  excluirEnquete(id: string) {
    Swal.fire({
      title: this.translatePipe.transform('deletar_titulos'),
      text: this.translatePipe.transform('mensagem_excluir_combo'),
      showCancelButton: true,
      confirmButtonText: this.translatePipe.transform('deletar_sim'),
      cancelButtonText: this.translatePipe.transform('deletar_nao'),
    }).then(result => {
      if (result.value) {
        this.enquetesService.excluirItem(id).subscribe(
          res => {
            this.listarEnquetes();
          },
          error => {
            Swal.fire(this.translatePipe.transform('cadastro_enquete'), error.error.message, 'error');
          }
        );
      }
    });
  }

  editarItem(id: string) {
    this.modoEdicao = true;
    this.idItem = id;
    this.enquetesService.listarItem(id).subscribe(res => {
      this.enqueteForm.controls.pergunta.setValue(res.question);

      this.enqueteForm.controls.answers = this.fb.array([]);

      res.answers.forEach(element => {
        const opcao = this.fb.group({
          resposta: [element.answer, Validators.required],
        });
        this.options.push(opcao);

        this.enqueteForm.updateValueAndValidity();
      });
    });
    window.scrollTo(0, 0);
  }

  cancelar() {
    this.enqueteForm.controls.pergunta.markAsUntouched();
    this.enqueteForm.controls.pergunta.setValue(null);

    this.enqueteForm.controls.answers = this.fb.array([]);

    this.incluirOptions();

    this.isSubmitted = false;
    this.modoEdicao = false;

  }

  voltar() {
    this.router.navigate([`admin/home`]);
  }
}

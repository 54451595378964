import { TokenService } from './../../../services/token.service';
import { MarcasService } from './../../../services/marcas.service';
import { PlataformaService } from './../../../services/plataforma.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { TranslatePipe } from '../../../pipes/translate.pipe';
@Component({
  selector: 'app-marca',
  templateUrl: './marca.component.html',
  styleUrls: ['./marca.component.css']
})
export class MarcaComponent implements OnInit {
  isSubmitted = false;
  marcaForm: FormGroup;
  idMarca: string;
  url = null;
  modoEdicao = false;
  uploadImage = false;
  image: any;
  imageId: string;
  imageUrl: string;
  imageSecureUrl: string;
  categorias: any;
  responsaveis: any;
  taxas: any;
  somenteLeitura: boolean = false;

  mostrarCnpj: boolean = false;
  mostrarNif: boolean = false;

  user = this.tokenService.GetPayLoad();

  constructor(
    private translatePipe: TranslatePipe,
    private tokenService: TokenService,
    private fb: FormBuilder,
    private marcasService: MarcasService,
    private router: Router,
    private route: ActivatedRoute,
    private plataformaService: PlataformaService
  ) { }

  ngOnInit() {

    this.init();

    this.route.paramMap.subscribe(params => {
      this.idMarca = params.get('id');

      if (this.idMarca) {
        this.modoEdicao = true;
        this.carregaCampos();
      }
    });

    this.obterCategorias();
    this.obterUsuarios();
    this.obterTaxas();
  }

  async buscarCEP() {
    this.marcasService.obterLogradouroCep(this.marcaForm.value.zipCode).subscribe(res => {
      this.marcaForm.controls.zipCode.setValue(res.cep.replace('-', ''));
      this.marcaForm.controls.rua.setValue(res.logradouro);
      this.marcaForm.controls.bairro.setValue(res.bairro);
      this.marcaForm.controls.cidade.setValue(res.localidade);
      this.marcaForm.controls.estado.setValue(res.uf);
    })
  }

  obterUsuarios() {
    this.marcasService.listaUsuarios().subscribe(res => {
      this.responsaveis = res;
    });
  }

  obterTaxas() {
    this.marcasService.listaTaxas().subscribe(res => {
      this.taxas = res;
    });
  }

  getImage(event) {
    this.image = event;
  }

  init() {

    this.plataformaService.listaConfiguracoes().subscribe((res) => {
      if (res.length) {
        if (res[0].cnpj === true) {
          this.mostrarCnpj = true;
          this.mostrarNif = false;
        } else {
          this.mostrarCnpj = false;
          this.mostrarNif = true;
        }
      }
    });


    this.marcaForm = this.fb.group({
      nomeMarca: ['', Validators.required],
      responsavel: [''],
      razaoSocial: [''],
      cnpj: [''],
      inscricaoEstadual: [''],
      inscricaoMunicipal: [''],
      nif: [''],
      zipCode: [''],
      rua: [''],
      numero: [''],
      complemento: [''],
      bairro: [''],
      cidade: [''],
      estado: [''],
      telefone: [''],
      email: [''],
      banco: [''],
      bancoCodigo: [''],
      bancoAgencia: [''],
      bancoConta: [''],
      bancoContaDigito: [''],
      lojaativa: [''],
      precodelivery: [''],
      categoria: ['', Validators.required],
      taxa: ['']
    });

    if (this.user.accesses.stores_administrator) {
      this.marcaForm.disable();
      this.somenteLeitura = true;
    }
    else if (this.user.accesses.account_manager) {
      this.marcaForm.controls.responsavel.disable();
      this.marcaForm.controls.responsavel.setValue(this.user._id);
    }

  }

  obterCategorias() {
    this.marcasService.listaCategoriasMarcas().subscribe(res => {
      this.categorias = res;
    });
  }

  onSubmit(form: FormGroup) {
    this.isSubmitted = true;

    if (form.valid && (this.image || this.imageId)) {
      if (this.image) {
        this.marcasService.uploadImages(this.image).subscribe(res => {
          this.salvar({
            name: this.marcaForm.value.nomeMarca,
            account_manager: (this.user.accesses.account_manager ? this.user._id : (this.marcaForm.value.responsavel ? this.marcaForm.value.responsavel : undefined)),
            fees: (this.marcaForm.value.taxa ? this.marcaForm.value.taxa : undefined),
            company_name: (this.marcaForm.value.razaoSocial ? this.marcaForm.value.razaoSocial : undefined),
            company_id: (this.marcaForm.value.cnpj ? this.marcaForm.value.cnpj : undefined),
            company_state_id: (this.marcaForm.value.inscricaoEstadual ? this.marcaForm.value.inscricaoEstadual : undefined),
            company_city_id: (this.marcaForm.value.inscricaoMunicipal ? this.marcaForm.value.inscricaoMunicipal : undefined),
            company_nif: (this.marcaForm.value.nif ? this.marcaForm.value.nif : undefined),
            zip_code: (this.marcaForm.value.zipCode ? this.marcaForm.value.zipCode : undefined),
            address_line_1: (this.marcaForm.value.rua ? this.marcaForm.value.rua : undefined),
            number: (this.marcaForm.value.numero ? this.marcaForm.value.numero : undefined),
            address_line_2: (this.marcaForm.value.complemento ? this.marcaForm.value.complemento : undefined),
            area: (this.marcaForm.value.bairro ? this.marcaForm.value.bairro : undefined),
            city: (this.marcaForm.value.cidade ? this.marcaForm.value.cidade : undefined),
            state: (this.marcaForm.value.estado ? this.marcaForm.value.estado : undefined),
            phone: (this.marcaForm.value.telefone ? this.marcaForm.value.telefone : undefined),
            email: (this.marcaForm.value.email ? this.marcaForm.value.email : undefined),
            isActive: (this.marcaForm.value.lojaativa ? this.marcaForm.value.lojaativa : false),
            showDeliveryPriceApp: (this.marcaForm.value.precodelivery ? this.marcaForm.value.precodelivery : false),
            categories: [this.marcaForm.value.categoria],
            image: {
              id: res.public_id,
              url: res.url,
              secure_url: res.secure_url
            },
            bank_details: {
              name: (this.marcaForm.value.banco ? this.marcaForm.value.banco : undefined),
              code: (this.marcaForm.value.bancoCodigo ? this.marcaForm.value.bancoCodigo : undefined),
              branch: (this.marcaForm.value.bancoAgencia ? this.marcaForm.value.bancoAgencia : undefined),
              account: (this.marcaForm.value.bancoConta ? this.marcaForm.value.bancoConta : undefined),
              digit: (this.marcaForm.value.bancoContaDigito ? this.marcaForm.value.bancoContaDigito : undefined),
            }
          });
        });
      }
      else {
        this.salvar({
          name: this.marcaForm.value.nomeMarca,
          account_manager: this.marcaForm.value.responsavel ? this.marcaForm.value.responsavel : undefined,
          fees: (this.marcaForm.value.taxa ? this.marcaForm.value.taxa : undefined),
          company_name: (this.marcaForm.value.razaoSocial ? this.marcaForm.value.razaoSocial : undefined),
          company_id: (this.marcaForm.value.cnpj ? this.marcaForm.value.cnpj : undefined),
          company_state_id: (this.marcaForm.value.inscricaoEstadual ? this.marcaForm.value.inscricaoEstadual : undefined),
          company_city_id: (this.marcaForm.value.inscricaoMunicipal ? this.marcaForm.value.inscricaoMunicipal : undefined),
          company_nif: (this.marcaForm.value.nif ? this.marcaForm.value.nif : undefined),
          zip_code: (this.marcaForm.value.zipCode ? this.marcaForm.value.zipCode : undefined),
          address_line_1: (this.marcaForm.value.rua ? this.marcaForm.value.rua : undefined),
          number: (this.marcaForm.value.numero ? this.marcaForm.value.numero : undefined),
          address_line_2: (this.marcaForm.value.complemento ? this.marcaForm.value.complemento : undefined),
          area: (this.marcaForm.value.bairro ? this.marcaForm.value.bairro : undefined),
          city: (this.marcaForm.value.cidade ? this.marcaForm.value.cidade : undefined),
          state: (this.marcaForm.value.estado ? this.marcaForm.value.estado : undefined),
          phone: (this.marcaForm.value.telefone ? this.marcaForm.value.telefone : undefined),
          email: (this.marcaForm.value.email ? this.marcaForm.value.email : undefined),
          isActive: (this.marcaForm.value.lojaativa ? this.marcaForm.value.lojaativa : false),
          showDeliveryPriceApp: (this.marcaForm.value.precodelivery ? this.marcaForm.value.precodelivery : false),
          categories: [this.marcaForm.value.categoria],
          image: {
            id: this.imageId,
            url: this.imageUrl,
            secure_url: this.imageSecureUrl
          },
          bank_details: {
            name: (this.marcaForm.value.banco ? this.marcaForm.value.banco : undefined),
            code: (this.marcaForm.value.bancoCodigo ? this.marcaForm.value.bancoCodigo : undefined),
            branch: (this.marcaForm.value.bancoAgencia ? this.marcaForm.value.bancoAgencia : undefined),
            account: (this.marcaForm.value.bancoConta ? this.marcaForm.value.bancoConta : undefined),
            digit: (this.marcaForm.value.bancoContaDigito ? this.marcaForm.value.bancoContaDigito : undefined),
          }
        });
      }
    }
  }

  salvar(marcaForm) {
    if (this.idMarca) {
      this.marcasService.editarMarca(this.idMarca, marcaForm).subscribe(
        res => {
          Swal.fire(this.translatePipe.transform('marca_titulo'), this.translatePipe.transform('marca_editada'), 'success').then(() => {
            this.router.navigate(['admin/marcas']);
          });
        },
        error => {
          Swal.fire(this.translatePipe.transform('marca_titulo'), this.translatePipe.transform(error.error.message), 'error');
        }
      );
    } else {
      this.marcasService.salvaMarca(marcaForm).subscribe(
        res => {
          Swal.fire(this.translatePipe.transform('marca_titulo'), this.translatePipe.transform('marca_cadastrada'), 'success').then(() => {
            this.router.navigate(['admin/marcas']);
          });
        },
        error => {
          Swal.fire(this.translatePipe.transform('marca_titulo'), this.translatePipe.transform(error.error.message), 'error');
        }
      );
    }
  }

  cancelar() {
    this.router.navigate(['admin/marcas']);
  }

  // Editar
  carregaCampos() {
    this.marcasService.obterDadosMarca(this.idMarca).subscribe(res => {
      this.isSubmitted = true;
      this.marcaForm.controls.nomeMarca.setValue(res.name);
      this.marcaForm.controls.responsavel.setValue(res.account_manager ? res.account_manager : '');
      this.marcaForm.controls.taxa.setValue(res.fees ? res.fees._id : '');
      this.marcaForm.controls.razaoSocial.setValue(res.company_name);
      this.marcaForm.controls.cnpj.setValue(res.company_id);
      this.marcaForm.controls.inscricaoEstadual.setValue(res.company_state_id);
      this.marcaForm.controls.inscricaoMunicipal.setValue(res.company_city_id);
      this.marcaForm.controls.nif.setValue(res.company_nif);
      this.marcaForm.controls.zipCode.setValue(res.zip_code);
      this.marcaForm.controls.rua.setValue(res.address_line_1);
      this.marcaForm.controls.numero.setValue(res.number);
      this.marcaForm.controls.complemento.setValue(res.address_line_2);
      this.marcaForm.controls.bairro.setValue(res.area);
      this.marcaForm.controls.cidade.setValue(res.city);
      this.marcaForm.controls.estado.setValue(res.state);
      this.marcaForm.controls.telefone.setValue(res.phone);
      this.marcaForm.controls.email.setValue(res.email);
      this.marcaForm.controls.lojaativa.setValue(res.isActive);
      this.marcaForm.controls.precodelivery.setValue(res.showDeliveryPriceApp);
      this.marcaForm.controls.categoria.setValue(res.categories[0]._id);


      this.marcaForm.controls.banco.setValue(res.bank_details?.name);
      this.marcaForm.controls.bancoCodigo.setValue(res.bank_details?.code);
      this.marcaForm.controls.bancoAgencia.setValue(res.bank_details?.branch);
      this.marcaForm.controls.bancoConta.setValue(res.bank_details?.account);
      this.marcaForm.controls.bancoContaDigito.setValue(res.bank_details?.digit);



      this.imageId = res.image.id;
      this.imageUrl = res.image.url;
      this.imageSecureUrl = res.image.secure_url;
      this.url = res.image.url;
    });
  }
}

import { ExtrasService } from './../../services/extras.service';
import { TokenService } from '../../services/token.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-produtos-extras',
  templateUrl: './produtos-extras.component.html',
  styleUrls: ['./produtos-extras.component.css']
})
export class ProdutosExtrasComponent implements OnInit {
  isSubmitted = false;
  produtoExtraForm: FormGroup;
  url = null;
  idMarca: any;
  idProduto: any;
  ingredientes: any;
  moedasCombo: any;
  user = this.tokenService.GetPayLoad();

  constructor(
    private tokenService: TokenService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private extrasService: ExtrasService
  ) { }

  ngOnInit() {
    this.init();
    this.route.paramMap.subscribe(params => {
      this.idMarca = params.get('idMarca');
      this.idProduto = params.get('idProduto');
    });
    this.listaExtrasProdutos();
    this.obterMoedas();
  }

  init() {
    this.produtoExtraForm = this.fb.group({
      ingredienteExtra: ['', Validators.required],
      valor: ['', Validators.required],
      maximo: ['', Validators.required],
      currency: ['', Validators.required]
    });
  }

  obterMoedas() {
    this.extrasService.obterMoedas(this.idProduto).subscribe(res => {
      this.moedasCombo = res;
      if (this.moedasCombo.length === 1) {
        this.produtoExtraForm = this.fb.group({
          ingredienteExtra: ['', Validators.required],
          valor: ['', Validators.required],
          maximo: ['', Validators.required],
          currency: [this.moedasCombo[0]._id, Validators.required]
        });
      }
    });
  }

  listaExtrasProdutos() {
    this.extrasService.listaExtrasProdutos(this.idProduto).subscribe(res => {
      this.ingredientes = res;
    });
  }

  onSubmit(form: FormGroup) {
    this.isSubmitted = true;
    if (form.valid) {
      this.salvar({
        item_id: this.idProduto,
        name: this.produtoExtraForm.value.ingredienteExtra,
        price: this.produtoExtraForm.value.valor,
        max: this.produtoExtraForm.value.maximo,
        currency: this.produtoExtraForm.value.currency
      });
    }
  }

  salvar(produtoExtraForm) {
    this.extrasService.salvaExtras(produtoExtraForm).subscribe(
      res => {
        Swal.fire('Extras', 'Extra cadastrado com sucesso!', 'success').then(() => {
          this.listaExtrasProdutos();
          this.produtoExtraForm.controls.ingredienteExtra.markAsUntouched();
          this.produtoExtraForm.controls.ingredienteExtra.setValue(null);
          this.produtoExtraForm.controls.valor.markAsUntouched();
          this.produtoExtraForm.controls.valor.setValue(null);
          this.produtoExtraForm.controls.maximo.markAsUntouched();
          this.produtoExtraForm.controls.maximo.setValue(null);

          if (this.moedasCombo.length !== 1) {
            this.produtoExtraForm.controls.currency.markAsUntouched();
            this.produtoExtraForm.controls.currency.setValue('');
          }

          this.isSubmitted = false;
        });
      },
      error => {
        Swal.fire('Extras', error.error.message, 'error');
      }
    );
  }

  deletarIngredienteExtra(id: string) {
    this.extrasService.excluirExtras(id).subscribe(
      res => {
        Swal.fire('Extras', 'Extra excluido com sucesso!', 'success').then(() => {
          this.listaExtrasProdutos();
        });
      },
      error => {
        Swal.fire('Extras', error.error.message, 'error');
      }
    );
  }

  voltar() {
    this.router.navigate([`admin/cardapios/marca/${this.idMarca}`]);
  }
}

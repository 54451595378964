<app-toolbar></app-toolbar>

<div class="container">
  <div class="row">
    <div class="input-field col s1"
         style="margin-right: 10px;">

      <div class="btn-small btnUpload file-field input-field">
        <span>
          Enviar
        </span>
        <input type="file"
               (change)="selecionarArquivoBase($event.target.files)">
      </div>
    </div>
    <div class="input-field col s9">
      <input id="arquivo"
             disabled
             type="text"
             [(ngModel)]="arquivo">
      <label for="arquivo"
             [class.active]="true">{{'campo_arquivo' | translate}}</label>
    </div>

    <div class="input-field col s1">
      <button class="btn-floating"
              id="salvar"
              type="submit"
              data-toggle="tooltip"
              data-placement="bottom"
              title="{{'texto_adicionar' | translate}}"
              (click)="salvar()">
        <i class="material-icons">add</i>
      </button>
    </div>
  </div>

  <div class="col s12 m12 l12">
    <div class="row">
      <table>
        <thead>
          <tr>
            <th>{{'campo_id' | translate}}</th>
            <th>{{'campo_arquivo' | translate}}</th>
            <th style="text-align: center;">{{'campo_url' | translate}}</th>
            <th>{{'campo_situacao' | translate}}</th>
            <th>{{'pedido_numero' | translate}}</th>
            <th style="text-align: center;"></th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let item of estoque">
            <td>{{item._id}}</td>
            <td>{{item.base_file.file_name}}</td>
            <td style="text-align: center;">
              <button class="btn"
                      id="mostrarUrl"
                      type="button"
                      (click)="mostrarUrl(item)">
                {{'campo_url' | translate}}
              </button>

            </td>
            <td>{{item.status | translate}}</td>
            <td>{{item.order}}</td>
            <td style="width:10%; text-align: center;">
              <a *ngIf="!item.order"
                 class="btn-floating btn-remove"
                 (click)="excluirEstoqueDigital(item)"
                 data-toggle="tooltip"
                 data-placement="bottom"
                 title="{{'texto_excluir' | translate}}">
                <i class="material-icons">cancel</i>
              </a>
            </td>

          </tr>
        </tbody>
      </table>

    </div>

  </div>
  <div class="row center">
    <button class="btn btnVoltar"
            id="voltar"
            (click)="voltar()"
            type="button">
      {{'texto_voltar' | translate}}
    </button>

  </div>
</div>
import { MotivosCancelamentoService } from '../../services/motivos-cancelamento.service';
import { PedidosService } from '../../services/pedidos.service';
import { TokenService } from '../../services/token.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Swal from 'sweetalert2';
import { TranslatePipe } from '../../pipes/translate.pipe';
@Component({
    selector: 'app-modal-cancelamento-pedido',
    templateUrl: './modal-cancelamento-pedido.component.html',
    styleUrls: ['./modal-cancelamento-pedido.component.css']
})
export class ModalCancelamentoPedidoComponent implements OnInit {
    motivos = [];
    motivoCancelamento: any;
    @Input() pedido: any = '';
    @Output() pedidoCancelado = new EventEmitter<string>();

    constructor(
        private tokenService: TokenService,
        private router: Router,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private motivosCancelamentoService: MotivosCancelamentoService,
        private translatePipe: TranslatePipe,
        private pedidosService: PedidosService
    ) { }

    ngOnInit() {
        this.listaMotivos();
    }

    listaMotivos() {
        this.motivosCancelamentoService.listaMotivoCancelamento().subscribe(res => {
            this.motivos = res;
            this.motivoCancelamento = '';
        });
    }


    confirmar() {

        if (!this.motivoCancelamento) {
            Swal.fire('Cancelamento de Pedido', this.translatePipe.transform('cancelamento_selecionar'), 'error');
            return;
        }

        let status = {
            status: "canceled",
            cancel_reason: this.motivoCancelamento._id
          }

        this.pedidosService.cancelarPedido(this.pedido, status).subscribe(res => {
            Swal.fire('Cancelamento de Pedido', this.translatePipe.transform('cancelamento_mensagem'), 'success').then(() => {
                
                this.pedidoCancelado.emit('cancelado');
                
              });      
        });







    }

}

<app-toolbar></app-toolbar>

<div class="container">

  <div class="center">
    <image-helper (convertedImage)="getImage($event)"
                  [croppedImage]="imageSecureUrl"></image-helper>
  </div>

  <form class="col s6"
        [formGroup]="usuarioForm"
        novalidate
        (ngSubmit)="onSubmit(usuarioForm)">
    <div class="row">
      <div class="input-field input-center col s6">
        <input id="nomeUsuario"
               type="text"
               formControlName="nomeUsuario">
        <label for="nomeUsuario"
               [class.active]="modoEdicao">{{'campo_nome' | translate}}</label>
        <span class="error"
              *ngIf="usuarioForm.controls['nomeUsuario'].invalid && 
          (usuarioForm.controls['nomeUsuario'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>
    </div>

    <div *ngIf="mostrarCpf"
         class="row">
      <div class="input-field input-center col s6">
        <input id="cpfUsuario"
               type="text"
               autocomplete="off"
               formControlName="cpfUsuario">
        <label for="cpfUsuario"
               [class.active]="modoEdicao">{{'campo_cpf' | translate}}</label>
        <div *ngIf="usuarioForm.controls['cpfUsuario'].invalid && 
               (usuarioForm.controls['cpfUsuario'].touched || isSubmitted)">
          <span class="error"
                *ngIf="!usuarioForm.controls['cpfUsuario'].value">
            {{'texto_obrigatorio' | translate}}
          </span>
          <span class="error"
                *ngIf="!isValidCPF() ">
            {{'cpf_invalido' | translate}}
          </span>
        </div>
      </div>
    </div>

    <div *ngIf="mostrarNif"
         class="row">
      <div class="input-field input-center col s6">
        <input id="nifUsuario"
               type="text"
               autocomplete="off"
               formControlName="nifUsuario">
        <label for="nifUsuario"
               [class.active]="modoEdicao">{{'gerenciamento_plataforma_nif' | translate}}</label>
        <div *ngIf="usuarioForm.controls['nifUsuario'].invalid && 
               (usuarioForm.controls['nifUsuario'].touched || isSubmitted)">
          <span class="error"
                *ngIf="!usuarioForm.controls['nifUsuario'].value">
            {{'texto_obrigatorio' | translate}}
          </span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="input-field input-center col s6">
        <input id="emailUsuario"
               type="text"
               autocomplete="off"
               formControlName="emailUsuario">
        <label for="emailUsuario"
               [class.active]="modoEdicao">{{'campo_email' | translate}}</label>
        <div *ngIf="usuarioForm.controls['emailUsuario'].invalid && 
               (usuarioForm.controls['emailUsuario'].touched || isSubmitted)">
          <span class="error"
                *ngIf="!usuarioForm.controls['emailUsuario'].value">
            {{'texto_obrigatorio' | translate}}
          </span>
          <span class="error"
                *ngIf="usuarioForm.controls['emailUsuario'].value ">
            {{'email_invalido' | translate}}
          </span>
        </div>
      </div>
    </div>




    <div class="row">
      <div class="input-field input-center col s6">
        <div class="center">
          <div class="col s12 m12 l12 card-block">
            <h5 class="title">{{'perfil' | translate}}</h5>
          </div>
        </div>
        <div class="input-field input-center col s6">
          <select material-select
                  id="nomePerfil"
                  formControlName="campoPerfil">
            <option value=""
                    disabled
                    selected>{{'texto_selecione' | translate}}</option>
            <option *ngIf="this.user.accesses.market_place_administrator"
                    value="market_place_administrator">{{'market_place_administrator' | translate}}</option>
            <option *ngIf="this.user.accesses.market_place_administrator"
                    value="account_manager">{{'account_manager' | translate}}</option>
            <option *ngIf="this.user.accesses.market_place_administrator || this.user.accesses.stores_administrator || this.user.accesses.account_manager"
                    value="stores_administrator">{{'stores_administrator' | translate}}</option>
            <option *ngIf="this.user.accesses.stores_administrator"
                    value="employee">{{'employee' | translate}}</option>
            <option *ngIf="this.user.accesses.stores_administrator"
                    value="delivery_person">{{'delivery_person' | translate}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="center">
      <span class="error"
            *ngIf="!usuarioForm.controls['campoPerfil'].value && isSubmitted">
        {{'texto_obrigatorio' | translate}}
      </span>
    </div>

    <div *ngIf="usuarioForm.controls['campoPerfil'].value==='stores_administrator'"
         class="row"
         style="margin-left: 0.1rem;">
      <div class="form-group">
        <div formArrayName="marcas"
             class="marcas">
          <label class="labelSelect"
                 style="margin-left: 26.5%;">{{'titulo_marca' | translate}}</label>

          <div *ngFor="let marca of marcas.controls; let i=index"
               [formGroup]="marca"
               style="margin-top: 20px;"
               class="marca">

            <div class="input-field dropMarca input-center col s10"
                 style="margin-top: -30px;">
              <div class="input-field inline col s8">
                <select material-select
                        id="nomeMarca"
                        formControlName="campoMarca">
                  <option value=""
                          disabled
                          selected>{{'texto_selecione' | translate}}</option>
                  <option *ngFor="let marcaCombo of marcasCombo"
                          [ngValue]="marcaCombo._id">{{ marcaCombo.name }}</option>
                </select>
              </div>
              <a *ngIf="i === marcas.length - 1"
                 class="btn-floating"
                 (click)="incluirMarcas()"
                 data-toggle="tooltip"
                 data-placement="bottom"
                 title="{{'texto_adicionar' | translate}}">
                <i class="material-icons">add</i>
              </a>
              <a *ngIf="i > 0"
                 class="btn-floating btn-remove"
                 (click)="removerMarcas(i)"
                 data-toggle="tooltip"
                 data-placement="bottom"
                 title="{{'texto_excluir' | translate}}">
                <i class="material-icons">cancel</i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="usuarioForm.controls['campoPerfil'].value==='employee' || usuarioForm.controls['campoPerfil'].value==='delivery_person'"
         class="row"
         style="margin-left: 0.1rem;">
      <div class="form-group">
        <div formArrayName="unidades"
             class="unidades">
          <label class="labelSelect"
                 style="margin-left: 26.5%;">{{'titulo_unidade' | translate}}</label>

          <div *ngFor="let unidade of unidades.controls; let i=index"
               [formGroup]="unidade"
               class="unidade"
               style="margin-top: 20px;">

            <div class="input-field dropUnidade input-center col s8"
                 style="margin-top: -30px;">
              <div class="input-field inline col s8">
                <select material-select
                        id="nomeUnidade"
                        formControlName="campoUnidade">
                  <option value=""
                          disabled
                          selected>{{'texto_selecione' | translate}}</option>
                  <option *ngFor="let unidadeCombo of unidadesCombo"
                          [ngValue]="unidadeCombo._id">{{ unidadeCombo.brand.name }} - {{ unidadeCombo.name }}</option>
                </select>
              </div>
              <a *ngIf="i === unidades.length - 1"
                 class="btn-floating"
                 (click)="incluirUnidades()"
                 data-toggle="tooltip"
                 data-placement="bottom"
                 title="{{'texto_adicionar' | translate}}">
                <i class="material-icons">add</i>
              </a>
              <a *ngIf="i > 0"
                 class="btn-floating btn-remove"
                 (click)="removerUnidades(i)"
                 data-toggle="tooltip"
                 data-placement="bottom"
                 title="{{'texto_excluir' | translate}}">
                <i class="material-icons">cancel</i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="center">
      <span class="error"
            *ngIf="isSubmitted && ((usuarioForm.controls['campoPerfil'].value==='stores_administrator' && isValidMarcas() === false)
                               || ((usuarioForm.controls['campoPerfil'].value==='employee' || usuarioForm.controls['campoPerfil'].value==='delivery_person') && isValidUnidades() === false))">
        {{'texto_obrigatorio' | translate}}
      </span>
    </div>




    <div class="row center">
      <button class="btn btnCancelar waves-effect"
              id="cancelar"
              (click)="cancelar()"
              type="button">
        {{'texto_cancelar' | translate}}
      </button>

      <button class="btn btnSalvar waves-effect"
              id="salvar"
              type="submit"
              [disabled]="uploadImage">
        {{'texto_salvar' | translate}}
      </button>
    </div>
  </form>
</div>
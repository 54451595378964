<app-toolbar></app-toolbar>

<div class="container">
  <form class="col s6"
        [formGroup]="produtoExtraForm"
        novalidate
        (keydown.enter)="$event.preventDefault()"
        (ngSubmit)="onSubmit(produtoExtraForm)">
    <div class="row"
         style="margin-bottom: 0;">
      <div class="input-field col s5">
        <input id="ingredienteExtra"
               type="text"
               formControlName="ingredienteExtra">
        <label for="ingredienteExtra">{{'campo_ingredientes' | translate}}</label>
        <span class="error"
              *ngIf="produtoExtraForm.controls['ingredienteExtra'].invalid && 
        (produtoExtraForm.controls['ingredienteExtra'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>

      <div class="input-field col s2">
        <input id="valor"
               type="number"
               formControlName="valor">
        <label for="valor">{{'campo_valor' | translate}}</label>
        <span class="error"
              *ngIf="produtoExtraForm.controls['valor'].invalid && 
        (produtoExtraForm.controls['valor'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>

      <div class="input-field col s2">
        <input id="maximo"
               type="number"
               formControlName="maximo">
        <label for="maximo">{{'quantidade_maxima_extra' | translate}}</label>
        <span class="error"
              *ngIf="produtoExtraForm.controls['maximo'].invalid && 
        (produtoExtraForm.controls['maximo'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>

      <div *ngIf="moedasCombo?.length !== 1"
           class="input-field col s3"
           style="display: flex; flex-direction: column; margin-top: -11px;">
        <span class="labelSelect"
              style="margin-left: 0;margin-bottom: 3px;margin-right: 10px;display: block;">
          {{'campo_moeda' | translate}}
        </span>

        <div class="input-field inline"
             style="margin-top: 0px;
        margin-left: 0;">
          <select material-select
                  id="currency"
                  formControlName="currency">
            <option value=""
                    disabled
                    selected>{{'texto_selecione' | translate}}</option>
            <option *ngFor="let moeda of moedasCombo"
                    [ngValue]="moeda._id">
              {{ moeda.name }}
            </option>
          </select>

          <div style="margin-top: 0.5rem;">
            <span class="error"
                  *ngIf="produtoExtraForm.controls['currency'].invalid && 
        (produtoExtraForm.controls['currency'].touched || isSubmitted)">
              {{'texto_obrigatorio' | translate}}
            </span>
          </div>
        </div>
      </div>

      <div class="s1"
           style="margin-left: 5px;">
        <button class="btn-floating"
                id="salvar"
                type="submit"
                data-toggle="tooltip"
                data-placement="bottom"
                title="{{'texto_adicionar' | translate}}"
                style="margin-top: 1rem !important;">
          <i class="material-icons">add</i>
        </button>
      </div>
    </div>
  </form>

  <div class="col s12 m12 l12">
    <div class="row"
         style="margin-top: 4rem;">
      <table>
        <thead>
          <tr>
            <th>{{'campo_ingredientes' | translate}}</th>
            <th>{{'campo_valor' | translate}}</th>
            <th>{{'quantidade_maxima_extra' | translate}}</th>
            <th *ngIf="moedasCombo?.length !== 1">{{'campo_moeda' | translate}}</th>
            <th style="text-align: center;">{{'texto_acao' | translate}}</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let ingrediente of ingredientes">
            <td>{{ingrediente.name}}</td>
            <td>{{ingrediente.price | number: '0.2-2'}}</td>
            <td>{{ingrediente.max | number: '0.0-0'}}</td>
            <td *ngIf="moedasCombo?.length !== 1">{{ingrediente.currency.name}}</td>
            <td style="text-align: center;">
              <a class="btn-floating btn-remove"
                 (click)="deletarIngredienteExtra(ingrediente._id)"
                 data-toggle="tooltip"
                 data-placement="bottom"
                 title="{{'texto_excluir' | translate}}">
                <i class="material-icons">cancel</i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>

  <div class="row center">
    <button class="btn btnVoltar"
            id="voltar"
            (click)="voltar()"
            type="button">
      {{'texto_voltar' | translate}}
    </button>
  </div>
</div>
<app-toolbar></app-toolbar>

<div style="justify-content: center;align-items: center;display: flex;width: 100%;padding-left: 18px;">
  <div class="col s12 m12 l12 card-block">
    <h5 class="title">{{'cadastro_enquete' | translate}}</h5>
  </div>
</div>

<div class="container">
  <div class="card-panel">
    <form class="col s12"
          [formGroup]="enqueteForm"
          novalidate
          (ngSubmit)="onSubmit(enqueteForm)">
      <div class="container">
        <div class="row">
          <div class="input-field col s12"
               style="margin-top: -10px;">
            <label class="labelSelect"
                   [class.active]="modoEdicao"
                   for="pergunta">{{'campo_enquete_pergunta' | translate}}</label>
            <input id="pergunta"
                   type="text"
                   formControlName="pergunta">
            <span class="error"
                  *ngIf="enqueteForm.controls['pergunta'].invalid && (enqueteForm.controls['pergunta'].touched || isSubmitted)">
              {{'texto_obrigatorio' | translate}}
            </span>
          </div>
        </div>

        <div class="row">
          <div formArrayName="answers">

            <div *ngFor="let option of options.controls; let i=index"
                 [formGroup]="option">

              <div class="row"
                   style="padding-left: 20px;">
                <div class="input-field col s10">
                  <label class="labelSelect"
                         [class.active]="modoEdicao"
                         for="resposta">{{'campo_enquete_resposta' | translate}}</label>
                  <input id="resposta"
                         formControlName="resposta"
                         type="text">
                  <span class="error"
                        *ngIf="options.controls[i].controls.resposta.invalid && (options.controls[i].controls.resposta.touched || isSubmitted)">
                    {{'texto_obrigatorio' | translate}}
                  </span>
                </div>
              </div>

              <div style="text-align: right;">
                <a *ngIf="i === options.length - 1"
                   class="btn-floating"
                   (click)="incluirOptions()"
                   data-toggle="tooltip"
                   data-placement="bottom"
                   title="{{'texto_adicionar' | translate}}"
                   style="margin-left: 10px !important;">
                  <i class="material-icons">add</i>
                </a>

                <a *ngIf="i > 0"
                   class="btn-floating btn-remove"
                   (click)="removeroptions(i)"
                   data-toggle="tooltip"
                   data-placement="bottom"
                   title="{{'texto_excluir' | translate}}">
                  <i class="material-icons">cancel</i>
                </a>
              </div>

              <br />


            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="row">
    <button *ngIf="!modoEdicao"
            class="btn btnSalvar waves-effect right"
            id="salvar"
            type="button"
            (click)="onSubmit(enqueteForm)"
            [disabled]="uploadImage">
      {{'texto_salvar' | translate}}
    </button>

    <button *ngIf="modoEdicao"
            class="btn btnSalvar waves-effect right"
            id="salvar"
            type="button"
            (click)="onSubmitEdit(enqueteForm)">
      {{'texto_salvar' | translate}}
    </button>

    <button *ngIf="modoEdicao"
            class="btn btnCancelar right"
            id="cancelar"
            (click)="cancelar()"
            type="button">
      {{'texto_cancelar' | translate}}
    </button>

  </div>

  <table style="margin-top: 5rem;">
    <thead>
      <tr>
        <th style="width: 70%;">{{'campo_enquete_perguntas' | translate}}</th>
        <th style="text-align: center;">{{'texto_acao' | translate}}</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let item of enquetes">
        <td style="width:70%;">{{item.question}}</td>
        <td style="width:10%; text-align: center;">
          <a class="btn-floating btn-edit"
             (click)="editarItem(item._id)"
             data-toggle="tooltip"
             data-placement="bottom"
             title="{{'texto_editar' | translate}}">
            <i class="material-icons"
               style="color: orange;">create</i>
          </a>

          <a class="btn-floating btn-remove"
             (click)="excluirEnquete(item._id)"
             data-toggle="tooltip"
             data-placement="bottom"
             title="{{'texto_excluir' | translate}}">
            <i class="material-icons">cancel</i>
          </a>

        </td>
      </tr>

    </tbody>
  </table>

  <div class="row center"
       style="margin-top: 3rem;">
    <button class="btn btnVoltar"
            id="voltar"
            (click)="voltar()"
            type="button">
      {{'texto_voltar' | translate}}
    </button>
  </div>
</div>
<app-toolbar></app-toolbar>

<div class="container">

  <div class="center">
    <image-helper (convertedImage)="getImage($event)"
                  [croppedImage]="imageSecureUrl"></image-helper>
  </div>

  <div class="center">
    <span class="error"
          *ngIf="!image && !imageId && isSubmitted">
      {{'texto_obrigatorio' | translate}}
    </span>
  </div>

  <form class="col s6"
        [formGroup]="segmentoForm"
        novalidate
        (ngSubmit)="onSubmit(segmentoForm)">

    <div class="row">
      <div class="input-field input-center col s6">
        <input id="nomeItem"
               type="text"
               maxlength="20"
               formControlName="nomeItem">
        <label for="nomeItem"
               [class.active]="modoEdicao">{{'gerenciamento_segmentos_superior_descricao' | translate}}</label>
        <span class="error"
              *ngIf="segmentoForm.controls['nomeItem'].invalid && 
          (segmentoForm.controls['nomeItem'].touched ||isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>
    </div>

    <div class="row center">
      <button class="btn btnCancelar waves-effect"
              id="cancelar"
              (click)="cancelar()"
              type="button">
        {{'texto_cancelar' | translate}}
      </button>
      <button class="btn btnSalvar waves-effect"
              id="salvar"
              type="button"
              (click)="onSubmit(segmentoForm)"
              [disabled]="uploadImage">
        {{'texto_salvar' | translate}}
      </button>
    </div>
  </form>
</div>
import { UsuariosService } from './../../services/usuarios.service';
import { TokenService } from '../../services/token.service';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { TranslatePipe } from '../../pipes/translate.pipe';

@Component({
  selector: 'app-gerenciamento-usuarios',
  templateUrl: './gerenciamento-usuarios.component.html',
  styleUrls: ['./gerenciamento-usuarios.component.css']
})
export class GerenciamentoUsuariosComponent implements OnInit {
  usuarios = [];
  perfis = [];
  url = null;
  user = this.tokenService.GetPayLoad();

  constructor(private translatePipe: TranslatePipe, private tokenService: TokenService, private usuariosService: UsuariosService, private router: Router) { }

  ngOnInit() {
    this.listaUsuarios();
  }

  listaUsuarios() {
    this.usuariosService.listaUsuarios().subscribe(res => {
      res.map((usuario) => {
        if (this.perfis.filter((perfil) => { return perfil === usuario.role }).length === 0) {
          this.perfis.push(usuario.role);
        }
      });

      this.usuarios = res;
    });
  }

  usuariosFiltros(perfil) {
    let usuariosFiltrados = this.usuarios.filter((usuario) => { return usuario.role === perfil });

    usuariosFiltrados.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase())
        return -1;
      if (a.name.toLowerCase() > b.name.toLowerCase())
        return 1;
      return 0;
    });

    return usuariosFiltrados;
  }

  excluir(id: string) {
    Swal.fire({
      title: this.translatePipe.transform('deletar_titulos'),
      text: this.translatePipe.transform('excluir_usuario'),
      showCancelButton: true,
      confirmButtonText: this.translatePipe.transform('deletar_sim'),
      cancelButtonText: this.translatePipe.transform('deletar_nao'),
    }).then(result => {
      if (result.value) {
        this.usuariosService.excluirUsuario(id).subscribe((res: any) => {
          this.listaUsuarios();
        },
          error => {
            Swal.fire('Gerenciamento de Usuários', error.error.message, 'error');
          }
        );
      }
    });
  }

  novasenha(email: string) {
    Swal.fire({
      title: this.translatePipe.transform('deletar_titulos'),
      text: this.translatePipe.transform('nova_senha_usuario'),
      showCancelButton: true,
      confirmButtonText: this.translatePipe.transform('deletar_sim'),
      cancelButtonText: this.translatePipe.transform('deletar_nao'),
    }).then(result => {
      if (result.value) {

        this.usuariosService.novaSenhaUsuario({ email }).subscribe((res: any) => {
          Swal.fire(this.translatePipe.transform('usuario_titulo'), this.translatePipe.transform('usuario_nova_senha'), 'success');
        },
          error => {
            Swal.fire('Gerenciamento de Usuários', error.error.message, 'error');
          }
        );
      }
    });
  }

  editar(id: string) {
    this.router.navigate([`admin/gerenciamento-usuarios/editar/${id}`]);
  }
}

import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CuponsService {
  BASEURL: string;
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    this.BASEURL = environment.apiUrl;

    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tokenMappe'),
    });
  }

  listaCupons(): Observable<any> {
    return this.http.get(`${this.BASEURL}/coupons`, {
      headers: this.httpHeaders,
    });
  }

  listaCuponsPorMarca(idMarca: any): Observable<any> {
    return this.http.get(`${this.BASEURL}/coupons/brand/${idMarca}`, {
      headers: this.httpHeaders,
    });
  }

  listaItemCupom(id: string): Observable<any> {
    return this.http.get(`${this.BASEURL}/coupons/${id}`, {
      headers: this.httpHeaders,
    });
  }

  salvaCupom(body: any): Observable<any> {
    return this.http.post(`${this.BASEURL}/coupons`, body, {
      headers: this.httpHeaders,
    });
  }

  editarItemCupom(id: string, body: any): Observable<any> {
    return this.http.put(`${this.BASEURL}/coupons/${id}`, body, {
      headers: this.httpHeaders,
    });
  }

  excluirItemCupom(id: string): Observable<any> {
    return this.http.delete(`${this.BASEURL}/coupons/${id}`, {
      headers: this.httpHeaders,
    });
  }

  listaMarcas(): Observable<any> {
    return this.http.get(`${this.BASEURL}/brands`, {
      headers: this.httpHeaders,
    });
  }

  obterLogradouroCep(cep: string): Observable<any> {
    return this.http.get(`${this.BASEURL}/addresses/cep/${cep}`, {
      headers: this.httpHeaders,
    });
  }
}

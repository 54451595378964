<app-toolbar></app-toolbar>

<div class="row"
     style="justify-content: center;align-items: center;display: flex;">
  <div class="col s12 m12 l12 card-block">

    <div class="col s12 m3">
      <div class="card-panel cardUsuario">
        <div class="dados-usuario">
          <label class="dados-usuario">{{'ola' | translate}}, {{this.user.name}}!</label>
        </div>
        <div class="imgDivHome">
          <img class="responsive-img"
               [src]="this.user?.image?.secure_url ? this.user?.image?.secure_url : 'assets/images/nophoto.png'">
        </div>
        <div class="dados-usuario">
          <label class="dados-usuario">{{'role' | translate}}: {{this.user.role | translate }}</label>
        </div>
        <div class="dados-usuario">
          <label class="dados-usuario">{{'campo_email' | translate}}: {{this.user.email}}</label>
        </div>
        <div class="dados-usuario waves-effect">
          <label class="dados-usuario waves-effect"
                 (click)="solicitarToken()">{{'alterar_senha' | translate}}</label>
        </div>
      </div>
    </div>

    <div class="col s12 m9">
      <div class="row"
           style=" height: 35vh; margin-top: 20px;">
        <div class="col s12 l12">
          <div *ngIf="this.user.role === 'employee'"
               class="col s12 m12"
               style="height: 35vh;">
            <ngx-charts-bar-vertical [results]="pedidos30diasQuantidade"
                                     [xAxisLabel]="'Pedidos nos últimos 30 dias (Quantidades)'"
                                     [yAxisLabel]="'Pedidos'"
                                     [showXAxisLabel]="true"
                                     [showYAxisLabel]="true"
                                     [xAxis]="true"
                                     [yAxis]="true"
                                     [gradient]="true">
            </ngx-charts-bar-vertical>

          </div>
          <div *ngIf="this.user.role !== 'employee'"
               class="col s12 m6"
               style="height: 35vh;">
            <ngx-charts-bar-vertical [results]="pedidos30diasQuantidade"
                                     [xAxisLabel]="'Pedidos nos últimos 30 dias (Quantidades)'"
                                     [yAxisLabel]="'Pedidos'"
                                     [showXAxisLabel]="true"
                                     [showYAxisLabel]="true"
                                     [xAxis]="true"
                                     [yAxis]="true"
                                     [gradient]="true">
            </ngx-charts-bar-vertical>

          </div>
          <div *ngIf="this.user.role !== 'employee'"
               class="col s12 m6"
               style="height: 35vh;">

            <ngx-charts-bar-vertical [results]="pedidos30diasValor"
                                     [xAxisLabel]="'Pedidos nos últimos 30 dias (Valores)'"
                                     [yAxisLabel]="'Valor'"
                                     [showXAxisLabel]="true"
                                     [showYAxisLabel]="true"
                                     [xAxis]="true"
                                     [yAxis]="true"
                                     [gradient]="true">
            </ngx-charts-bar-vertical>
          </div>
        </div>
      </div>

      <!-- Gerente de Loja -->

      <div *ngIf="this.user.role === 'stores_administrator'"
           class="row"
           style="height: 25vh;">

        <div class="col s12 l12">
          <div class="col s12 m6"
               style="height: 25vh; text-align: center;">

            <div class="card-panel"
                 style="height: 90%;">
              <label class="titulo-card">
                {{'recebimento_30_dias' | translate}}
              </label>
              <hr style="margin-bottom: -3px;
                margin-top: 3px;">
              <label class="titulo-card">
                {{valor30dias | number: '0.2-2'}}
              </label>

            </div>

          </div>
          <div class="col s12 m3"
               style="height: 25vh; text-align: center;">
            <div class="card-panel"
                 style="height: 90%;">
              <label class="titulo-card">
                {{'novo_pedido' | translate}}
              </label>
              <hr style="margin-bottom: -3px;
                  margin-top: 3px;">
              <label class="titulo-card">
                {{novos}}
              </label>

            </div>
          </div>
          <div class="col s12 m3"
               style="height: 25vh; text-align: center;">

            <div class="card-panel"
                 style="height: 90%;">
              <label class="titulo-card">
                {{'pedido_liberado' | translate}}
              </label>
              <hr style="margin-bottom: -3px;
                    margin-top: 3px;">
              <label class="titulo-card">
                {{liberados}}
              </label>

            </div>

          </div>
        </div>
      </div>

      <div *ngIf="this.user.role === 'stores_administrator'"
           class="row"
           style="height: 25vh;">

        <div class="col s12 l12">
          <div class="col s12 m6"
               style="height: 25vh; text-align: center;">
          </div>
          <div class="col s12 m3"
               style="height: 25vh; text-align: center;">

            <div class="card-panel"
                 style="height: 90%;">
              <label class="titulo-card">
                {{'pedido_producao' | translate}}
              </label>
              <hr style="margin-bottom: -3px;
                    margin-top: 3px;">
              <label class="titulo-card">
                {{producao}}
              </label>

            </div>

          </div>
          <div class="col s12 m3"
               style="height: 25vh; text-align: center;">

            <div class="card-panel"
                 style="height: 90%;">
              <label class="titulo-card">
                {{'pedido_enviado' | translate}}
              </label>
              <hr style="margin-bottom: -3px;
                    margin-top: 3px;">
              <label class="titulo-card">
                {{enviados}}
              </label>

            </div>

          </div>
        </div>
      </div>

      <!-- Funcionário -->

      <div *ngIf="this.user.role === 'employee'"
           class="row"
           style="height: 25vh;">

        <div class="col s12 l12">
          <div class="col s12 m6"
               style="height: 25vh; text-align: center;">
            <div class="card-panel"
                 style="height: 90%;">
              <label class="titulo-card">
                {{'novo_pedido' | translate}}
              </label>
              <hr style="margin-bottom: -3px;
                  margin-top: 3px;">
              <label class="titulo-card">
                {{novos}}
              </label>

            </div>
          </div>
          <div class="col s12 m6"
               style="height: 25vh; text-align: center;">

            <div class="card-panel"
                 style="height: 90%;">
              <label class="titulo-card">
                {{'pedido_liberado' | translate}}
              </label>
              <hr style="margin-bottom: -3px;
                    margin-top: 3px;">
              <label class="titulo-card">
                {{liberados}}
              </label>

            </div>

          </div>
        </div>
      </div>

      <div *ngIf="this.user.role === 'employee'"
           class="row"
           style="height: 25vh;">

        <div class="col s12 l12">
          <div class="col s12 m6"
               style="height: 25vh; text-align: center;">

            <div class="card-panel"
                 style="height: 90%;">
              <label class="titulo-card">
                {{'pedido_producao' | translate}}
              </label>
              <hr style="margin-bottom: -3px;
                    margin-top: 3px;">
              <label class="titulo-card">
                {{producao}}
              </label>

            </div>

          </div>
          <div class="col s12 m6"
               style="height: 25vh; text-align: center;">

            <div class="card-panel"
                 style="height: 90%;">
              <label class="titulo-card">
                {{'pedido_enviado' | translate}}
              </label>
              <hr style="margin-bottom: -3px;
                    margin-top: 3px;">
              <label class="titulo-card">
                {{enviados}}
              </label>

            </div>

          </div>
        </div>
      </div>

      <!-- Administrador de Market Place -->

      <div *ngIf="this.user.role === 'market_place_administrator'"
           class="row"
           style="height: 25vh;">

        <div class="col s12 l12">
          <div class="col s12 m4"
               style="height: 25vh; text-align: center;">

            <div class="card-panel"
                 style="height: 90%;">
              <label class="titulo-card">
                {{'recebimento_30_dias' | translate}}
              </label>
              <hr style="margin-bottom: -3px; margin-top: 3px;">
              <label class="titulo-card">
                {{valor30dias | number: '0.2-2'}}
              </label>
            </div>

          </div>
          <div class="col s12 m4"
               style="height: 25vh; text-align: center;">
            <div class="card-panel"
                 style="height: 90%;">
              <label class="titulo-card">
                {{'recebimento_30_dias' | translate}}
              </label>
              <hr style="margin-bottom: -3px; margin-top: 3px;">
              <label class="titulo-card">
                {{valor30dias | number: '0.2-2'}}
              </label>
            </div>
          </div>
          <div class="col s12 m4"
               style="height: 25vh; text-align: center;">

            <div class="card-panel"
                 style="height: 90%;">
              <label class="titulo-card">
                {{'marcas' | translate}}
              </label>
              <hr style="margin-bottom: -3px;
               margin-top: 3px;">
              <label class="titulo-card">
                {{lojas}}
              </label>

            </div>

          </div>
        </div>
      </div>

      <div *ngIf="this.user.role === 'market_place_administrator'"
           class="row"
           style="height: 25vh;">

        <div class="col s12 l12">
          <div class="col s12 m4"
               style="height: 25vh; text-align: center;">

            <div class="card-panel"
                 style="height: 90%;">
              <label class="titulo-card">
                {{'recebimento_30_dias' | translate}}
              </label>
              <hr style="margin-bottom: -3px; margin-top: 3px;">
              <label class="titulo-card">
                {{valor30dias | number: '0.2-2'}}
              </label>
            </div>

          </div>
          <div class="col s12 m4"
               style="height: 25vh; text-align: center;">

            <div class="card-panel"
                 style="height: 90%;">
              <label class="titulo-card">
                {{'recebimento_30_dias' | translate}}
              </label>
              <hr style="margin-bottom: -3px; margin-top: 3px;">
              <label class="titulo-card">
                {{valor30dias | number: '0.2-2'}}
              </label>
            </div>

          </div>
          <div class="col s12 m4"
               style="height: 25vh; text-align: center;">

            <div class="card-panel"
                 style="height: 90%;">
              <label class="titulo-card">
                {{'unidades' | translate}}
              </label>
              <hr style="margin-bottom: -3px;
               margin-top: 3px;">
              <label class="titulo-card">
                {{unidades}}
              </label>

            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</div>
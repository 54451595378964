<app-toolbar></app-toolbar>

<div *ngIf="this.user.accesses.market_place_administrator || this.user.accesses.account_manager"
     class="row"
     style="text-align: right;">
  <a routerLink="adicionar">
    <img class="imgAdicionar"
         src="../../../assets/icones/adicionar.svg">
  </a>
</div>

<div *ngFor="let categoria of categorias">
  <h6 class="title-category">{{ categoria.name }}</h6>
  <div class="row"
       style="justify-content: center;align-items: center;display: flex;">
    <div class="col s12 m12 l12 card-block">
      <div class="row">
        <div class="col cardDiv"
             *ngFor="let marca of marcasFiltros(categoria)">
          <div class="card">
            <div class="linha">
              <div class="imgDiv">
                <img class="responsive-img"
                     [src]="marca.image.url || 'assets/images/nophoto.png'">
              </div>
              <div>
                <div class="div-dados">
                  <span class="card-title">
                    {{marca.name}}
                  </span>
                  <br>
                  <span class="titleItalic">
                    Loja {{marca.situacao}}
                  </span>
                </div>
                <div class="divBotoes">
                  <a class="botoes"
                     (click)="editar(marca._id)">
                    <img class="imgIcones"
                         src="../../../assets/icones/editar.svg"
                         [title]="translatePipe.transform('texto_editar')">
                  </a>
                  <a *ngIf="this.user.accesses.menus"
                     class="botoes"
                     (click)="redirecionaCategorias(marca._id)">
                    <img class="imgIcones"
                         src="../../../assets/icones/segmento_produto.svg"
                         [title]="translatePipe.transform('gerenciamento_categorias_cardapios')">
                  </a>
                  <a *ngIf="this.user.accesses.menus"
                     class="botoes"
                     (click)="redirecionaOpcionais(marca._id)">
                    <img class="imgIcones"
                         src="../../../assets/icones/opcoes.svg"
                         [title]="translatePipe.transform('opcionais_cardapios')">
                  </a>
                  <a *ngIf="this.user.accesses.menus"
                     class="botoes"
                     (click)="redirecionaCardapios(marca._id)">
                    <img class="imgIcones"
                         src="../../../assets/icones/produtos.svg"
                         [title]="translatePipe.transform('botao_cardapio')">
                  </a>
                  <a *ngIf="this.user.accesses.special_offers"
                     class="botoes"
                     (click)="redirecionaPromocoes(marca._id)">
                    <img class="imgIcones"
                         src="../../../assets/icones/food.svg"
                         [title]="translatePipe.transform('promocoes')">
                  </a>
                  <a *ngIf="this.user.accesses.restaurants"
                     class="botoes"
                     (click)="redirecionaUnidades(marca._id)">
                    <img class="imgIcones"
                         src="../../../assets/icones/unidade.svg"
                         [title]="translatePipe.transform('botao_unidades')">
                  </a>
                  <a *ngIf="this.user.accesses.stores_administrator"
                     class="botoes"
                     (click)="redirecionaCupons(marca._id)">
                    <img class="imgIcones"
                         src="../../../assets/icones/cupons.svg"
                         [title]="translatePipe.transform('botao_cupons')">
                  </a>
                  <a *ngIf="this.user.accesses.market_place_administrator || this.user.accesses.account_manager"
                     class="botoes"
                     (click)="redirecionaDestaques(marca._id)">
                    <img class="imgIcones"
                         src="../../../assets/icones/destaque-aplicativo.svg"
                         [title]="translatePipe.transform('botao_cupons')">
                  </a>
                  <a class="botoes"
                     *ngIf="this.user.accesses.market_place_administrator || this.user.accesses.account_manager"
                     (click)="excluirMarca(marca._id)">
                    <img class="imgIcones"
                         src="../../../assets/icones/excluir.svg"
                         [title]="translatePipe.transform('texto_excluir')">
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
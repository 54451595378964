<app-toolbar></app-toolbar>

<div class="container">
  <form class="col s12"
        [formGroup]="cupomForm"
        novalidate
        (ngSubmit)="onSubmit(cupomForm)">


    <h5>Identificação</h5>
    <br>
    <div class="row">
      <div class="input-field col s1">
        <input id="codigo"
               type="text"
               formControlName="codigo">
        <label for="codigo"
               [class.active]="modoEdicao">{{'gerenciamento_cupons_codigo' | translate}}</label>
        <span class="error"
              *ngIf="cupomForm.controls['codigo'].invalid && 
          (cupomForm.controls['codigo'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>
      <div class="input-field col s3">
        <input id="nome"
               type="text"
               formControlName="nome">
        <label for="nome"
               [class.active]="modoEdicao">{{'gerenciamento_cupons_nome' | translate}}</label>
        <span class="error"
              *ngIf="cupomForm.controls['nome'].invalid && 
          (cupomForm.controls['nome'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>
      <div class="input-field col s2">
        <input id="quantidade"
               type="number"
               formControlName="quantidade">
        <label for="quantidade"
               [class.active]="modoEdicao">{{'gerenciamento_cupons_quantidade' | translate}}</label>
        <span class="error"
              *ngIf="cupomForm.controls['quantidade'].invalid && 
          (cupomForm.controls['quantidade'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>
      <div class="input-field col s2">
        <input id="vigencia"
               type="date"
               formControlName="vigencia">
        <label for="vigencia"
               [class.active]="modoEdicao">{{'gerenciamento_cupons_vigencia' | translate}}</label>
        <span class="error"
              *ngIf="cupomForm.controls['vigencia'].invalid && 
          (cupomForm.controls['vigencia'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>
      <div class="input-field col s2">
        <input id="validade"
               type="date"
               formControlName="validade">
        <label for="validade"
               [class.active]="modoEdicao">{{'gerenciamento_cupons_validade' | translate}}</label>
        <span class="error"
              *ngIf="cupomForm.controls['validade'].invalid && 
          (cupomForm.controls['validade'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>

      <div class="input-field col s2">
        <span class="labelSelect"
              style="margin-left: 0; margin-right: 10px;display: block; margin-top: -20px;">
          {{'gerenciamento_cupons_entrega_manual' | translate}}
          <div style="margin-top: 13px;">
            <label>
              <input type="checkbox"
                     formControlName="entregaManual" />
              <span></span>
            </label>
          </div>
        </span>
      </div>
    </div>

    <h5>Benefícios</h5>
    <br>

    <div class="row">
      <div class="input-field col s2">
        <input id="valor"
               type="number"
               formControlName="valor">
        <label for="valor"
               [class.active]="modoEdicao">{{'gerenciamento_cupons_valor' | translate}}</label>
        <span class="error"
              *ngIf="cupomForm.controls['valor'].invalid && 
          (cupomForm.controls['valor'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>

      <div class="input-field col s2">
        <select material-select
                id="tipoValor"
                formControlName="tipoValor">
          <option value=""
                  disabled
                  selected>{{'texto_selecione' | translate}}</option>
          <option value="%">{{'gerenciamento_cupons_tipo_valor_percentual' | translate}}</option>
          <option value="R$">{{'gerenciamento_cupons_tipo_valor_financeiro' | translate}}</option>
        </select>
        <span class="error"
              *ngIf="cupomForm.controls['tipoValor'].invalid && (cupomForm.controls['tipoValor'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>
      <div class="input-field col s2">
        <span class="labelSelect"
              style="margin-left: 0; margin-top: -20px;margin-right: 10px;display: block;">
          {{'gerenciamento_cupons_frete_gratis' | translate}}
          <div style="margin-top: 13px;">
            <label>
              <input type="checkbox"
                     formControlName="freteGratis" />
              <span></span>
            </label>
          </div>
        </span>
      </div>

      <div class="input-field col s3">
        <span class="labelSelect"
              style="margin-left: 0; margin-top: -20px;margin-right: 10px;display: block;">
          {{'gerenciamento_cupons_taxa_cliente_gratis' | translate}}
          <div style="margin-top: 13px;">
            <label>
              <input type="checkbox"
                     formControlName="taxaClienteGratis" />
              <span></span>
            </label>
          </div>
        </span>
      </div>
    </div>

    <h5>Regras</h5>
    <br>

    <div class="row">

      <div class="input-field col s1">
        <input id="limitePeriodo"
               type="number"
               formControlName="limitePeriodo">
        <label for="limitePeriodo"
               [class.active]="modoEdicao">{{'gerenciamento_cupons_limite_periodo' | translate}}</label>
        <span class="error"
              *ngIf="cupomForm.controls['limitePeriodo'].invalid && 
          (cupomForm.controls['limitePeriodo'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>

      <div class="input-field col s2">
        <select material-select
                id="limitePeriodoTipo"
                formControlName="limitePeriodoTipo">
          <option value=""
                  disabled
                  selected>{{'texto_selecione' | translate}}</option>
          <option value="day">{{'gerenciamento_cupons_tipo_periodo_dias' | translate}}</option>
          <option value="month">{{'gerenciamento_cupons_tipo_periodo_meses' | translate}}</option>
        </select>
        <span class="error"
              *ngIf="cupomForm.controls['limitePeriodoTipo'].invalid && (cupomForm.controls['limitePeriodoTipo'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>

      <div class="input-field col s2">
        <input id="valorMinimo"
               type="number"
               formControlName="valorMinimo">
        <label for="valorMinimo"
               [class.active]="modoEdicao">{{'gerenciamento_cupons_valor_minimo' | translate}}</label>
        <span class="error"
              *ngIf="cupomForm.controls['valorMinimo'].invalid && 
          (cupomForm.controls['valorMinimo'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>
      <div class="input-field col s2">
        <input id="primeirasCompras"
               type="number"
               formControlName="primeirasCompras">
        <label for="codigo"
               [class.active]="modoEdicao">{{'gerenciamento_cupons_primeiras_compras' | translate}}</label>
        <span class="error"
              *ngIf="cupomForm.controls['primeirasCompras'].invalid && 
          (cupomForm.controls['primeirasCompras'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>
      <div class="input-field col s3">
        <input id="ultimaCompra"
               type="number"
               formControlName="ultimaCompra">
        <label for="ultimaCompra"
               [class.active]="modoEdicao">{{'gerenciamento_cupons_ultima_compra' | translate}}</label>
        <span class="error"
              *ngIf="cupomForm.controls['ultimaCompra'].invalid && 
          (cupomForm.controls['ultimaCompra'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>
      <div class="input-field col s2">
        <input id="indicarUsuarios"
               type="number"
               formControlName="indicarUsuarios">
        <label for="indicarUsuarios"
               [class.active]="modoEdicao">{{'gerenciamento_cupons_indicar_usuarios' | translate}}</label>
        <span class="error"
              *ngIf="cupomForm.controls['indicarUsuarios'].invalid && 
          (cupomForm.controls['indicarUsuarios'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>
    </div>

    <div class="row">
      <div class="input-field col s3">
        <input id="distanciaEndereco"
               type="number"
               formControlName="distanciaEndereco">
        <label for="codigo"
               [class.active]="modoEdicao">{{'gerenciamento_cupons_distancia_endereco' | translate}}</label>
        <span class="error"
              *ngIf="cupomForm.controls['distanciaEndereco'].invalid && 
          (cupomForm.controls['distanciaEndereco'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>

      <div class="input-field col s3">
        <input id="zipCode"
               type="text"
               (blur)="buscarCEP()"
               formControlName="zipCode">
        <label for="zipCode"
               [class.active]="modoEdicao">{{'campo_zip' | translate}}</label>
        <span class="error"
              *ngIf="cupomForm.controls['zipCode'].invalid && 
          (cupomForm.controls['zipCode'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>

      <div class="input-field col s4">
        <input id="rua"
               type="text"
               disabled
               formControlName="rua">
        <label for="rua"
               [class.active]="modoEdicao">{{'campo_endereco' | translate}}</label>
        <span class="error"
              *ngIf="cupomForm.controls['rua'].invalid && 
          (cupomForm.controls['rua'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>

      <div class="input-field col s2">
        <input id="numero"
               type="text"
               formControlName="numero">
        <label for="numero"
               [class.active]="modoEdicao">{{'campo_endereco_numero' | translate}}</label>
        <span class="error"
              *ngIf="cupomForm.controls['numero'].invalid && 
          (cupomForm.controls['numero'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>
    </div>

    <h5 *ngIf="user.role === 'market_place_administrator'">Lojas</h5>
    <br *ngIf="user.role === 'market_place_administrator'">

    <div *ngIf="user.role === 'market_place_administrator'"
         class="row">
      <div class="form-group">
        <div formArrayName="marcas"
             class="marcas">
          <label class="labelSelect">{{'titulo_marca' | translate}}</label>

          <div *ngFor="let marca of marcas.controls; let i=index"
               [formGroup]="marca"
               style="margin-top: 20px;"
               class="marca">

            <div class="input-field dropMarca col s12"
                 style="margin-top: -30px;">
              <div class="input-field inline col s3">
                <select material-select
                        id="nomeMarca"
                        formControlName="campoMarca">
                  <option value=""
                          selected>{{'texto_selecione' | translate}}</option>
                  <option *ngFor="let marcaCombo of marcasCombo"
                          [ngValue]="marcaCombo._id">{{ marcaCombo.name }}</option>
                </select>
              </div>
              <div class="input-field col s2">
                <input id="quantidadeMarca"
                       type="number"
                       formControlName="quantidadeMarca">
                <label for="quantidadeMarca"
                       [class.active]="modoEdicao">{{'gerenciamento_cupons_quantidade' | translate}}</label>
              </div>


              <div class="input-field col s3">
                <input id="limitePeriodoMarca"
                       type="number"
                       formControlName="limitePeriodoMarca">
                <label for="limitePeriodoMarca"
                       [class.active]="modoEdicao">{{'gerenciamento_cupons_limite_periodo' | translate}}</label>
              </div>

              <div class="input-field col s2">
                <select material-select
                        id="limitePeriodoTipoMarca"
                        formControlName="limitePeriodoTipoMarca">
                  <option value=""
                          disabled
                          selected>{{'texto_selecione' | translate}}</option>
                  <option value="day">{{'gerenciamento_cupons_tipo_periodo_dias' | translate}}</option>
                  <option value="month">{{'gerenciamento_cupons_tipo_periodo_meses' | translate}}</option>
                </select>
              </div>



              <a *ngIf="i === marcas.length - 1"
                 class="btn-floating"
                 (click)="incluirMarcas()"
                 data-toggle="tooltip"
                 data-placement="bottom"
                 title="{{'texto_adicionar' | translate}}">
                <i class="material-icons">add</i>
              </a>
              <a *ngIf="i > 0"
                 class="btn-floating btn-remove"
                 (click)="removerMarcas(i)"
                 data-toggle="tooltip"
                 data-placement="bottom"
                 title="{{'texto_excluir' | translate}}">
                <i class="material-icons">cancel</i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row center">
      <button class="btn btnCancelar waves-effect"
              id="cancelar"
              (click)="voltar()"
              type="button">
        {{'texto_cancelar' | translate}}
      </button>

      <button class="btn btnSalvar waves-effect"
              id="salvar"
              type="submit">
        {{'texto_salvar' | translate}}
      </button>
    </div>
  </form>
</div>
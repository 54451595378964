import { TokenService } from '../../services/token.service';
import { AuthService } from '../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslationService } from '../../services/translation.service';
import { TranslatePipe } from '../../pipes/translate.pipe';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  isSubmitted = false;
  loginForm: FormGroup;
  errorMessage: string;
  showSpinner = false;
  user: any;
  // = this.tokenService.GetPayLoad();

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private tokenService: TokenService,
    private translationService: TranslationService,
    private translatePipe: TranslatePipe
  ) { }

  ngOnInit() {
    this.init();
  }

  init() {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required, Validators.minLength(5)]],
      password: ['', [Validators.required, Validators.minLength(8)]]
    });

  }

  loginUser() {
    this.isSubmitted = true;
    if (this.loginForm.valid) {
      this.showSpinner = true;
      this.authService.loginUser(this.loginForm.value).subscribe(
        data => {
          this.tokenService.SetToken(data.token);
          window.localStorage.setItem('language', this.translationService.language);
          this.user = this.tokenService.GetPayLoad();
          this.loginForm.reset();
          this.router.navigate(['admin/home']);
        },
        err => {
          this.showSpinner = false;
          if (err.error.message) {
            this.errorMessage = err.error.message;
          }
        }
      );
    } else {
      this.loginForm.markAsTouched();
    }
  }

  direcionarEsqueceuSenha() {
    this.router.navigate(['esqueceu-senha']);
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  constructor() { }

  SetToken(token) {
    localStorage.setItem('tokenMappe', "Bearer " + token);
  }

  GetToken() {
    return localStorage.getItem('tokenMappe');
  }

  DeleteToken() {
    localStorage.removeItem('tokenMappe');
  }

  GetPayLoad() {
    const token = this.GetToken();
    let payload;
    if (token) {
      payload = token.split('.')[1];
      payload = JSON.parse(window.atob(payload));
    }

    return payload.data;
  }
  
  DeleteTimerChat() {
    localStorage.removeItem('timerChatMappe');
  }

  SetTimerChat() {
    localStorage.setItem('timerChatMappe', new Date().toISOString());
  }

  GetTimerChat() {
    return localStorage.getItem('timerChatMappe');
  }

    
  DeleteTimerNovosPedidos() {
    localStorage.removeItem('timerNovosPedidosMappe');
  }

  SetTimerNovosPedidos() {
    localStorage.setItem('timerNovosPedidosMappe', new Date().toISOString());
  }

  GetTimerNovosPedidos() {
    return localStorage.getItem('timerNovosPedidosMappe');
  }
}

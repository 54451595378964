<app-toolbar></app-toolbar>
<div class="container">
  <div class="col s12">
    <div class="row">
      <div class="input-field col s4">
        <span class="labelSelect"
              style="margin-left: 0;margin-bottom: 3px;margin-right: 10px;display: block;">
          {{'gerenciamento_plataforma_home_brands' | translate}}
          <div style="margin-top: 13px;">
            <label>
              <input type="checkbox"
                     [(ngModel)]="pesquisaLojas" />
              <span></span>
            </label>
          </div>
        </span>
      </div>

      <div class="input-field col s4">
        <span class="labelSelect"
              style="margin-left: 0;margin-bottom: 3px;margin-right: 10px;display: block;">
          {{'gerenciamento_plataforma_home_tickets' | translate}}
          <div style="margin-top: 13px;">
            <label>
              <input type="checkbox"
                     [(ngModel)]="pesquisaIngressos" />
              <span></span>
            </label>
          </div>
        </span>
      </div>

      <div class="input-field col s4">
        <span class="labelSelect"
              style="margin-left: 0;margin-bottom: 3px;margin-right: 10px;display: block;">
          {{'gerenciamento_plataforma_segmento_superior' | translate}}
          <div style="margin-top: 13px;">
            <label>
              <input type="checkbox"
                     [(ngModel)]="segmentosuperior" />
              <span></span>
            </label>
          </div>
        </span>
      </div>
    </div>
  </div>

  <div class="col s12">
    <div class="row">
      <div class="input-field col s3">
        <span class="labelSelect"
              style="margin-left: 0;margin-bottom: 3px;margin-right: 10px;display: block;">
          {{'gerenciamento_plataforma_endereco' | translate}}
          <div style="margin-top: 13px;">
            <label>
              <input type="checkbox"
                     [(ngModel)]="endereco" />
              <span></span>
            </label>
          </div>
        </span>
      </div>
      <div class="input-field col s3">
        <span class="labelSelect"
              style="margin-left: 0;margin-bottom: 3px;margin-right: 10px;display: block;">
          {{'gerenciamento_plataforma_codigo_postal' | translate}}
          <div style="margin-top: 13px;">
            <label>
              <input type="checkbox"
                     [(ngModel)]="codigopostal" />
              <span></span>
            </label>
          </div>
        </span>
      </div>
      <div class="input-field col s4">
        <span class="labelSelect"
              style="margin-left: 0;margin-bottom: 3px;margin-right: 10px;display: block;">
          {{'gerenciamento_plataforma_telefone_obrigatorio' | translate}}
          <div style="margin-top: 13px;">
            <label>
              <input type="checkbox"
                     [(ngModel)]="telefoneObrigatorio" />
              <span></span>
            </label>
          </div>
        </span>
      </div>
    </div>
  </div>

  <h6 class="label-title">{{ 'gerenciamento_plataforma_documentacao' | translate }}</h6>

  <div class="row"
       style="padding-bottom: 20px;">
    <div class="input-field col s4">
      <label class="check-task custom-control custom-checkbox d-flex"
             style="margin-right: 20px">
        <input type="checkbox"
               class="input-field input-center col s3"
               id="cnpj"
               (click)="atualizaDocumentacao('cnpj')"
               [checked]="cnpj"
               [(ngModel)]="cnpj" />
        <span class="custom-control-label texto-sm-check">{{'gerenciamento_plataforma_cnpjcpf' | translate}}</span>
      </label>
    </div>
    <div class="input-field col s4">
      <label class="check-task custom-control custom-checkbox d-flex"
             style="margin-right: 20px">
        <input type="checkbox"
               class="input-field input-center col s3"
               id="nif"
               (click)="atualizaDocumentacao('nif')"
               [checked]="nif"
               [(ngModel)]="nif" />
        <span class="custom-control-label texto-sm-check">{{'gerenciamento_plataforma_nif' | translate}}</span>
      </label>
    </div>
  </div>

  <div class="col s12">
    <div class="row">
      <div class="input-field col s12">
        <h6 class="label-title">{{ 'gerenciamento_plataforma_codigo_indicacao' | translate }}</h6>
        <input id="mensagemCompartilhamento"
               type="text"
               [(ngModel)]="mensagemCompartilhamento">
      </div>
    </div>
  </div>

  <div class="col s12">
    <div class="row">
      <div class="input-field col s4">
        <h6 class="label-title">Instagram</h6>
        <input id="instagram"
               type="text"
               [(ngModel)]="instagram">
      </div>
      <div class="input-field col s4">
        <h6 class="label-title">Facebook</h6>
        <input id="facebook"
               type="text"
               [(ngModel)]="facebook">
      </div>
      <div class="input-field col s4">
        <h6 class="label-title">WhatsApp</h6>
        <input id="whatsapp"
               type="text"
               [(ngModel)]="whatsapp">
      </div>
    </div>
  </div>

  <div class="col s12">
    <div class="row">
      <div class="input-field col s5">
        <h6 class="label-title">E-mail</h6>
        <input id="email"
               type="text"
               [(ngModel)]="email">
      </div>
      <div class="input-field col s7">
        <h6 class="label-title">Site</h6>
        <input id="site"
               type="text"
               [(ngModel)]="site">
      </div>
    </div>
  </div>


  <h6 class="title-category">{{ 'gerenciamento_plataforma_popup_cliente' | translate }}</h6>
  <h6 class="sub-title-category">{{ 'gerenciamento_plataforma_descricao' | translate }}</h6>

  <div class="center">
    <image-helper (convertedImage)="getImage($event,'cliente')"
                  [croppedImage]="imageClienteUrl"
                  [aspectRatio]="0.75"></image-helper>
  </div>

  <div *ngIf="imageClienteUrl"
       class="row center"
       style="padding-bottom: 10px;">
    <button class="btn-small excluir-imagens waves-effect"
            id="excluir_cliente"
            (click)="excluirCliente()"
            type="button">
      {{'texto_excluir' | translate}}
    </button>

  </div>

  <h6 class="title-category">{{ 'gerenciamento_plataforma_popup_entregador' | translate }}</h6>
  <h6 class="sub-title-category">{{ 'gerenciamento_plataforma_descricao' | translate }}</h6>

  <div class="center">
    <image-helper (convertedImage)="getImage($event,'entregador')"
                  [croppedImage]="imageEntregadorUrl"
                  [aspectRatio]="0.75"></image-helper>
  </div>

  <div *ngIf="imageEntregadorUrl"
       class="row center"
       style="padding-bottom: 10px;">
    <button class="btn-small excluir-imagens waves-effect"
            id="excluir_entregador"
            (click)="excluirEntregador()"
            type="button">
      {{'texto_excluir' | translate}}
    </button>

  </div>



  <div class="row center"
       style="padding-bottom: 10px;">
    <button class="btn btnCancelar waves-effect"
            id="cancelar"
            (click)="cancelar()"
            type="button">
      {{'texto_cancelar' | translate}}
    </button>

    <button class="btn btnSalvar waves-effect"
            id="salvar"
            (click)="salvarConfiguracoes()"
            type="button">
      {{'texto_salvar' | translate}}
    </button>
  </div>


</div>
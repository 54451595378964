<app-toolbar></app-toolbar>

<div style="justify-content: center;align-items: center;display: flex;width: 100%;padding-left: 18px;">
  <div class="col s12 m12 l12 card-block">
    <h5 class="title">{{'cadastro_combo' | translate}}</h5>
  </div>
</div>

<div class="container">
  <div class="card-panel">
    <form class="col s6"
          [formGroup]="comboForm"
          novalidate
          (ngSubmit)="onSubmit(comboForm)">
      <div class="container">
        <div class="row">
          <div class="input-field col s8"
               style="margin-top: -10px;">
            <label class="labelSelect"
                   for="itemCombo">{{'campo_item' | translate}}</label>
            <input id="itemCombo"
                   type="text"
                   formControlName="itemCombo"
                   style="width: 94%;">
            <span class="error"
                  *ngIf="comboForm.controls['itemCombo'].invalid && 
            (comboForm.controls['itemCombo'].touched || isSubmitted)">
              {{'texto_obrigatorio' | translate}}
            </span>
          </div>

          <div class="input-field col s3"
               style="display: flex;flex-direction: column;margin-top: -11px;">
            <span class="labelSelect"
                  style="margin-left: 0;margin-bottom: 3px;margin-right: 10px;display: block;">
              {{'opcionais_cardapios_vincular_grupo' | translate}}
              <div style="margin-top: 13px;">
                <label>
                  <input type="checkbox"
                         (change)="reinit()"
                         formControlName="grupoCheck" />
                  <span></span>
                </label>
              </div>
            </span>
          </div>
        </div>



        <div *ngIf="comboForm.controls['grupoCheck'].value === false"
             class="row"
             style="margin-left: 0.1rem;">
          <div class="form-group right">
            <div formArrayName="options"
                 class="options">
              <label class="labelSelect"
                     style="margin-left: 0rem;">{{'campo_opcao' | translate}}</label>

              <div *ngFor="let option of options.controls; let i=index"
                   [formGroup]="option"
                   style="margin-top: -1rem;">

                <div class="input-field inline"
                     style="width: 30rem;">
                  <input id="name"
                         type="text"
                         formControlName="name">
                </div>
                <a *ngIf="i === options.length - 1"
                   class="btn-floating"
                   (click)="incluirOptions()"
                   data-toggle="tooltip"
                   data-placement="bottom"
                   title="{{'texto_adicionar' | translate}}"
                   style="margin-left: 10px !important;">
                  <i class="material-icons">add</i>
                </a>

                <a *ngIf="i > 1"
                   class="btn-floating btn-remove"
                   (click)="removeroptions(i)"
                   data-toggle="tooltip"
                   data-placement="bottom"
                   title="{{'texto_excluir' | translate}}">
                  <i class="material-icons">cancel</i>
                </a>
                <br />
                <span class="error"
                      style="
                margin-left: 5px;
                margin-top: -2rem;
                display: flex;
                "
                      *ngIf="options.controls[i].controls.name.invalid &&
                (options.controls[i].controls.name.touched || isSubmitted)">
                  {{'texto_obrigatorio' | translate}}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="comboForm.controls['grupoCheck'].value === true"
             class="row center">
          <label class="labelSelect"
                 [class.active]="modoEdicao">{{'opcionais_cardapios_grupo' | translate}}</label> <br />
          <div class="input-field col s6"
               style="
                flex-direction: column;
                display: flex;
                justify-content: start;
                align-items: start;
                margin-left: -83px;
                margin-top: 2rem;
                ">
            <select material-select
                    id="grupo"
                    formControlName="grupo">
              <option value=""
                      disabled
                      selected>{{'texto_selecione' | translate}}</option>
              <option *ngFor="let grupo of gruposCombo"
                      [ngValue]="grupo">{{ grupo }}</option>
            </select>

            <span class="error"
                  style="margin-top: 0.5rem;"
                  *ngIf="comboForm.controls['grupo'].invalid && 
                  (comboForm.controls['grupo'].touched || isSubmitted)">
              Campo obrigatório
            </span>
          </div>
        </div>

      </div>
    </form>
  </div>

  <div class="row">
    <button *ngIf="!modoEdicao"
            class="btn btnSalvar waves-effect right"
            id="salvar"
            type="button"
            (click)="onSubmit(comboForm)"
            [disabled]="uploadImage">
      {{'texto_salvar' | translate}}
    </button>

    <button *ngIf="modoEdicao"
            class="btn btnSalvar waves-effect right"
            id="salvar"
            type="button"
            (click)="onSubmitEdit(comboForm)">
      {{'texto_salvar' | translate}}
    </button>

    <button *ngIf="modoEdicao"
            class="btn btnCancelar right"
            id="cancelar"
            (click)="cancelar()"
            type="button">
      {{'texto_cancelar' | translate}}
    </button>

  </div>

  <table style="margin-top: 5rem;">
    <thead>
      <tr>
        <th style="width: 30%;">{{'grid_itens' | translate}}</th>
        <th style="width: 30%;">{{'opcionais_cardapios_vincular_grupo' | translate}}</th>
        <th style="text-align: center;">{{'texto_acao' | translate}}</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let item of itemsCombo">
        <td style="width:30%;">{{item.category}}</td>
        <td style="width:30%;">{{item.group}}</td>
        <td style="width:10%; text-align: center;">
          <a class="btn-floating btn-edit"
             (click)="editarItemCombo(item._id)"
             data-toggle="tooltip"
             data-placement="bottom"
             title="{{'texto_editar' | translate}}">
            <i class="material-icons"
               style="color: orange;">create</i>
          </a>

          <a class="btn-floating btn-remove"
             (click)="deletarItemCombo(item._id)"
             data-toggle="tooltip"
             data-placement="bottom"
             title="{{'texto_excluir' | translate}}">
            <i class="material-icons">cancel</i>
          </a>

        </td>
      </tr>

    </tbody>
  </table>

  <div class="row center"
       style="margin-top: 3rem;">
    <button class="btn btnVoltar"
            id="voltar"
            (click)="voltar()"
            type="button">
      {{'texto_voltar' | translate}}
    </button>
  </div>
</div>
<app-toolbar></app-toolbar>
<div class="row"
     style="text-align: right; margin-bottom: -30px;">
  <a (click)="redirecionarAdicionar()">
    <img class="imgAdicionar"
         src="../../../assets/icones/adicionar.svg">
  </a>
</div>

<div *ngFor="let situcao of situacoes">
  <h6 class="title-category">{{ situcao | translate }}</h6>
  <div class="row"
       style="justify-content: center;align-items: center;display: flex;">
    <div class="col s12 m12 l12 card-block">
      <div class="row">
        <div class="col cardDiv"
             *ngFor="let cupom of cuponsFiltros(situcao)">
          <div class="card">
            <div class="linha">
              <div class="imgDiv">
                <span class="card-title titulo-codigo">
                  {{cupom.code}}
                </span>
              </div>
              <div>
                <div class="div-dados">
                  <span class="card-title">
                    {{cupom.name}}
                  </span>
                  <br>
                  <span class="titleItalic">
                    {{cupom.start_date.toString().substr(0, 10) | date:'dd/MM/yyyy'}} - {{cupom.end_date.toString().substr(0, 10) | date:'dd/MM/yyyy'}}
                  </span>
                </div>
                <div class="divBotoes">
                  <a class="botoes"
                     (click)="editar(cupom._id)">
                    <img class="imgIcones"
                         src="../../../assets/icones/editar.svg"
                         [title]="translatePipe.transform('texto_editar')">
                  </a>
                  <a class="botoes"
                     (click)="excluir(cupom._id)">
                    <img class="imgIcones"
                         src="../../../assets/icones/excluir.svg"
                         [title]="translatePipe.transform('texto_excluir')">
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
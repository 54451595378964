<app-toolbars></app-toolbars>
<div class="container ">
  <div class="row">
    <div class="col s12">
      <div class="card-content auth-content">
        <div id="login"
             class="login">
          <body>
            <div class="Principal">
              <div class="container containerModal">
                <div class="row">
                  <div class="col s10 offset-s1"
                       id="painel">
                    <img src="../../../../../assets/images/logopadrao.png"
                         style="width: 150px;"
                         alt="">
                    <form class="col s12"
                          [formGroup]="esqueceSenhaForm"
                          novalidate>
                      <div class="row"
                           style="margin-top: -10px; margin-bottom: 30px !important;">
                        <div class="input-field col s12">
                          <input id="user-name"
                                 type="text"
                                 formControlName="email">
                          <label for="user-name">{{ 'usuario_login_recuperar_senha' | translate2}}</label>
                          <span class="error"
                                *ngIf="(esqueceSenhaForm.controls['email'].invalid && 
            (esqueceSenhaForm.controls['email'].touched || isSubmitted) &&
            !esqueceSenhaForm.controls['email'].value)">
                            {{ 'texto_obrigatorio' | translate2}}
                          </span>
                          <span class="error"
                                *ngIf="esqueceSenhaForm.controls['email'].value && esqueceSenhaForm.controls['email'].value.length < 5">
                            {{ 'usuario_caracteres_min' | translate2}}
                          </span>
                        </div>
                      </div>


                      <div class="center">
                        <button class="btn waves-effect"
                                id="loginbtn"
                                (click)="solicitarToken()">
                          {{'texto_enviar' | translate2 }}
                        </button>

                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </body>
        </div>
      </div>
    </div>
  </div>
</div>
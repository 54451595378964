<app-toolbar></app-toolbar>

<div class="container">
  <div class="row center">
    <div class="input-field col s4">
      <input id="quantidade"
             type="text"
             [(ngModel)]="quantidade">
      <label for="quantidade"
             [class.active]="true">{{'texto_quantidade' | translate}}</label>
    </div>

    <div class="input-field col s1">
      <button class="btn-floating"
              id="salvar"
              type="submit"
              data-toggle="tooltip"
              data-placement="bottom"
              title="{{'texto_adicionar' | translate}}"
              (click)="salvar()">
        <i class="material-icons">add</i>
      </button>
    </div>
  </div>

  <div class="col s12 m12 l12">
    <div class="row">
      <table>
        <thead>
          <tr>
            <th>{{'campo_id' | translate}}</th>
            <th>{{'campo_situacao' | translate}}</th>
            <th>{{'pedido_numero' | translate}}</th>
            <th style="text-align: center;"></th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let item of estoque">
            <td>{{item._id}}</td>
            <td>{{item.status | translate}}</td>
            <td>{{item.order}}</td>
            <td style="width:10%; text-align: center;">
              <a *ngIf="!item.order"
                 class="btn-floating btn-remove"
                 (click)="excluirEstoqueDigital(item)"
                 data-toggle="tooltip"
                 data-placement="bottom"
                 title="{{'texto_excluir' | translate}}">
                <i class="material-icons">cancel</i>
              </a>
            </td>

          </tr>
        </tbody>
      </table>

    </div>

  </div>
  <div class="row center">
    <button class="btn btnVoltar"
            id="voltar"
            (click)="voltar()"
            type="button">
      {{'texto_voltar' | translate}}
    </button>

  </div>
</div>
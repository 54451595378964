import { PedidosService } from '../../services/pedidos.service';
import { MarcasService } from '../../services/marcas.service';
import { AuthService } from '../../services/auth.service';

import { TokenService } from '../../services/token.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslatePipe } from '../../pipes/translate.pipe';
import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  user = this.tokenService.GetPayLoad();

  pedidos30diasQuantidade = [];
  pedidos30diasValor = [];

  novos = 0;
  producao = 0;
  liberados = 0;
  enviados = 0;

  lojas = 0;
  unidades = 0;

  valor30dias = 0;

  constructor(
    private translatePipe: TranslatePipe,
    private tokenService: TokenService,
    private router: Router,
    private pedidosService: PedidosService,
    private marcasService: MarcasService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.obterDados();

    this.obterDadosGerenteLoja();

    this.obterDadosAdministradorMarketPlace();
  }


  obterDados() {
    let pedidos30diasQuantidadeTemp = [];
    let pedidos30diasValorTemp = [];

    this.valor30dias = 0;

    this.pedidosService.obterPedidosUltimos30Dias().subscribe(res => {
      res.map((dia) => {
        pedidos30diasQuantidadeTemp.push({ name: moment(dia.date).format("DD-MM"), value: dia.quantity });
        pedidos30diasValorTemp.push({ name: moment(dia.date).format("DD-MM"), value: dia.value });

        this.valor30dias = this.valor30dias + parseFloat(dia.value);
      });

      this.pedidos30diasQuantidade = pedidos30diasQuantidadeTemp;
      this.pedidos30diasValor = pedidos30diasValorTemp;
    });
  }

  obterDadosGerenteLoja() {

    this.novos = 0;
    this.producao = 0;
    this.liberados = 0;
    this.enviados = 0;

    this.pedidosService.obterPedidos().subscribe(res => {
      res.forEach(pedido => {
        switch (pedido.status) {
          case 'open':
            this.novos = this.novos + 1;
            break;
          case 'in_preparation':
            this.producao = this.producao + 1;
            break;
          case 'ready':
            this.liberados = this.liberados + 1;
            break;
          case 'delivering':
            this.enviados = this.enviados + 1;
            break;
        }
      });
    });

  }

  obterDadosAdministradorMarketPlace() {
    this.lojas = 0;
    this.unidades = 0;

    this.marcasService.listaMarcas().subscribe(res => {
      res.map((dados) => {

        if (dados.isActive === true) {
          this.lojas = this.lojas + 1;
          this.unidades = this.unidades + dados.restaurants_quantity;
        }
      })
    });
  }

  solicitarToken() {

    this.authService.sendNewPasswordToken({ email: this.user.email }).subscribe(
      data => {
        Swal.fire('Login', this.translatePipe.transform('email_enviado'), 'success').then(() => {
          this.tokenService.DeleteToken();
          this.router.navigate(['/']);
        });
      },
      err => {
        if (err.error.message) {
          Swal.fire('Login', this.translatePipe.transform(err.error.message), 'error');
        }
      }
    );
  }
}

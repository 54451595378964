import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EstoqueService {
  BASEURL: string;
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    this.BASEURL = environment.apiUrl;

    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tokenMappe'),
    });
  }

  listaItens(id: string): Observable<any> {
    return this.http.get(`${this.BASEURL}/stock/restaurant/${id}`, {
      headers: this.httpHeaders,
    });
  }

  salvaItens(body: any): Observable<any> {
    return this.http.post(`${this.BASEURL}/stock`, body, {
      headers: this.httpHeaders,
    });
  }

  obterItem(unidade: string, item: string): Observable<any> {
    return this.http.get(`${this.BASEURL}/stock/${unidade}/${item}`, {
      headers: this.httpHeaders,
    });
  }

  listaItensDigitais(unidade: string, item: string): Observable<any> {
    return this.http.get(`${this.BASEURL}/digital-stock/${unidade}/${item}`, {
      headers: this.httpHeaders,
    });
  }

  salvaItemDigital(body: any): Observable<any> {
    return this.http.post(`${this.BASEURL}/digital-stock`, body, {
      headers: this.httpHeaders,
    });
  }

  excluiItemDigital(id: string): Observable<any> {
    return this.http.delete(`${this.BASEURL}/digital-stock/${id}`, {
      headers: this.httpHeaders,
    });
  }

  listaItensIngressos(unidade: string, item: string): Observable<any> {
    return this.http.get(`${this.BASEURL}/ticket-stock/${unidade}/${item}`, {
      headers: this.httpHeaders,
    });
  }

  salvaItemIngressos(body: any): Observable<any> {
    return this.http.post(`${this.BASEURL}/ticket-stock`, body, {
      headers: this.httpHeaders,
    });
  }

  excluiItemIngressos(id: string): Observable<any> {
    return this.http.delete(`${this.BASEURL}/ticket-stock/${id}`, {
      headers: this.httpHeaders,
    });
  }

}

import { TokenService } from '../../services/token.service';
import { AuthService } from '../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslatePipe } from '../../pipes/translate.pipe';
import Swal from 'sweetalert2';

@Component({
  selector: 'esqueceu-senha',
  templateUrl: './esqueceu-senha.component.html',
  styleUrls: ['./esqueceu-senha.component.css']
})
export class EsqueceuSenhaComponent implements OnInit {
  isSubmitted = false;
  esqueceSenhaForm: FormGroup;
  showSpinner = false;
  user: any;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private tokenService: TokenService,
    private translatePipe: TranslatePipe
  ) { }

  ngOnInit() {
    this.init();
  }

  init() {
    this.esqueceSenhaForm = this.fb.group({
      email: ['', [Validators.required, Validators.minLength(5)]]
    });

  }

  solicitarToken() {
    this.isSubmitted = true;
    if (this.esqueceSenhaForm.valid) {
      this.showSpinner = true;


      this.authService.sendNewPasswordToken(this.esqueceSenhaForm.value).subscribe(
        data => {
          Swal.fire('Login', this.translatePipe.transform('email_enviado'), 'success').then(() => {
            this.tokenService.DeleteToken();
            this.router.navigate(['/']);
          });
        },
        err => {
          this.showSpinner = false;
          if (err.error.message) {
            Swal.fire('Login', this.translatePipe.transform(err.error.message), 'error');
          }
        }
      );
    } else {
      this.esqueceSenhaForm.markAsTouched();
    }
  }
}

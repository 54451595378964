import { PagamentoService } from './../../services/pagamentos.service';
import { TokenService } from '../../services/token.service';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { TranslatePipe } from '../../pipes/translate.pipe';

@Component({
  selector: 'app-pagamentos',
  templateUrl: './pagamentos.component.html',
  styleUrls: ['./pagamentos.component.css']
})
export class PagamentosComponent implements OnInit {
  pagamentos = [];
  url = null;
  user = this.tokenService.GetPayLoad();

  constructor(private translatePipe: TranslatePipe, private tokenService: TokenService, private pagamentoService: PagamentoService, private router: Router) { }

  ngOnInit() {
    this.listaPagamentos();
  }

  listaPagamentos() {
    this.pagamentoService.listaPagamentos().subscribe(res => {
      this.pagamentos = res;
    });
  }

  excluir(id: string) {
    Swal.fire({
      title: this.translatePipe.transform('deletar_titulos'),
      text: this.translatePipe.transform('excluir_pagamento'),
      showCancelButton: true,
      confirmButtonText: this.translatePipe.transform('deletar_sim'),
      cancelButtonText: this.translatePipe.transform('deletar_nao'),
    }).then(result => {
      if (result.value) {
        this.pagamentoService.excluirPagamento(id).subscribe((res: any) => {
          this.listaPagamentos();
        });
      }
    });
  }

  editar(id: string) {
    this.router.navigate([`admin/pagamentos/editar/${id}`]);
  }

}

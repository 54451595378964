<app-toolbars></app-toolbars>
<div class="container ">
  <div class="row">
    <div class="col s12">
      <div class="card-content auth-content">
        <div id="login"
             class="login">

          <body>
            <div class="Principal">
              <div class="container containerModal">
                <div class="row">
                  <div class="col s10 offset-s1"
                       id="painel">
                    <img src="../../../../../assets/images/logopadrao.png"
                         style="width: 150px;"
                         alt="">
                    <form class="col s12"
                          [formGroup]="alterarSenhaForm"
                          novalidate>

                      <div class="row">
                        <div class="input-field col s12">
                          <input id="password"
                                 type="password"
                                 formControlName="password">
                          <label for="password">{{ 'texto_nova_senha' | translate2}}</label>
                          <span class="error"
                                *ngIf="(alterarSenhaForm.controls['password'].invalid && (alterarSenhaForm.controls['password'].touched || isSubmitted) && !alterarSenhaForm.controls['password'].value)">
                            {{ 'texto_obrigatorio' | translate2}}
                          </span>
                          <span class="error"
                                *ngIf="alterarSenhaForm.controls['password'].value && alterarSenhaForm.controls['password'].value.length < 8">
                            {{ 'senha_caracteres_min' | translate2}}
                          </span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="input-field col s12">
                          <input id="passwordconfirm"
                                 type="password"
                                 formControlName="passwordconfirm">
                          <label for="password">{{ 'texto_confirmar_senha' | translate2}}</label>
                          <span class="error"
                                *ngIf="(alterarSenhaForm.controls['passwordconfirm'].invalid &&  (alterarSenhaForm.controls['passwordconfirm'].touched || isSubmitted) && !alterarSenhaForm.controls['passwordconfirm'].value)">
                            {{ 'texto_obrigatorio' | translate2}}
                          </span>
                          <span class="error"
                                *ngIf="alterarSenhaForm.controls['passwordconfirm'].value && alterarSenhaForm.controls['passwordconfirm'].value.length < 8">
                            {{ 'senha_caracteres_min' | translate2}}
                          </span>
                          <span class="error"
                                *ngIf="alterarSenhaForm.controls['passwordconfirm'].value && alterarSenhaForm.controls['passwordconfirm'].value.length >= 8 && alterarSenhaForm.controls['passwordconfirm'].value !== alterarSenhaForm.controls['password'].value">
                            {{ 'senha_diferentes' | translate2}}
                          </span>
                        </div>
                      </div>
                      <div class="center">
                        <button class="btn waves-effect"
                                id="loginbtn"
                                (click)="gravarSenha()">
                          {{'entrar' | translate2 }}
                        </button>

                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </body>
        </div>
      </div>
    </div>
  </div>
</div>
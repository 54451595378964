import { CombosService } from './../../services/combos.service';
import { TokenService } from '../../services/token.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { TranslatePipe } from '../../pipes/translate.pipe';
@Component({
  selector: 'app-combo',
  templateUrl: './combo.component.html',
  styleUrls: ['./combo.component.css']
})
export class ComboComponent implements OnInit {
  isSubmitted = false;
  url = null;
  comboForm: FormGroup;
  idMarca: any;
  idProduto: any;
  itemsCombo = [];
  gruposCombo: any;
  modoEdicao = false;
  idItem: any;
  user = this.tokenService.GetPayLoad();

  constructor(
    private tokenService: TokenService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private comboService: CombosService,
    private translatePipe: TranslatePipe
  ) { }

  ngOnInit() {
    this.init();
    this.route.paramMap.subscribe(params => {
      this.idMarca = params.get('idMarca');
      this.idProduto = params.get('idProduto');
    });

    this.obterGrupos();

    this.incluirOptions();
    this.incluirOptions();
    this.listaComboProduto();
  }

  init() {
    this.comboForm = this.fb.group({
      itemCombo: ['', Validators.required],
      grupoCheck: [false],
      grupo: [''],
      options: this.fb.array([])
    });
  }

  reinit() {

    if (this.comboForm.value.grupoCheck === true) {
      this.comboForm = this.fb.group({
        itemCombo: [this.comboForm.value.itemCombo, Validators.required],
        grupoCheck: [this.comboForm.value.grupoCheck],
        grupo: [this.comboForm.value.grupo, (this.comboForm.value.grupoCheck === true ? Validators.required : Validators.nullValidator)],
        options: this.fb.array([])
      });
    }
    else {
      this.comboForm = this.fb.group({
        itemCombo: [this.comboForm.value.itemCombo, Validators.required],
        grupoCheck: [this.comboForm.value.grupoCheck],
        grupo: [this.comboForm.value.grupo, (this.comboForm.value.grupoCheck === true ? Validators.required : Validators.nullValidator)],
        options: this.fb.array([])
      })

      this.incluirOptions();
      this.incluirOptions();
    }
  }

  obterGrupos() {
    this.comboService.listaGrupos(this.idMarca).subscribe(res => {
      this.gruposCombo = res;
    });
  }

  listaComboProduto() {
    this.comboService.listaComboProduto(this.idProduto).subscribe(res => {
      this.itemsCombo = res;
    });
  }

  get options() {
    return this.comboForm.get('options') as FormArray;
  }

  incluirOptions() {
    const option = this.fb.group({
      name: ['', Validators.required]
    });

    this.options.push(option);
  }

  removeroptions(index: number) {
    this.options.removeAt(index);
  }

  onSubmit(form: FormGroup) {
    this.isSubmitted = true;
    this.comboForm.updateValueAndValidity();

    if (form.valid) {
      this.salvar({
        item_id: this.idProduto,
        category: this.comboForm.value.itemCombo,
        group: (this.comboForm.value.grupo === '' ? undefined : this.comboForm.value.grupo),
        options: this.options
      });
    }
  }

  onSubmitEdit(form: FormGroup) {
    this.comboForm.updateValueAndValidity();
    if (form.valid) {
      this.salvar({
        item_id: this.idProduto,
        category: this.comboForm.value.itemCombo,
        group: (this.comboForm.value.grupo === '' ? undefined : this.comboForm.value.grupo),
        options: this.options
      });
    }
  }

  salvar(comboForm) {
    if (this.modoEdicao) {
      const items = [];
      this.options.value.forEach(item => {
        items.push({ name: item.name });
      });

      comboForm.options = (items.length > 0 ? items : undefined);

      this.comboService.editarItemCombo(this.idItem, comboForm).subscribe(
        res => {
          Swal.fire('Opções', this.translatePipe.transform('mensagem_editar_combo'), 'success').then(() => {
            this.listaComboProduto();

            this.comboForm.controls.itemCombo.markAsUntouched();
            this.comboForm.controls.itemCombo.setValue(null);

            this.comboForm.controls.grupo.markAsUntouched();
            this.comboForm.controls.grupo.setValue('');

            this.isSubmitted = false;
            this.modoEdicao = false;

            this.reinit();
          });
        },
        error => {
          Swal.fire('Opções', error.error.message, 'error');
        }
      );
    } else {
      const items = [];
      this.options.value.forEach(item => {
        items.push({ name: item.name });
      });
      comboForm.options = (items.length > 0 ? items : undefined);
      this.comboService.salvaCombo(comboForm).subscribe(
        res => {
          Swal.fire('Opções', this.translatePipe.transform('mensagem_combo'), 'success').then(() => {
            this.listaComboProduto();

            this.comboForm.controls.itemCombo.markAsUntouched();
            this.comboForm.controls.itemCombo.setValue(null);

            this.comboForm.controls.grupo.markAsUntouched();
            this.comboForm.controls.grupo.setValue('');

            this.isSubmitted = false;
            this.modoEdicao = false;

            this.reinit();
          });
        },
        error => {
          Swal.fire('Opções', error.error.message, 'error');
        }
      );
    }
  }

  deletarItemCombo(id: string) {
    Swal.fire({
      title: this.translatePipe.transform('deletar_titulos'),
      text: this.translatePipe.transform('mensagem_excluir_combo'),
      showCancelButton: true,
      confirmButtonText: this.translatePipe.transform('deletar_sim'),
      cancelButtonText: this.translatePipe.transform('deletar_nao'),
    }).then(result => {
      if (result.value) {
        this.comboService.excluirItemCombo(id).subscribe(
          res => {
            this.listaComboProduto();
          },
          error => {
            Swal.fire('Opções', error.error.message, 'error');
          }
        );
      }
    });
  }

  editarItemCombo(id: string) {
    this.reinit();

    this.modoEdicao = true;
    this.idItem = id;
    this.comboService.listaItemCombo(id).subscribe(res => {
      this.comboForm.controls.itemCombo.setValue(res.category);

      if (res.group && res.group !== '') {
        this.comboForm.controls.grupoCheck.setValue(true);
        this.comboForm.controls.grupo.setValue(res.group);
      }
      else {
        this.comboForm.controls.grupoCheck.setValue(false);

        this.comboForm.controls.options = this.fb.array([]);
        res.options.forEach(element => {
          const opcao = this.fb.group({
            name: [element.name, Validators.required]
          });
          this.options.push(opcao);
          this.comboForm.updateValueAndValidity();
        });
      }
    });
    window.scrollTo(0, 0);
  }

  cancelar() {
    this.comboForm.controls.itemCombo.markAsUntouched();
    this.comboForm.controls.itemCombo.setValue(null);

    this.comboForm.controls.grupo.markAsUntouched();
    this.comboForm.controls.grupo.setValue('');

    this.modoEdicao = false;
    this.isSubmitted = false;

    this.reinit();
  }

  voltar() {
    this.router.navigate([`admin/cardapios/marca/${this.idMarca}`]);
  }
}

import { TokenService } from '../../services/token.service';
import { MarcasService } from '../../services/marcas.service';
import { UnidadesService } from '../../services/unidades.service';
import { CardapiosService } from '../../services/cardapios.service';
import { PedidosService } from './../../services/pedidos.service';
import { Component, OnInit, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { TranslationService } from '../../services/translation.service';
import { clearCache } from "clear-cache";
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/takeWhile';
import 'rxjs/add/observable/timer';
@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit {
  @Output() onlineUsers = new EventEmitter();

  user: any;
  notifications = [];
  socket: any;
  count = [];
  languages = [];
  chatList = [];
  msgNumber = 0;
  imageId: any;
  imageVersion: any;
  showDropdownConfiguracao = false;
  showDropdownPlataforma = false;
  showDropdownIdiomas = false;
  showDropdownOperacao = false;

  descricaoMarca: any;
  descricaoUnidade: any;
  descricaoItem: any;

  marca: any;
  unidade: any;
  item: any;

  breadcrumbLista: any;
  chatVisivel = false;
  chatNaoLido = false;

  constructor(
    private tokenService: TokenService,
    private router: Router,
    private translationService: TranslationService,
    private marcasService: MarcasService,
    private pedidosService: PedidosService,
    private unidadesService: UnidadesService,
    private cardapiosService: CardapiosService
  ) { }

  ngOnInit() {
    this.user = this.tokenService.GetPayLoad();
    this.languages = this.translationService.languages;
    var usuario = window.localStorage.getItem('language');
    if (usuario !== null) {
      this.setaIdioma(usuario);
    }
    this.obterMarcaBreadcrumb();

    if (this.user.accesses.chat) {
      if (!this.tokenService.GetTimerChat()) {

        this.tokenService.SetTimerChat();

        Observable.timer(0, 30000)
          .takeWhile(() => true) // Apenas com o componente ativo
          .subscribe(() => {
            this.obterChatNaoLido();
          });
      }
    }

    if (this.user.accesses.operations) {
      if (!this.tokenService.GetTimerNovosPedidos()) {

        this.tokenService.SetTimerNovosPedidos();

        Observable.timer(0, 120000)
          .takeWhile(() => true) // Apenas com o componente ativo
          .subscribe(() => {
            this.obterPedidosPendentes();
          });
      }
    }

  }

  obterMarcaBreadcrumb() {
    let url = window.location.href.toLocaleLowerCase();
    let idMarca = undefined;
    let idUnidade = undefined;
    let idItemEstoque = undefined;

    if (url.indexOf('/marca/') >= 0) {
      idMarca = url.substring(url.indexOf('/marca/') + '/marca/'.length);
      if (idMarca.indexOf('/') >= 0) {
        idMarca = idMarca.substring(0, idMarca.indexOf('/'));
      }
    }

    if (url.indexOf('/unidade/') >= 0) {
      idUnidade = url.substring(url.indexOf('/unidade/') + '/unidade/'.length);
      if (idUnidade.indexOf('/') >= 0) {
        idUnidade = idUnidade.substring(0, idUnidade.indexOf('/'));
      }
    }

    if (url.indexOf('/estoque/') >= 0) {
      idItemEstoque = url.substring(url.indexOf('/estoque/') + '/estoque/'.length);
      if (idItemEstoque.indexOf('/') >= 0) {
        idItemEstoque = idItemEstoque.substring(0, idItemEstoque.indexOf('/'));
      }
    }

    if (url.indexOf('/produto/') >= 0) {
      idItemEstoque = url.substring(url.indexOf('/produto/') + '/produto/'.length);
      if (idItemEstoque.indexOf('/') >= 0) {
        idItemEstoque = idItemEstoque.substring(0, idItemEstoque.indexOf('/'));
      }
    }

    if (idMarca) {
      this.marca = idMarca;
      this.marcasService.obterDadosMarca(idMarca).subscribe(res => {
        this.descricaoMarca = res.name;

        if (idUnidade) {
          this.unidade = idUnidade;
          this.unidadesService.obterDadosUnidade(idUnidade).subscribe(res => {
            this.descricaoUnidade = res.name;

            if (idItemEstoque) {
              this.item = idItemEstoque;
              this.cardapiosService.obterDadosProduto(idItemEstoque).subscribe(res => {
                this.descricaoItem = res.name;

                this.montarBreadcrumb();
              });
            } else {
              this.montarBreadcrumb();
            }

          });
        } else if (idItemEstoque) {
          this.item = idItemEstoque;
          this.cardapiosService.obterDadosProduto(idItemEstoque).subscribe(res => {
            this.descricaoItem = res.name;

            this.montarBreadcrumb();
          });
        } else {
          this.montarBreadcrumb();
        }
      });
    } else {
      this.montarBreadcrumb();
    }
  }

  montarBreadcrumb() {

    this.breadcrumbLista = [];


    //Home
    if (window.location.href.toLocaleLowerCase().endsWith('/home')) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('home'), redirecionamento: true, rota: '#/admin/home' });
    }

    //Lojas
    if (window.location.href.toLocaleLowerCase().endsWith('/marcas')) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('marcas'), redirecionamento: true, rota: '#/admin/marcas' });
    }
    else if (window.location.href.toLocaleLowerCase().indexOf('/marcas') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/editar') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('marcas'), redirecionamento: true, rota: '#/admin/marcas' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('titulo_marca_editar'), redirecionamento: false });
    }
    else if (window.location.href.toLocaleLowerCase().indexOf('/marcas') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/adicionar') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('marcas'), redirecionamento: true, rota: '#/admin/marcas' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('titulo_marca_adicionar'), redirecionamento: false });
    }

    //Categorias de Produtos
    if (window.location.href.toLocaleLowerCase().indexOf('/categorias') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/marca') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/editar') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('marcas'), redirecionamento: true, rota: '#/admin/marcas' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.descricaoMarca, redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_categorias_cardapios'), redirecionamento: true, rota: '#/admin/categorias/marca/' + this.marca });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_categorias_cardapios_editar'), redirecionamento: false });
    }
    else if (window.location.href.toLocaleLowerCase().indexOf('/categorias') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/marca') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/adicionar') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('marcas'), redirecionamento: true, rota: '#/admin/marcas' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.descricaoMarca, redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_categorias_cardapios'), redirecionamento: true, rota: '#/admin/categorias/marca/' + this.marca });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_categorias_cardapios_adicionar'), redirecionamento: false });
    }
    else if (window.location.href.toLocaleLowerCase().indexOf('/categorias') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/marca') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('marcas'), redirecionamento: true, rota: '#/admin/marcas' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.descricaoMarca, redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_categorias_cardapios'), redirecionamento: false });
    }

    //Segmentos Superiores
    if (window.location.href.toLocaleLowerCase().indexOf('/segmentos-superiores') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/editar') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('configuracao'), redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_segmentos_superior'), redirecionamento: true, rota: '#/admin/segmentos-superiores' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_segmentos_superior_editar'), redirecionamento: false });
    }
    else if (window.location.href.toLocaleLowerCase().indexOf('/segmentos-superiores') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/adicionar') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('configuracao'), redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_segmentos_superior'), redirecionamento: true, rota: '#/admin/segmentos-superiores' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_segmentos_superior_adicionar'), redirecionamento: false });
    }
    else if (window.location.href.toLocaleLowerCase().indexOf('/segmentos-superiores') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('configuracao'), redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_segmentos_superior'), redirecionamento: false });
    }
    //Segmentos
    else if (window.location.href.toLocaleLowerCase().indexOf('/segmentos') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/editar') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('configuracao'), redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_segmentos'), redirecionamento: true, rota: '#/admin/segmentos' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_segmentos_editar'), redirecionamento: false });
    }
    else if (window.location.href.toLocaleLowerCase().indexOf('/segmentos') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/adicionar') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('configuracao'), redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_segmentos'), redirecionamento: true, rota: '#/admin/segmentos' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_segmentos_adicionar'), redirecionamento: false });
    }
    else if (window.location.href.toLocaleLowerCase().indexOf('/segmentos') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('configuracao'), redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_segmentos'), redirecionamento: false });
    }

    //Opcionais
    if (window.location.href.toLocaleLowerCase().indexOf('/opcionais') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/marca') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/editar') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('marcas'), redirecionamento: true, rota: '#/admin/marcas' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.descricaoMarca, redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('opcionais_cardapios'), redirecionamento: true, rota: '#/admin/opcionais/marca/' + this.marca });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('opcionais_cardapios_editar'), redirecionamento: false });
    }
    else if (window.location.href.toLocaleLowerCase().indexOf('/opcionais') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/marca') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/adicionar') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('marcas'), redirecionamento: true, rota: '#/admin/marcas' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.descricaoMarca, redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('opcionais_cardapios'), redirecionamento: true, rota: '#/admin/opcionais/marca/' + this.marca });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('opcionais_cardapios_adicionar'), redirecionamento: false });
    }
    else if (window.location.href.toLocaleLowerCase().indexOf('/opcionais') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/marca') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('marcas'), redirecionamento: true, rota: '#/admin/marcas' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.descricaoMarca, redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('opcionais_cardapios'), redirecionamento: false });
    }

    //Combos
    if (window.location.href.toLocaleLowerCase().indexOf('/promocoes') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/marca') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/editar') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('marcas'), redirecionamento: true, rota: '#/admin/marcas' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.descricaoMarca, redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_promocao'), redirecionamento: true, rota: '#/admin/opcionais/marca/' + this.marca });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('editar_promocao'), redirecionamento: false });
    }
    else if (window.location.href.toLocaleLowerCase().indexOf('/promocoes') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/marca') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/adicionar') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('marcas'), redirecionamento: true, rota: '#/admin/marcas' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.descricaoMarca, redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_promocao'), redirecionamento: true, rota: '#/admin/opcionais/marca/' + this.marca });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('cadastro_promocao'), redirecionamento: false });
    }
    else if (window.location.href.toLocaleLowerCase().indexOf('/promocoes') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/marca') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('marcas'), redirecionamento: true, rota: '#/admin/marcas' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.descricaoMarca, redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_promocao'), redirecionamento: false });
    }

    //Usuários
    if (window.location.href.toLocaleLowerCase().endsWith('/gerenciamento-usuarios')) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('configuracao'), redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('usuario_perfil'), redirecionamento: true, rota: '#/admin/gerenciamento-usuarios' });
    }
    else if (window.location.href.toLocaleLowerCase().indexOf('/gerenciamento-usuarios') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/editar') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('configuracao'), redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('usuario_perfil'), redirecionamento: true, rota: '#/admin/gerenciamento-usuarios' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('editar_usuario'), redirecionamento: false });
    }
    else if (window.location.href.toLocaleLowerCase().indexOf('/gerenciamento-usuarios') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/adicionar') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('configuracao'), redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('usuario_perfil'), redirecionamento: true, rota: '#/admin/gerenciamento-usuarios' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('cadastro_usuario'), redirecionamento: false });
    }

    //Destaque de Aplicativos
    else if (window.location.href.toLocaleLowerCase().indexOf('/destaques-aplicativo') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/marca') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('marcas'), redirecionamento: true, rota: '#/admin/marcas' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.descricaoMarca, redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('destaques_aplicativos'), redirecionamento: false });
    }

    //Cupons
    if (window.location.href.toLocaleLowerCase().indexOf('/cupons') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/marca') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/editar') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('marcas'), redirecionamento: true, rota: '#/admin/marcas' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.descricaoMarca, redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_cupons_titulo'), redirecionamento: true, rota: '#/admin/cupons/marca/' + this.marca });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_cupons_editar'), redirecionamento: false });
    }
    else if (window.location.href.toLocaleLowerCase().indexOf('/cupons') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/marca') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/adicionar') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('marcas'), redirecionamento: true, rota: '#/admin/marcas' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.descricaoMarca, redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_cupons_titulo'), redirecionamento: true, rota: '#/admin/cupons/marca/' + this.marca });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_cupons_cadastro'), redirecionamento: false });
    }
    else if (window.location.href.toLocaleLowerCase().indexOf('/cupons') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/marca') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('marcas'), redirecionamento: true, rota: '#/admin/marcas' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.descricaoMarca, redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_cupons_titulo'), redirecionamento: false });
    }

    else if (window.location.href.toLocaleLowerCase().endsWith('/cupons')) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('configuracao'), redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_cupons_titulo'), redirecionamento: true, rota: '#/admin/cupons' });
    }
    else if (window.location.href.toLocaleLowerCase().indexOf('/cupons') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/editar') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('configuracao'), redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_cupons_titulo'), redirecionamento: true, rota: '#/admin/cupons' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_cupons_editar'), redirecionamento: false });
    }
    else if (window.location.href.toLocaleLowerCase().indexOf('/cupons') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/adicionar') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('configuracao'), redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_cupons_titulo'), redirecionamento: true, rota: '#/admin/cupons' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_cupons_cadastro'), redirecionamento: false });
    }

    //Pagamentos
    if (window.location.href.toLocaleLowerCase().endsWith('/pagamentos')) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('configuracao'), redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('pagamento'), redirecionamento: true, rota: '#/admin/pagamentos' });
    }
    else if (window.location.href.toLocaleLowerCase().indexOf('/pagamentos') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/editar') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('configuracao'), redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('pagamento'), redirecionamento: true, rota: '#/admin/pagamentos' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('editar_pagamento'), redirecionamento: false });
    }
    else if (window.location.href.toLocaleLowerCase().indexOf('/pagamentos') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/adicionar') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('configuracao'), redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('pagamento'), redirecionamento: true, rota: '#/admin/pagamentos' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('cadastro_pagamento'), redirecionamento: false });
    }

    //Segmentos
    if (window.location.href.toLocaleLowerCase().endsWith('/categorias-marcas')) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('configuracao'), redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_categorias'), redirecionamento: true, rota: '#/admin/categorias-marcas' });
    }

    //Taxas
    if (window.location.href.toLocaleLowerCase().endsWith('/taxas')) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('configuracao'), redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('cadastro_taxa'), redirecionamento: false });
    }

    //Plataforma
    if (window.location.href.toLocaleLowerCase().endsWith('/configuracoes-plataforma')) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('configuracao'), redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_plataforma'), redirecionamento: false });
    }

    //Taxas
    if (window.location.href.toLocaleLowerCase().endsWith('/motivos-cancelamento')) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('configuracao'), redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('motivos_cancelamento'), redirecionamento: false });
    }

    //Pedidos
    if (window.location.href.toLocaleLowerCase().endsWith('/pedidos')) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('operacao'), redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('controle_pedidos'), redirecionamento: true, rota: '#/admin/pedidos' });
    }


    //Entregas
    if (window.location.href.toLocaleLowerCase().endsWith('/controle-entregas')) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('operacao'), redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('controle_entrega'), redirecionamento: true, rota: '#/admin/controle-entregas' });
    }


    //Recebimentos
    if (window.location.href.toLocaleLowerCase().endsWith('/controle-recebimento')) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('operacao'), redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('controle_recebimentos'), redirecionamento: true, rota: '#/admin/controle-recebimento' });
    }


    //Cardápio
    if (window.location.href.toLocaleLowerCase().indexOf('/cardapios') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/marca') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/editar') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('marcas'), redirecionamento: true, rota: '#/admin/marcas' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.descricaoMarca, redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_cardapio'), redirecionamento: true, rota: '#/admin/cardapios/marca/' + this.marca });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('editar_produto'), redirecionamento: false });
    }
    else if (window.location.href.toLocaleLowerCase().indexOf('/cardapios') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/marca') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/adicionar') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('marcas'), redirecionamento: true, rota: '#/admin/marcas' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.descricaoMarca, redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_cardapio'), redirecionamento: true, rota: '#/admin/cardapios/marca/' + this.marca });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('cadastro_produto'), redirecionamento: false });
    }
    else if (window.location.href.toLocaleLowerCase().indexOf('/cardapios') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/marca') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/extras') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('marcas'), redirecionamento: true, rota: '#/admin/marcas' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.descricaoMarca, redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_cardapio'), redirecionamento: true, rota: '#/admin/cardapios/marca/' + this.marca });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.descricaoItem, redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('cadastro_extra'), redirecionamento: false });
    }
    else if (window.location.href.toLocaleLowerCase().indexOf('/cardapios') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/marca') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/combo') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('marcas'), redirecionamento: true, rota: '#/admin/marcas' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.descricaoMarca, redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_cardapio'), redirecionamento: true, rota: '#/admin/cardapios/marca/' + this.marca });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.descricaoItem, redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('cadastro_combo'), redirecionamento: false });
    }
    else if (window.location.href.toLocaleLowerCase().indexOf('/cardapios') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/marca') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('marcas'), redirecionamento: true, rota: '#/admin/marcas' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.descricaoMarca, redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_cardapio'), redirecionamento: false });
    }


    //Unidades
    if (window.location.href.toLocaleLowerCase().indexOf('/unidades') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/marca') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/editar') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('marcas'), redirecionamento: true, rota: '#/admin/marcas' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.descricaoMarca, redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_unidades'), redirecionamento: true, rota: '#/admin/unidades/marca/' + this.marca });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('editar_unidade'), redirecionamento: false });
    }
    else if (window.location.href.toLocaleLowerCase().indexOf('/unidades') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/marca') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/adicionar') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('marcas'), redirecionamento: true, rota: '#/admin/marcas' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.descricaoMarca, redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_unidades'), redirecionamento: true, rota: '#/admin/unidades/marca/' + this.marca });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('adicionar_unidade'), redirecionamento: false });
    }
    else if (window.location.href.toLocaleLowerCase().indexOf('/unidades') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/marca') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('marcas'), redirecionamento: true, rota: '#/admin/marcas' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.descricaoMarca, redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_unidades'), redirecionamento: false });
    }


    //Estoque
    if (window.location.href.toLocaleLowerCase().indexOf('/estoque-ingressos') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/unidade') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('marcas'), redirecionamento: true, rota: '#/admin/marcas' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.descricaoMarca, redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_unidades'), redirecionamento: true, rota: '#/admin/unidades/marca/' + this.marca });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.descricaoUnidade, redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('estoque'), redirecionamento: true, rota: '#/admin/unidades/marca/' + this.marca + '/unidade/' + this.unidade + '/estoque' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.descricaoItem, redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('estoque_ingressos'), redirecionamento: false });
    }
    else if (window.location.href.toLocaleLowerCase().indexOf('/estoque-digital') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/unidade') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('marcas'), redirecionamento: true, rota: '#/admin/marcas' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.descricaoMarca, redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_unidades'), redirecionamento: true, rota: '#/admin/unidades/marca/' + this.marca });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.descricaoUnidade, redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('estoque'), redirecionamento: true, rota: '#/admin/unidades/marca/' + this.marca + '/unidade/' + this.unidade + '/estoque' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.descricaoItem, redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('estoque_digital'), redirecionamento: false });
    }
    else if (window.location.href.toLocaleLowerCase().indexOf('/estoque') >= 0 && window.location.href.toLocaleLowerCase().indexOf('/unidade') >= 0) {
      this.breadcrumbLista = [];

      this.breadcrumbLista.push({ descricao: this.translationService.translate('marcas'), redirecionamento: true, rota: '#/admin/marcas' });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.descricaoMarca, redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('gerenciamento_unidades'), redirecionamento: true, rota: '#/admin/unidades/marca/' + this.marca });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.descricaoUnidade, redirecionamento: false });
      this.breadcrumbLista.push({ descricao: '/', redirecionamento: false });
      this.breadcrumbLista.push({ descricao: this.translationService.translate('estoque'), redirecionamento: false });
    }
  }

  obterChatNaoLido() {
    this.pedidosService.obterPedidosChat().subscribe(res => {
      this.chatNaoLido = false;
      res.map((chat) => {
        if (chat.message_not_read_count > 0) {
          this.chatNaoLido = true;

          this.playAudio();
        }
      })
    });
  }

  obterPedidosPendentes() {
    this.pedidosService.obterPedidos().subscribe(res => {

      let orders = res.filter((order) => { return order.status === 'open' || order.status === 'ready' });

      if (orders.length > 0) {
        this.playAudio();
      }
    });
  }


  playAudio() {
    let audio = new Audio();
    audio.src = "assets/sounds/beep.wav";
    audio.load();
    audio.play();
  }

  logout() {
    this.tokenService.DeleteToken();
    clearCache();
    this.router.navigate(['/']);
  }

  setaIdioma(idioma) {
    window.localStorage.setItem('language', idioma);
    this.translationService.setLanguage(idioma);
  }

  fechaConfiguracao() {
    this.showDropdownConfiguracao = false;
  }

  fechaPlataforma() {
    this.showDropdownPlataforma = false;
  }

  fechaIdiomas() {
    this.showDropdownIdiomas = false;
  }

  fechaOperacao() {
    this.showDropdownOperacao = false;
  }

  redirecionaHome() {
    this.router.navigate(['admin/home']);
  }

  redirecionaGerenciamentoDePlataforma() {
    this.router.navigate(['admin/configuracoes-plataforma']);
  }

  redirecionaMotivosCancelamento() {
    this.router.navigate(['admin/motivos-cancelamento']);
  }

  redirecionaMarcas() {
    this.router.navigate(['admin/marcas']);
  }

  redirecionaUsuariosPerfis() {
    this.router.navigate(['admin/gerenciamento-usuarios']);
  }

  redirecionaMeiosPagamento() {
    this.router.navigate(['admin/pagamentos']);
  }

  redirecionaTaxas() {
    this.router.navigate(['admin/taxas']);
  }

  redirecionaEnquetes() {
    this.router.navigate(['admin/enquetes']);
  }

  redirecionaControlePedidos() {
    this.showDropdownOperacao = false;
    this.router.navigate(['admin/pedidos']);
  }

  redirecionaPromocoes() {
    this.router.navigate(['admin/promocoes']);
  }

  redirecionaCupons() {
    this.router.navigate(['admin/cupons']);
  }

  redirecionaControleEntregas() {
    this.showDropdownOperacao = false;
    this.router.navigate(['admin/controle-entregas']);
  }

  redirecionaControleRecebimentos() {
    this.showDropdownOperacao = false;
    this.router.navigate(['admin/controle-recebimento']);
  }

  redirecionaChat() {
    this.chatVisivel = !this.chatVisivel;

    if (!this.chatVisivel) {
      this.obterChatNaoLido();
    }
  }

  redirecionaGerenciamentoDeCategoriasMarcas() {
    this.router.navigate(['admin/segmentos']);
  }

  redirecionaSuperiores() {
    this.router.navigate(['admin/segmentos-superiores']);
  }
}
